import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { NavDropdown } from "react-bootstrap"
import RoutesDef from "src/routes/RoutesDef";

type NavDropdownMailtoProps = {
    mailto?: string;
    label: string;
};


export const NavDropdownMailto: FC<NavDropdownMailtoProps> = (props) => {
    var t: any;
    return (
        <NavDropdown.Item href="#"
            to='#'
            onBlur={() => {
                clearTimeout(t);
            }}
            onClick={(e) => {
                t = setTimeout(function () {
                    // The browser did not respond after 500ms, so open an alternative URL.
                    document.location.href = RoutesDef.ABOUT
                }, 600);
                document.location.href = 'mailto:' + props.mailto
            }}
        >
            <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
            {props.label}
        </NavDropdown.Item>
    )
};
