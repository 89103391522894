import { ChangeEventHandler, FC, useEffect, useRef, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { Column } from "react-table";
import { SourceFile } from "src/api/generated.api";
import {
    useManagedLayerControllerDeleteSourceFileMutation,
    useManagedLayerSourcesControllerToucheSourceFilesMutation,
} from "src/api/ManagedLayerApi";
import TableSimple from "src/components/tables/TableSimple";
import {
    DoUploadFunction,
    FileUploadStatus,
    InitUploadFunction,
    useChunkUploader,
} from "src/features/uploader/ChunkUploaderService";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

export type fileStatus = {
    name: string;
    onServer: boolean;
};

export enum LayerType {
    DEM = "dem",
    TEX = "tex",
}

export enum FileServerAvailabilityStatus {
    OFFLINE = "Offline",
    ONLINE = "Online",
    INCOMPLETE = "Incomplete",
}

type FileTableRow = {
    name: string;
    uploadId: string;
    progress: number;
    uploading: boolean;
    status: FileServerAvailabilityStatus;
    uploadStatus: FileUploadStatus;
};

const uploadFuncFactory = (
    layerType: LayerType,
    layerId: number,
): DoUploadFunction => {
    return async (
        { offset, totalSize, fileName, chunk, uploadId },
        abortSignal,
    ) => {
        const formData = new FormData();
        const queryString = new URLSearchParams({
            offset: `${offset}`,
            totalSize: `${totalSize}`,
            fileId: uploadId,
        });
        formData.append("file", chunk, fileName);
        await axios.get("/api/auth/refreshToken");
        await axios.post(
            `/api/managed-layers/${layerId}/upload-chunk/${layerType}?${queryString.toString()}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                signal: abortSignal,
            },
        );
    };
};

export type LayerSourceFileTableProps = {
    /** A list of file object attached to the layer and saved in db */
    files: SourceFile[];
    layerType: LayerType;
    layerId: number;
    showDoneQueue?: Boolean;
};

export const LayerSourceFileTable: FC<LayerSourceFileTableProps> = ({
    files,
    layerType,
    layerId,
    showDoneQueue,
}) => {
    const { addToQueue, uploadQueue, clearProcessed } = useChunkUploader();

    const [touchFiles] =
        useManagedLayerSourcesControllerToucheSourceFilesMutation();

    const initUploadFuncFactory = (
        files: File[],
        layerType: LayerType,
        layerId: number,
    ): InitUploadFunction => {
        return async (uploadIds: string[]) => {
            await touchFiles({
                body: files.map((file, index) => ({
                    id: uploadIds[index],
                    layerId,
                    name: file.name,
                    totalSize: file.size,
                    layerFileUsage: layerType,
                })),
            });
        };
    };

    const [data, setData] = useState<FileTableRow[]>([]);

    const getFileContext: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!e.target || !e.target.files) {
            return;
        }
        const { files } = e.target;

        const farray: File[] = [];
        for (let i = 0; i < files.length; i++) {
            farray.push(files[i]);
        }
        const uploadFunc = uploadFuncFactory(layerType, layerId);
        const initFunc = initUploadFuncFactory(farray, layerType, layerId);
        addToQueue(farray, uploadFunc, initFunc);
        e.target.value = "";
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const columns: Column<FileTableRow>[] = [
        {
            Header: "File name",
            width: "auto",
            Cell: ({ row }: { row: { original: FileTableRow } }) => (
                <>{row.original.name}</>
            ),
        },
        {
            Header: "Status",
            Cell: ({ row }: { row: { original: FileTableRow } }) => (
                <>
                    {row.original.status ===
                        FileServerAvailabilityStatus.ONLINE && (
                            <>
                                <div>
                                    On Server{" "}
                                    <a
                                        href={`/api/managed-layers/${layerId}/sourceFile/${row.original.uploadId}/download`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button className="d-inline">
                                            <FontAwesomeIcon icon={faDownload} />
                                        </Button>
                                    </a>
                                </div>
                            </>
                        )}{" "}
                    {row.original.status ===
                        FileServerAvailabilityStatus.OFFLINE && (
                            <div className="inline">Offline</div>
                        )}{" "}
                    {row.original.status ===
                        FileServerAvailabilityStatus.INCOMPLETE && (
                            <FileUploadStatusCell task={row.original} />
                        )}
                </>
            ),
        },
        {
            Header: "Action",
            Cell: ({ row }: { row: { original: FileTableRow } }) => (
                <>
                    {row.original.uploading ? (
                        <FileUploadAction task={row.original} />
                    ) : (
                        <FileAction task={row.original} layerId={layerId} />
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        const rows = files.map(({ name, id, isOnline, ...f }) => {
            const uploadQueueTask = uploadQueue.find(
                ({ uploadId }) => uploadId === id,
            );
            if (uploadQueueTask) {
                // file is in the upload queue, so let's check it's status
                return {
                    name: uploadQueueTask.file.name,
                    uploadId: uploadQueueTask.uploadId,
                    progress:
                        uploadQueueTask.uploadedChunks /
                        uploadQueueTask.totalChunks,
                    status: FileServerAvailabilityStatus.INCOMPLETE,
                    uploadStatus: uploadQueueTask.status,
                    uploading: true,
                };
            } else {
                // display server status
                const status =
                    isOnline && f.lastUploadedOffset / f.totalSize === 1
                        ? FileServerAvailabilityStatus.ONLINE
                        : !isOnline
                            ? FileServerAvailabilityStatus.OFFLINE
                            : FileServerAvailabilityStatus.INCOMPLETE;
                return {
                    name,
                    uploadId: id,
                    progress: f.lastUploadedOffset / f.totalSize,
                    status,
                    uploadStatus: FileUploadStatus.DONE,
                    uploading: false,
                };
            }
        });

        setData(rows);
    }, [files, layerId, layerType, uploadQueue]);

    return (
        <>
            <TableSimple
                columns={columns}
                data={data}
                fetchData={(pageIndex: number, pageSize: number) => { }}
                loading={false}
            />
            <input
                ref={inputRef}
                type="file"
                onChange={getFileContext}
                multiple
                hidden
            ></input>
            <Button onClick={handleUpload}>Add files</Button>
            {showDoneQueue && (
                <Button onClick={() => clearProcessed()}>Clear done</Button>
            )}
        </>
    );
};

const FileUploadStatusCell: FC<{ task: FileTableRow }> = ({ task }) => {
    const [showProgressBar, setShowProgressBar] = useState<Boolean>(false);

    useEffect(() => {
        if (!task.uploading) {
            setShowProgressBar(false);
            return;
        }
        if (
            task.uploadStatus === FileUploadStatus.PENDING &&
            task.progress === 0
        ) {
            setShowProgressBar(false);
        }

        if (task.uploadStatus === FileUploadStatus.RUNNING) {
            setShowProgressBar(true);
        }

        if (task.uploadStatus === FileUploadStatus.DONE) {
            setShowProgressBar(false);
        }

        if (task.uploadStatus === FileUploadStatus.ERROR) {
            setShowProgressBar(false);
        }
    }, [task.status, task.progress, task.uploading]);

    return (
        <>
            {showProgressBar ? (
                <ProgressBar
                    animated={task.uploadStatus === FileUploadStatus.RUNNING}
                    variant={
                        task.uploadStatus === FileUploadStatus.ERROR
                            ? "warning"
                            : "info"
                    }
                    label={
                        <div style={{ minWidth: 30 }}>{`${Math.round(
                            100 * task.progress,
                        )}%${task.uploadStatus !== FileUploadStatus.RUNNING
                            ? " - " + task.status
                            : ""
                            }`}</div>
                    }
                    now={100 * task.progress}
                />
            ) : (
                <div>
                    {task.uploading
                        ? task.status
                        : task.progress === 1
                            ? "On Server"
                            : `Partial - ${Math.round(100 * task.progress)}%`}
                </div>
            )}
        </>
    );
};

const FileUploadAction: FC<{ task: FileTableRow }> = ({ task }) => {
    const { cancel } = useChunkUploader();

    return (
        <>
            {(task.uploadStatus === FileUploadStatus.PENDING ||
                task.uploadStatus === FileUploadStatus.RUNNING) && (
                    <Button variant="warning" onClick={() => cancel(task.uploadId)}>
                        Cancel
                    </Button>
                )}
        </>
    );
};

const FileAction: FC<{ task: FileTableRow; layerId: number }> = ({
    task,
    layerId,
}) => {
    const [deleteFile] = useManagedLayerControllerDeleteSourceFileMutation();
    return (
        <>
            <Button
                variant="danger"
                onClick={() =>
                    deleteFile({
                        fileId: task.uploadId,
                        layerId,
                    })
                }
            >
                Delete
            </Button>
        </>
    );
};
