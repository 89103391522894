import { FC, useContext } from "react";
import { Button, FormCheck, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { CurrentUser } from "../features/auth/CurrentUser";
import { AppLogo } from "./AppLogo";
import { useSelector } from "react-redux";
import { selectAuthStatus, selectLoggedInUser } from "src/features/auth/authSlice";
import { Sentences } from "src/lang/lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import RoutesDef from "src/routes/RoutesDef";
import { useAbility } from "@casl/react";
import { AbilityContext } from "src/casl/Can";
import { Action, Subjects } from "src/api/Permissions";

export const MainNavbarUnity: FC<any> = () => {
    const authStatus = useSelector(selectAuthStatus);
    const ability = useAbility(AbilityContext)
    const location = useLocation();
    const displayGoBackBtn = ![RoutesDef.UNITY_HOME, RoutesDef.UNITY_LOGIN].includes(location.pathname) && ability.can(Action.Create, Subjects.Terrains)

    return (
        <Navbar fixed="top" expand="lg" bg="dark">
            <Navbar.Brand href={RoutesDef.UNITY_HOME}>
                <AppLogo />
            </Navbar.Brand>

            <Navbar>
                {displayGoBackBtn && <Link className="nav-link" to={RoutesDef.UNITY_HOME} ><FontAwesomeIcon className="mr-2" icon={faBackward} /> Go back</Link>}
            </Navbar>

            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    {authStatus === 'LOGGED_IN' && <Link className="nav-link" to={RoutesDef.UNITY_LOGOUT}><FontAwesomeIcon className="mr-2" icon={faSignOutAlt} />{Sentences.logout.en}</Link>}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
