import { Formik } from "formik";
import { FC, useContext } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useAddLocalPasswordMutation, useAddSocialAccountMutation } from "src/api/generated.api";
import { BootstrapTextInput } from "src/components/BootstrapFormComponents";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { LangContext } from "src/lang/lang";
import * as Yup from 'yup';

export type AddAuthProviderFormProps = {
    userId: number;
    providerType: string;
    onSuccess?: () => void;
    onErrorMsg?: (err: string) => void
}

export const LocalPasswordSchema = Yup.string()
    .matches(RegExp("(?=(.*[a-z]){3,})"), "must contain at least 3 lowercase letters")
    .matches(RegExp("(?=(.*[A-Z]){2,})"), "must contain at least 2 uppercase letters")
    .matches(RegExp("(?=(.*[0-9]){2,})"), "must contain at least 2 numbers")
    .matches(RegExp("(?=(.*[!@#$%^&*()\\-__+.]){1,})"), "must contain at least 1 of !@#$%^&*()-_+.")
    .min(8, "must be at least 8 characters long")
    .matches(RegExp(
        "^" + //start anchor
        "(?=(.*[a-z]){3,})" + // lowercase letters. {3,} indicates that you want 3 of this group
        "(?=(.*[A-Z]){2,})" + // uppercase letters. {2,} indicates that you want 2 of this group
        "(?=(.*[0-9]){2,})" + // numbers. {2,} indicates that you want 2 of this group
        "(?=(.*[!@#$%^&*()\\-__+.]){1,})" + //all the special characters in the [] fields. The ones used by regex are escaped by using the \ or the character itself. {1,} is redundant, but good practice, in case you change that to more than 1 in the future. Also keeps all the groups consistent
        ".{8,}" + // indicates that you want 8 or more
        "$"  //end anchor)
    ),)
    .required()

export const AddAuthProviderForm: FC<AddAuthProviderFormProps> = ({ userId, onSuccess, onErrorMsg, providerType }) => {
    const [addLocalPassword] = useAddLocalPasswordMutation();
    const [addSocialPassword] = useAddSocialAccountMutation();

    const { Sentences } = useContext(LangContext);

    const localValidationSchema = Yup.object({
        providerKey: LocalPasswordSchema
    });

    const socialValidationSchema = Yup.object({
        providerKey: Yup.string().required()
    });

    return <Container className="mt-3 mb-3"><Formik
        validationSchema={providerType === 'local' ? localValidationSchema : socialValidationSchema}
        initialValues={{ providerKey: "" }}
        onSubmit={async (values, formikBag) => {
            formikBag.setSubmitting(true);
            try {
                if (providerType === 'local') {
                    await addLocalPassword({ id: userId, addPasswordDto: { password: values['providerKey'] } }).unwrap();
                } else {
                    await addSocialPassword({ id: userId, addSocialAccountDto: { providerKey: values['providerKey'], providerType: providerType } }).unwrap();
                }
                if (onSuccess) onSuccess();
            } catch (e) {
                try {
                    const errors = convertApiErrorsToFormikErrors(e);
                    formikBag.setErrors(errors);
                } catch (newErr) {
                    if (onErrorMsg) onErrorMsg(JSON.stringify(newErr));
                }
            }
        }}>
        {formik => (
            <Form onSubmit={formik.handleSubmit} method="post">

                <BootstrapTextInput
                    label={providerType === 'local' ? Sentences.userPassword.en : Sentences.userSocialAccountUsername.en}
                    name="providerKey"
                    placeholder={providerType === 'local' ? Sentences.userPassword.en : Sentences.userSocialAccountUsername.en}
                />

                <Button variant="primary" type="submit"> {providerType === 'local' ? "add password" : "connect new " + providerType + " social account"} </Button>
            </Form>
        )}
    </Formik></Container>
}