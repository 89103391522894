import { subject } from "@casl/ability";
import { useAbility } from "@casl/react";
import { FC } from "react";
import { Action, Subjects } from "src/api/Permissions";
import {
    DocumentVisibilityEditor,
    DocumentVisibilityEditorProps,
} from "./document-visibility-editor";
import { AbilityContext } from "src/casl/Can";
import { Col, Form, FormLabel, Row } from "react-bootstrap";

export type DocumentVisibilityEditorIfCanProps =
    DocumentVisibilityEditorProps & {
        documentType: Subjects;
    };

export const DocumentVisibilityEditorIfCan: FC<
    DocumentVisibilityEditorIfCanProps
> = ({ document, documentType, onVisibilityUpdate }) => {
    const ab = useAbility(AbilityContext);
    return (
        <>
            {ab.can(
                Action.Share,
                subject(documentType, {
                    ...document,
                }),
                "visibility",
            ) ? (
                <DocumentVisibilityEditor
                    document={document}
                    onVisibilityUpdate={onVisibilityUpdate}
                />
            ) : (
                <Row>
                    <Form.Label column sm="2">
                        Document Visibility
                    </Form.Label>
                    <Form.Label column>{document.visibility}</Form.Label>
                </Row>
            )}
        </>
    );
};
