import { useContext } from "react";
import { EnrolledSessions } from "./EnrolledSessions";
import { LangContext } from "src/lang/lang";
import { OwnedSessions } from "./OwnedSessions";

export default function MySessions() {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const title = ObjectNames.courseSessionRecords.en;

    return (
        <div>
            <div className="section mb-3">
                <h1>{title}</h1>
                <div className="subtitle">
                    {Sentences.courseSessionRecordsSubtitle.en}
                </div>
            </div>
            <div className="section">
                <OwnedSessions />
            </div>
            <div className="section">
                <EnrolledSessions />
            </div>
        </div>
    );
}
