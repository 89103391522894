import { Subjects } from "./Permissions";
import {
    DocumentAclTagList,
    invalidateDocumentAclEntryTag,
    invalidateDocumentTagsFromId,
    provideDocumentAclTags,
} from "./document-access-control.api";
import { api as generatedApi, Slide } from "./generated.api";

function makeSlideListTags(
    activityId: number,
    slides: Slide[],
): (
    | "Slide"
    | "SlideList"
    | { type: "Slide"; id: number }
    | { type: "SlideList"; id: number }
)[] {
    return [
        ...slides.map(({ id }) => ({ type: "Slide" as const, id })),
        "Slide",
        { type: "SlideList" as const, id: activityId },
        "SlideList",
    ];
}

export const ActivityApi = generatedApi.enhanceEndpoints({
    addTagTypes: [
        "Activity",
        "Slide",
        "SlideList",
        "SlideSummary",
        "SlideSummaryList",
        ...DocumentAclTagList,
    ],
    endpoints: {
        activitiesControllerFindAllPaginated: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.results.map(({ id }) => ({
                              type: "Activity" as const,
                              id,
                          })),
                          "Activity",
                      ]
                    : [],
        },
        activitiesControllerFindOne: {
            providesTags: (result, error, arg) => {
                if (!result) {
                    return [];
                }
                const activity = result;
                const slides = activity.slides;
                return [
                    { type: "Activity" as const, id: activity.id },
                    "Activity",
                    ...makeSlideListTags(activity.id, slides),
                    ...provideDocumentAclTags(activity, Subjects.Activities),
                ];
            },
        },
        activitiesControllerRemove: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          { type: "Activity" as const, id: result.id },
                          "Activity",
                          ...invalidateDocumentTagsFromId(
                              Subjects.Activities,
                              arg.activityId,
                          ),
                      ]
                    : [];
        },
        activitiesControllerUpdate: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          { type: "Activity" as const, id: arg.activityId },
                          "Activity",
                      ]
                    : [];
        },
        activitiesControllerCreate: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result
                    ? [{ type: "Activity" as const, id: result.id }, "Activity"]
                    : [];
        },
        activitiesControllerGetSlides: {
            providesTags: (result, error, arg) =>
                result ? [...makeSlideListTags(arg.activityId, result)] : [],
        },
        activitiesControllerGetSlidesSummary: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "SlideSummary" as const,
                              id,
                          })),
                          {
                              type: "SlideSummaryList" as const,
                              id: arg.activityId,
                          },
                          "SlideSummaryList",
                      ]
                    : [],
        },
        activitiesControllerCreateSlide: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          { type: "Slide" as const, id: result.id },
                          { type: "Activity" as const, id: arg.activityId },
                          { type: "SlideList" as const, id: arg.activityId },
                          {
                              type: "SlideSummaryList" as const,
                              id: arg.activityId,
                          },
                      ]
                    : [];
        },
        activitiesControllerUpdateSlidesOrder: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          { type: "Activity" as const, id: arg.activityId },
                          { type: "SlideList" as const, id: arg.activityId },
                          {
                              type: "SlideSummaryList" as const,
                              id: arg.activityId,
                          },
                      ]
                    : [];
        },
        activitiesControllerAddUser: {
            invalidatesTags: invalidateDocumentAclEntryTag,
        },
        activitiesControllerChangeTeam: {
            invalidatesTags: (result, error, arg) => [
                { type: "Activity" as const, id: parseInt(arg.id) },
            ],
        },
        activitiesControllerChangeVisibility: {
            invalidatesTags: (result, error, arg) => [
                { type: "Activity" as const, id: parseInt(arg.id) },
            ],
        },
    },
});

export const {
    useActivitiesControllerFindOneQuery: useGetActivityQuery,
    useActivitiesControllerFindAllPaginatedQuery: useGetAllActivitiesQuery,
    useActivitiesControllerGetSlidesQuery: useGetActivitySlidesQuery,
    useActivitiesControllerGetSlidesSummaryQuery:
        useGetActivitySlidesSummaryQuery,
    useActivitiesControllerCreateMutation: useCreateActivityMutation,
    useActivitiesControllerCreateSlideMutation: useCreateActivitySlideMutation,
    useActivitiesControllerUpdateSlidesOrderMutation:
        useUpdateActivitySlidesOrderMutation,
    useActivitiesControllerRemoveMutation: useDeleteActivityMutation,
    useActivitiesControllerUpdateMutation: useUpdateActivityMutation,
    useActivitiesControllerAddUserMutation: useActivitiesAddUser,
    useActivitiesControllerChangeTeamMutation: useChangeTeam,
    useActivitiesControllerChangeVisibilityMutation: useChangeVisibility,
} = ActivityApi;
