import { useLocation } from "react-router-dom";

export const DebugRouter = ({ children }: { children: any }) => {
    const location = useLocation();

    console.log(
        `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
            location.state,
        )}`,
    );
    return children;
};
