import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { FC } from "react";

export type ConfirmProps = {
    showConfirmation: boolean;
    onPerform?: () => void;
    onCancel?: () => void;
    message?: string;
};
export const Confirm: FC<ConfirmProps> = ({
    showConfirmation,
    onPerform,
    onCancel,
    message,
}) => {
    const defaultMessage: string = "Are you sure ?";
    return (
        <Modal show={showConfirmation} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message ? message : defaultMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    No
                </Button>
                <Button variant="primary" onClick={onPerform}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
