import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { useGetAllCourseSessionsQuery } from "src/api/CourseSessionApi";
import TableSimple from "src/components/tables/TableSimple";
import { Button } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { CourseSession, useCourseSessionControllerFindAllPaginatedQuery } from "src/api/generated.api";
import { LangContext } from "src/lang/lang";
import { teamContext } from "../teams/context/team-context-provider";
import { Column } from "react-table";
import TableWithPagination from "src/components/tables/TableWithPagination";
import moment from "moment";

export const EnrolledSessions: FC = () => {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [errorMessage, setErrorMessage] = React.useState("");

    const queryProps = {
        enrolled: true,
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : ""
    }

    const {
        data: enrolledCourseSessions,
        isFetching,
        isError: getIsError,
        error: getError,
    } = useCourseSessionControllerFindAllPaginatedQuery(queryProps);


    const objectEditRoute = "/session-records";
    type objectType = CourseSession;

    const myColumns: Column<CourseSession>[] = [

        {
            Header: ObjectNames.courseSession.en,
            width: undefined,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{row.original.name}</div>
            ),
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>
                    <Link to={objectEditRoute + "/" + row.original.id}>
                        <Button variant="secondary">
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Link>
                </div>
            ),
        },
    ];

    return (
        <div>
            {enrolledCourseSessions && (
                <>
                    <h3>{Sentences.courseSessionsEnrolled.en}</h3>

                    <TableWithPagination
                        fetchData={(
                            pageIndexToFetch: number,
                            pageSizeToFetch: number,
                            filterToFetch: string,
                            sortToFetch: string
                        ) => {
                            setPageIndex(pageIndexToFetch);
                            setPageSize(pageSizeToFetch);
                            setFilter(filterToFetch);
                            setSort(sortToFetch);
                        }}
                        loading={isFetching}
                        columns={myColumns}
                        data={enrolledCourseSessions?.results ?? []}
                        itemCount={enrolledCourseSessions?.total ?? 0}
                        enableFiltering={true}
                        sortableProps={[
                            { name: 'Name', accessor: 'name' },
                            { name: 'Created at', accessor: 'createdAt' }
                        ]}
                    />
                </>
            )}
        </div>
    );
};
