import { useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { useParams } from "react-router";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import { EditActivity } from "./EditActivity";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";

interface EditCreateActivityProps {
    isEdit?: boolean;
}

export default function EditCreateActivityPage({
    isEdit,
}: EditCreateActivityProps) {
    const { Sentences } = useContext(LangContext);

    const { activityId } = useParams<{ activityId: string }>();
    const ability = useContext(AbilityContext);

    return (activityId ?
        <>
            <Container>
                <EditActivity
                    activityId={parseInt(activityId)}
                    isEdit={isEdit}
                />
            </Container>
        </> : <></>
    );
}
