import { FC } from "react";
import { useSlideProgressControllerGetFilesQuery } from "src/api/generated.api";
import { MediaList } from "../../medias/MediaList";

export type SlideProgressMediaListProps = {
    slideProgressId: number;
};

export const SlideProgressMediaList: FC<SlideProgressMediaListProps> = ({
    slideProgressId,
}) => {
    const { data, isFetching } = useSlideProgressControllerGetFilesQuery(
        { slideProgressId },
        { pollingInterval: 30000, refetchOnMountOrArgChange: true },
    );

    const onDownloadMediaClick = (fileId: string) => {
        if (slideProgressId) {
            var element = document.createElement("a");
            var filename = `SlideProgress_${slideProgressId}_media_${fileId}.png`;
            element.setAttribute(
                "href",
                `/api/slide-progress/${slideProgressId}/file/${fileId}`,
            );
            element.setAttribute("download", filename);

            element.style.display = "none";
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    };

    return (
        <MediaList
            data={data ?? []}
            loading={isFetching}
            onItemDownloadClick={onDownloadMediaClick}
        />
    );
};
