import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Button } from "react-bootstrap";
import { DownloadedFile } from "src/api/generated.api";
import TableSimple from "src/components/tables/TableSimple";
import { ObjectNames } from "src/lang/lang";

export type MediaListProps = {
    data: DownloadedFile[];
    loading: boolean;
    onItemDownloadClick?: ( id: string) => void;
}
export const MediaList: FC<MediaListProps> = ({data, loading, onItemDownloadClick, ...props}) => {
    const columns = [
        {
            Header: ObjectNames.medias.en,
            width: undefined,
            Cell: ({ row } : { row : { original : DownloadedFile} }) => (
                <div>
                    {row.original.name}
                </div>
            )
        },
        {
            Header: "type",
            width: undefined,
            Cell: ({ row } : { row : { original : DownloadedFile} }) => (
                <div>
                    {row.original.mimeType}
                </div>
            )
        },
        {
            Header: "url",
            width: undefined,
            Cell: ({ row } : { row : { original : DownloadedFile} }) => (
                <div>
                    {row.original.url}
                </div>
            )
        },
        {
            Header: 'Action',
            width: 125,
            Cell: ({ row } : { row : { original : DownloadedFile} }) => (
                <div>                    
                    <Button onClick={() => onItemDownloadClick ? onItemDownloadClick(row.original.id) : null}><FontAwesomeIcon icon={faDownload}/></Button>                    
                </div>
            ),
        },
    ]

    return (<div>
        <TableSimple 
            columns={columns} 
            data={data} 
            fetchData={()=>{}}
            loading={loading} />
    </div>)
}