import React from "react";
import { Form,} from "react-bootstrap";
import { FkDraggableChoiceItem, FkDraggableChoiceItemProps } from "./FkDraggableChoiceItem";

export default function FkMultiChoiceItem(props: FkDraggableChoiceItemProps) {
    return (
        <FkDraggableChoiceItem {...props}>
            <Form.Check type="checkbox" disabled />
        </FkDraggableChoiceItem>
    );
}