import { FC } from "react";
import { Spinner, Badge } from "react-bootstrap";
import { useGetJobStatusQuery } from "src/api/JobApi";

export type JobStatusProps = {
    jobId: string;
    showDate?: boolean;
};

export const JobStatus: FC<JobStatusProps> = ({ jobId, showDate }) => {
    const { data, isFetching } = useGetJobStatusQuery(
        { jobId },
        { refetchOnMountOrArgChange: true },
    );

    return (
        <div>
            {data && (
                <>
                    <>{showDate && <div>Date: {data.timestamp}</div>}</>
                    <div>Status: {data.status} </div>
                </>
            )}
            {isFetching && !data && (
                <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </div>
    );
};

export const SimpleJobStatus: FC<JobStatusProps> = ({ jobId, showDate }) => {
    const { data, isFetching } = useGetJobStatusQuery(
        { jobId },
        { refetchOnMountOrArgChange: true },
    );

    const variants = {
        NOT_SUBMITED: "secondary",
        PENDING: "primary",
        RUNNING: "info",
        DONE: "success",
        ERROR: "danger",
    };

    return (
        <>
            {data && (
                <>
                    <Badge variant={variants[data.status]}>
                        {data.status +
                            (showDate ? ` on ${data.timestamp}` : "")}
                    </Badge>
                </>
            )}
            {isFetching && !data && (
                <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </>
    );
};
