import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    useDeleteCourseSessionMutation,
    useGetAllCourseSessionsQuery,
} from "src/api/CourseSessionApi";
import TableSimple from "src/components/tables/TableSimple";
import { Button } from "react-bootstrap";
import { CourseSession, useCourseSessionControllerFindAllPaginatedQuery } from "src/api/generated.api";
import { LangContext } from "src/lang/lang";
import { teamContext } from "../teams/context/team-context-provider";
import { Action, Subjects } from "src/api/Permissions";
import { Can } from "src/casl/Can";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import ErrorBar from "src/components/ErrorBar";
import { subject } from "@casl/ability";
import { Column } from "react-table";
import TableWithPagination from "src/components/tables/TableWithPagination";
import moment from "moment";

export const OwnedSessions: FC = () => {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [errorMessage, setErrorMessage] = React.useState("");

    const queryProps = {
        mine: true,
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : ""
    }

    const {
        data: myCourseSessions,
        isFetching,
        isError: getIsError,
        error: getError,
    } = useCourseSessionControllerFindAllPaginatedQuery(queryProps);



    const deleteMessage = Sentences.courseSessionDeleteAreYouSure.en;

    const [deleteObject, { isError: deleteIsError, error: deleteError }] =
        useDeleteCourseSessionMutation();

    const objectEditRoute = "/session-records";
    type objectType = CourseSession;

    const myColumns: Column<CourseSession>[] = [

        {
            Header: ObjectNames.courseSession.en,
            width: undefined,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{row.original.name}</div>
            ),
        },

        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>
                    <Can
                        I={Action.Read}
                        this={subject(Subjects.CourseSessions, {
                            ...row.original,
                        })}
                    >
                        <Link to={objectEditRoute + "/" + row.original.id}>
                            <Button variant="secondary" className="ml-1">
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                    </Can>
                </div>
            ),
        },
    ];

    useEffect(() => {
        const isErr = getIsError || deleteIsError;
        const err = [getError, deleteError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [getIsError, deleteIsError, getError, deleteError]);

    return (
        <>
            {myCourseSessions && (
                <>
                    <h3>{Sentences.courseSessionsMine.en}</h3>
                    <ErrorBar errorMessage={errorMessage} />

                    <TableWithPagination
                        fetchData={(
                            pageIndexToFetch: number,
                            pageSizeToFetch: number,
                            filterToFetch: string,
                            sortToFetch: string
                        ) => {
                            setPageIndex(pageIndexToFetch);
                            setPageSize(pageSizeToFetch);
                            setFilter(filterToFetch);
                            setSort(sortToFetch);
                        }}
                        loading={isFetching}
                        columns={myColumns}
                        data={myCourseSessions?.results ?? []}
                        itemCount={myCourseSessions?.total ?? 0}
                        enableFiltering={true}
                        sortableProps={[
                            { name: 'Session', accessor: 'name' },
                            { name: 'Created at', accessor: 'createdAt' }
                        ]}
                    />
                </>
            )}
        </>
    );
};
