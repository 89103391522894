import { useContext, useEffect } from "react";
import "./App.css";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";

import { ChunkUploaderProvider } from "./features/uploader/ChunkUploaderService";
import { DebugRouter } from "./app-parts/DebugRouter";
import { RouterSwitch } from "./routes/RouterSwitch";
import { BackendVersionProvider } from "./features/AppVersionWatcher/BackendVersionProvider";
import { LangProvider } from "./lang/LangProvider";
import { LangContext } from "./lang/lang";
import { TeamContextProvider } from "./features/teams/context/team-context-provider";
import { AbilitiesProvider } from "./features/auth/abilities-provider";

const AppDocumentTitle = () => {
    const { ObjectNames } = useContext(LangContext);
    useEffect(() => {
        document.title = ObjectNames.appName.en;
    }, [ObjectNames]);
    return null;
};

function App() {
    return (
        <>
            <BackendVersionProvider intervalMs={300000}>
                <LangProvider>
                    <AppDocumentTitle />
                    <AbilitiesProvider>
                        <BrowserRouter>
                            <TeamContextProvider>
                                <ChunkUploaderProvider>
                                    <DebugRouter>
                                        <RouterSwitch />
                                    </DebugRouter>
                                </ChunkUploaderProvider>
                            </TeamContextProvider>
                        </BrowserRouter>
                    </AbilitiesProvider>
                </LangProvider>
            </BackendVersionProvider>
        </>
    );
}

export default App;
