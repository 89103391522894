import React from "react";
import { Form } from "react-bootstrap";

import { FkDraggableChoiceItem, FkDraggableChoiceItemProps } from "./FkDraggableChoiceItem";

export default function FKSingleChoiceItem(props: FkDraggableChoiceItemProps) {
    return (
        <FkDraggableChoiceItem {...props}>
            <Form.Check type="radio" disabled />
        </FkDraggableChoiceItem>
    );
}
