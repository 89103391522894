import { FieldHookConfig, useField } from 'formik'
import { FC } from 'react'
import { FormCheck, FormControl, FormGroup } from 'react-bootstrap'

export type BsFkCheckboxProps = FieldHookConfig<any> & {
    /** optional label for the field */
    label? : string,
}

export const BsFkCheckbox: FC<BsFkCheckboxProps> = ({ label, ...props }) => {
    const [field, meta] = useField({ ...props });
    return (
      <FormGroup>
            <FormCheck
                type='checkbox'
                name={field.name}
                label={label}
                onBlur={field.onBlur}
                onChange={field.onChange}
                checked={field.value}
                isInvalid={(meta.touched && (meta.error !== undefined)) || ((meta.initialError !== undefined) && (meta.initialValue === field.value))}
            />
            {
                (meta.touched && meta.error)
                    ? (<FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>)
                    : (meta.initialError && meta.initialValue === field.value)
                    ? (<FormControl.Feedback type="invalid">{meta.initialError}</FormControl.Feedback>)
                    : null
            }
      </FormGroup>
    );
  };