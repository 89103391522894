import { Formik, Form as FormikForm } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
    useAddLocalPasswordMutation,
    useAddSocialAccountMutation,
    useFindOneUserQuery,
    useGetUserAuthProvidersQuery,
    useRemoveAuthProviderMutation,
    useUpdateUserMutation,
} from "src/api/UserApi";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import {
    BootstrapDropdownList,
    BootstrapTextInput,
} from "src/components/BootstrapFormComponents";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { AuthProvider } from "src/api/generated.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TableSimple from "src/components/tables/TableSimple";
import { roles } from "./UserTools";
import { subject } from "@casl/ability";
import { Can, AbilityContext } from "../../casl/Can";
import { Action, Subjects } from "../../api/Permissions";
import { LangContext } from "src/lang/lang";
import axios from "axios";
import { AddAuthProviderForm } from "./AddAuthProvider";
interface EditUserProps {
    id: number;
}

export function UserEditor({ id }: EditUserProps) {
    const { Sentences } = useContext(LangContext);

    const {
        data: user,
        isFetching,
        isError,
        error,
    } = useFindOneUserQuery({ id });
    const { data: authProviders, isFetching: fetchingAuthProviders } =
        useGetUserAuthProvidersQuery({ id });
    const [isEdit, setIsEdit] = useState(false);
    const [authMethods, setAuthMethods] = useState({
        useGoogleOauth: false,
        useCas: false,
    });

    const [updateUser] = useUpdateUserMutation();

    const [removeAuthProvider] = useRemoveAuthProviderMutation();

    const [showAddPasswordForm, setShowAddPasswordForm] = useState(false);
    const [providerType, setProviderType] = useState("");

    const [errorMsg, setErrorMsg] = useState("");

    const ability = useContext(AbilityContext);
    useEffect(() => {
        setIsEdit(ability.can(Action.Update, subject("User", { id })));
    }, [ability, id]);

    useEffect(() => {
        setErrorMsg(isError ? JSON.stringify(error) : "");
    }, [isError, error]);

    const onAddPassword = () => {
        setProviderType("local");
        setShowAddPasswordForm(true);
    };

    const onAddCasAccount = () => {
        setProviderType("cas");
        setShowAddPasswordForm(true);
    };

    const onAddGoogleAccount = () => {
        setProviderType("google");
        setShowAddPasswordForm(true);
    };

    const columns = [
        {
            Header: "Authentication provider",
            width: undefined,
            Cell: ({ row }: { row: { original: AuthProvider } }) => (
                <div>{row.original.providerType}</div>
            ),
        },
        {
            Header: "Action",
            width: 125,
            Cell: ({ row }: { row: { original: AuthProvider } }) => (
                <Can I={Action.Update} this={subject("User", { id })}>
                    <Button
                        variant="outline-dark"
                        className="ml-1"
                        onClick={() =>
                            removeAuthProvider({
                                id,
                                authProviderId: row.original.id,
                            })
                        }
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Can>
            ),
        },
    ];

    useEffect(() => {
        axios
            .get("/api/auth/methods")
            .then((value) => setAuthMethods(value.data));
    }, []);

    return (
        <div>
            <ErrorBar errorMessage={errorMsg} />
            {isFetching ? (
                <div>Loading</div>
            ) : (
                <div>
                    {user && (
                        <Formik
                            validationSchema={Yup.object().shape({
                                username: Yup.string()
                                    .min(3, "too short!")
                                    .max(320, "too long!")
                                    .matches(RegExp("^[a-z0-9\\-_.+@]+$"), {
                                        excludeEmptyString: true,
                                        message:
                                            "Can only contain a-z, 0-9 hyphens (-), dots (.) and underscores (_). No space.",
                                    })
                                    .required("Required !"),
                            })}
                            initialValues={user}
                            enableReinitialize
                            onSubmit={async (values, formikBag) => {
                                formikBag.setSubmitting(true);
                                try {
                                    await updateUser({
                                        id,
                                        updateUserDto: {
                                            username: values["username"],
                                            role: values["role"],
                                            email: values["email"] ? values["email"] : null,
                                            firstName: values["firstName"],
                                            lastName: values["lastName"],
                                        },
                                    });
                                } catch (e) {
                                    try {
                                        const errors =
                                            convertApiErrorsToFormikErrors(e);
                                        formikBag.setErrors(errors);
                                    } catch (newErr) {
                                        setErrorMsg(JSON.stringify(newErr));
                                    }
                                }
                            }}
                        >
                            {(formik) => (
                                <>
                                    <FormikForm
                                        onSubmit={formik.handleSubmit}
                                        method="post"
                                    >
                                        <Container className="section">
                                            <Row>
                                                <Col>
                                                    <h1>
                                                        {
                                                            Sentences
                                                                .userDetails.en
                                                        }
                                                    </h1>
                                                </Col>
                                                <Col
                                                    md="auto"
                                                    className="align-items-end d-flex"
                                                >
                                                    {isEdit && (
                                                        <Button
                                                            disabled={!formik.dirty}
                                                            type="submit">
                                                            {Sentences.save.en}
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container>
                                            <div className="section">
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="Username *"
                                                    name="username"
                                                    placeholder="username"
                                                    disabled={!isEdit}
                                                />
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="Email"
                                                    name="email"
                                                    placeholder="email"
                                                    disabled={!isEdit}
                                                />
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="First name"
                                                    name="firstName"
                                                    placeholder="firstname"
                                                    disabled={!isEdit}
                                                />
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="Last name"
                                                    name="lastName"
                                                    placeholder="lastname"
                                                    disabled={!isEdit}
                                                />
                                                <BootstrapDropdownList
                                                    labelColWidth={2}
                                                    label="Role"
                                                    name="role"
                                                    items={roles}
                                                    className="mt-5"
                                                    disabled={ability.cannot(
                                                        Action.Update,
                                                        Subjects.Users,
                                                        "role",
                                                    )}
                                                />
                                            </div>
                                        </Container>
                                    </FormikForm>
                                </>
                            )}
                        </Formik>
                    )}
                    <Container className="section">
                        <h2>Login methods</h2>

                        <TableSimple
                            data={authProviders ?? []}
                            columns={columns}
                            loading={fetchingAuthProviders}
                            fetchData={() => { }}
                        />
                        {isEdit && (
                            <Row>
                                <Col>
                                    <Button onClick={onAddPassword} size="sm">
                                        {Sentences.userAddLocalPassword.en}
                                    </Button>
                                </Col>
                                {authMethods.useCas && (
                                    <Col>
                                        <Button
                                            onClick={onAddCasAccount}
                                            size="sm"
                                        >
                                            {Sentences.userAddCasAccount.en}
                                        </Button>
                                    </Col>
                                )}
                                {authMethods.useGoogleOauth && (
                                    <Col>
                                        <Button
                                            onClick={onAddGoogleAccount}
                                            size="sm"
                                        >
                                            {Sentences.userAddGoogleAccount.en}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </Container>
                    {showAddPasswordForm && isEdit && (
                        <AddAuthProviderForm
                            userId={id}
                            onSuccess={() => setShowAddPasswordForm(false)}
                            onErrorMsg={setErrorMsg}
                            providerType={providerType}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
