import React from 'react';
import { selectAuthStatus, selectLoggedInUser } from "./authSlice";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Navbar, NavDropdown } from 'react-bootstrap';
import { LangContext } from 'src/lang/lang';
import { faAddressBook, faSignOutAlt, faUserCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TeamContextSelector } from '../teams/context/team-context-selector';
import RoutesDef from 'src/routes/RoutesDef';

export function CurrentUser() {
    const { ObjectNames, Sentences } = React.useContext(LangContext);

    const authStatus = useSelector(selectAuthStatus);
    const user = useSelector(selectLoggedInUser);
    const userIcon = (<span><FontAwesomeIcon className="mr-2 bigIcon" icon={faUserCircle} />{user?.username}</span>)
    if (authStatus === 'LOGGED_IN') {
        return (
            <NavDropdown className='navDropDownWithoutArrow wideNavDropDown' alignRight title={userIcon} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to={RoutesDef.ADMIN_MY_TEAMS}>
                    <FontAwesomeIcon
                        className="mr-2"
                        icon={faUserFriends}
                    />{" "}
                    My teams
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={RoutesDef.ADMIN_MY_PROFILE}><FontAwesomeIcon className="mr-2" icon={faAddressBook} /> {Sentences.myProfile.en}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={RoutesDef.LOGOUT}> <FontAwesomeIcon className="mr-2" icon={faSignOutAlt} /> {Sentences.logout.en}</NavDropdown.Item>
            </NavDropdown>)
    }

    return (<Navbar.Text>
        <Link to={RoutesDef.LOGIN}>{Sentences.login.en}</Link>
    </Navbar.Text>);
}
