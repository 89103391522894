import { FeatureLike } from "ol/Feature";
import { Coordinate } from "ol/coordinate";
import { Fill, Stroke, RegularShape } from "ol/style";
import Style, { RenderFunction } from "ol/style/Style";

export const createGradientCircleRenderer = (
    color: number[],
): RenderFunction => {
    return (coordinates, state) => {
        const [[x, y], [x1, y1]] = coordinates as Coordinate[];
        const ctx = state.context;
        const dx = x1 - x;
        const dy = y1 - y;
        const radius = Math.sqrt(dx * dx + dy * dy);

        const innerRadius = 0;
        const outerRadius = radius * 1.4;

        const gradient = ctx.createRadialGradient(
            x,
            y,
            innerRadius,
            x,
            y,
            outerRadius,
        );
        gradient.addColorStop(
            0,
            `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.0)`,
        );
        gradient.addColorStop(
            0.6,
            `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.1)`,
        );
        gradient.addColorStop(
            1,
            `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.8)`,
        );
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
        ctx.fillStyle = gradient;
        ctx.fill();

        ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
        ctx.strokeStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)`;
        ctx.stroke();
    };
};

export const blackStroke = new Stroke({ color: "black", width: 2 });
export const redFill = new Fill({ color: "red" });
export const redFovFill = new Fill({ color: "rgba(255,255,0,0.3)" });

export const createXStyle = () =>
    new Style({
        image: new RegularShape({
            fill: redFill,
            stroke: blackStroke,
            points: 4,
            radius: 10,
            radius2: 0,
            angle: Math.PI / 4,
        }),
    });

export const createFovStyle = (feature: FeatureLike, resolution: number) => {
    return [
        new Style({
            image: new RegularShape({
                fill: redFovFill,
                points: 3,
                radius: 50,
                rotation: (180 + feature.get("angle")) * (Math.PI / 180),
                displacement: [0, -50],
                angle: 0,
            }),
        }),
    ];
};
