import {
    faChevronRight,
    faChevronDown,
    faImages,
    faUsers,
    faBookOpen,
    faMapMarker,
    faMapSigns,
    faMap,
    faList,
    faFile,
    faCheckCircle,
    faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState, ReactNode, ReactPropTypes } from "react";
import {
    Collapse,
    Nav,
    Row,
    Col,
    OverlayTriggerProps,
    NavProps,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
    ActivityProgressByGroupLayoutsDto,
    SlideProgressSummary,
} from "src/api/generated.api";
import { ReactComponent as ActivityIcon } from "../../design-system/Icons/Icons-x65_SVG/Activity.svg";
import { IconWithTooltip } from "../../../components/IconWithTooltip";

export type CollapsableItemProps = {
    header: ReactNode;
    content: ReactNode;
};

export const CollapsableItem: FC<CollapsableItemProps> = ({
    header,
    content,
}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div
                onClick={() => setOpen((_open) => !_open)}
                aria-controls="collapsedItem"
                aria-expanded={open}
            >
                <div className="d-inline mx-2">
                    <FontAwesomeIcon
                        icon={open ? faChevronDown : faChevronRight}
                    />
                </div>
                <div className="d-inline">{header}</div>
            </div>
            <Collapse in={open}>
                <div id="collapsedItem">{content}</div>
            </Collapse>
        </>
    );
};

type GroupItemProps = {
    title: string;
};

export const GroupItem: FC<GroupItemProps> = ({ title }) => {
    return (
        <>
            <FontAwesomeIcon className="d-inline" icon={faUsers} />
            <div
                className="mx-2 d-inline-block text-truncate align-middle"
                style={{ maxWidth: "200px" }}
            >
                {title}
            </div>
        </>
    );
};

type ActivityItemProps = {
    title: string;
};

export const ActivityItem: FC<ActivityItemProps> = ({ title }) => {
    return (
        <>
            <FontAwesomeIcon className="d-inline" icon={faBookOpen} />
            <div
                className="mx-2 d-inline-block text-truncate align-middle"
                style={{ maxWidth: "200px" }}
            >
                {title}
            </div>
        </>
    );
};

export type SlideProgressItemProps = {
    slideProgress: SlideProgressSummary;
};

export const SlideProgressItem: FC<SlideProgressItemProps> = ({
    slideProgress,
}) => {
    return (
        <Nav.Link eventKey={slideProgress.slideProgressId}>
            Slide: {slideProgress.slideId}{" "}
            <IconWithTooltip
                id="visited-icon"
                tooltipText={slideProgress.visited ? "visited" : "not visited"}
                icon={slideProgress.visited ? faCheckCircle : faFile}
            />{" "}
            {slideProgress.hasAnswers && (
                <>
                    <IconWithTooltip
                        id="answered-icon"
                        tooltipText={"Has Answers"}
                        icon={faList}
                    />{" "}
                </>
            )}
            {slideProgress.hasData && (
                <>
                    <IconWithTooltip
                        id="geodata-icon"
                        tooltipText={"Has Geo Data"}
                        icon={faMap}
                    />{" "}
                </>
            )}
        </Nav.Link>
    );
};

export type CourseSessionProgressNavProps = NavProps & {
    activityProgressSummaries: ActivityProgressByGroupLayoutsDto[];
};

export const CourseSessionProgressNav: FC<CourseSessionProgressNavProps> = ({
    activityProgressSummaries,
    ...props
}) => {
    return (
        <Nav {...props} className="flex-column">
            {activityProgressSummaries.map((aps) => (
                <CollapsableItem
                    key={"aps" + aps.activityProgressId}
                    header={<ActivityItem title={aps.activityName} />}
                    content={
                        <>
                            {aps.groupLayoutProgress.map((glp) => (
                                <CollapsableItem
                                    key={"glp" + glp.groupLayoutId}
                                    header={
                                        <>
                                            <FontAwesomeIcon
                                                className="d-inline"
                                                icon={faPeopleArrows}
                                            />
                                            <div
                                                className="mx-2 d-inline-block text-truncate align-middle"
                                                style={{ maxWidth: "200px" }}
                                            >
                                                Mode: {glp.groupLayoutName}
                                            </div>
                                        </>
                                    }
                                    content={
                                        <>
                                            {glp.groupProgress.map((grp) => (
                                                <CollapsableItem
                                                    key={"grp" + grp.groupId}
                                                    header={
                                                        <GroupItem
                                                            title={
                                                                grp.groupName
                                                            }
                                                        />
                                                    }
                                                    content={
                                                        <>
                                                            {grp.slideProgressRecords.map(
                                                                (spr) => (
                                                                    <SlideProgressItem
                                                                        slideProgress={
                                                                            spr
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                        </>
                                                    }
                                                />
                                            ))}
                                        </>
                                    }
                                />
                            ))}
                        </>
                    }
                />
            ))}
        </Nav>
    );
};
