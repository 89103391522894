import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import MarkdownView from "react-showdown";
import { useGetSlideProgressQuery } from "src/api/SlideProgressApi";
import ErrorBar from "src/components/ErrorBar";
import { QuizResultsView } from "../../quiz/QuizResultsView";
import { GeoObject } from "../../terrains/measurements/GeoObject";
import { LayerMap } from "../../terrains/TerrainMap";
import { SlideProgressMediaList } from "./SlideProgressMediaList";

export type SlideProgressViewProps = {
    slideProgressId: number;
};

export const SlideProgressView: FC<SlideProgressViewProps> = ({
    slideProgressId,
}) => {
    const {
        data: slideProgress,
        error,
        isError,
        isFetching,
    } = useGetSlideProgressQuery(
        { slideProgressId },
        { refetchOnMountOrArgChange: true, pollingInterval: 30000 },
    );

    const onDownLoadClick = () => {
        if (slideProgress) {
            const features = slideProgress.geoFeatures.map((geoFeature) =>
                JSON.parse(geoFeature.content),
            );
            const geoJson = {
                type: "FeatureCollection",
                features,
            };
            var element = document.createElement("a");
            var filename = `SlideProgress_${slideProgress.id}-Slide_${slideProgress.slideId}-Group_${slideProgress.groupId}_geofeatures.geojson`;
            element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," +
                    encodeURIComponent(JSON.stringify(geoJson, null, 4)),
            );
            element.setAttribute("download", filename);

            element.style.display = "none";
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    };

    return (
        <div>
            {isFetching && <div>Loading slide details for group...</div>}
            <ErrorBar errorMessage={isError ? JSON.stringify(error) : ""} />
            {slideProgress && (
                <>
                    <Alert variant="primary">
                        <Alert.Heading>
                            {slideProgress.slide.title}
                        </Alert.Heading>
                        <Row>
                            <Col>spId: {slideProgress.id}</Col>
                            <Col sm="auto">
                                Group: {slideProgress.group.name}
                            </Col>
                        </Row>
                    </Alert>

                    <Card>
                        <Card.Header>
                            <Card.Title>Page Content</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <MarkdownView
                                style={{ height: 400 }}
                                className="overflow-auto"
                                markdown={slideProgress.slide.content}
                                options={{ tables: true, emoji: true }}
                            ></MarkdownView>
                        </Card.Body>
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>
                            <Card.Title>Quiz</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <QuizResultsView
                                quiz={slideProgress.slide.quiz}
                                quizType={slideProgress.slide.quizType}
                                answers={slideProgress.quizAnswers}
                            />
                        </Card.Body>
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>
                            <Card.Title>Object created</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    number of objects:{" "}
                                    {slideProgress.geoFeatures.length}
                                </Col>
                                <Col md="auto">
                                    {slideProgress.geoFeatures.length > 0 && (
                                        <Button onClick={onDownLoadClick}>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip
                                                        id={props.id}
                                                        {...props}
                                                    >
                                                        Download as geojson
                                                    </Tooltip>
                                                )}
                                                placement="bottom"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                />
                                            </OverlayTrigger>
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col md="auto">
                                    {slideProgress.geoFeatures.map(
                                        ({ id, content }) => (
                                            <div key={id}>
                                                <GeoObject
                                                    geojson={JSON.parse(
                                                        content,
                                                    )}
                                                />
                                            </div>
                                        ),
                                    )}
                                </Col>
                                {slideProgress.slide.terrain?.terrainLayers
                                    ?.length === 1 && (
                                    <Col>
                                        <div
                                            className="mt-3"
                                            style={{ height: 400 }}
                                        >
                                            <LayerMap
                                                geojson={{
                                                    type: "FeatureCollection",
                                                    features:
                                                        slideProgress.geoFeatures.map(
                                                            (geoFeature) =>
                                                                JSON.parse(
                                                                    geoFeature.content,
                                                                ),
                                                        ),
                                                }}
                                                terrainLayer={
                                                    slideProgress.slide.terrain
                                                        .terrainLayers[0]
                                                }
                                            />
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>
                            <Card.Title>Files attached</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <SlideProgressMediaList
                                slideProgressId={slideProgressId}
                            />
                        </Card.Body>
                    </Card>
                </>
            )}
        </div>
    );
};
