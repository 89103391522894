import { Form as FormikForm, Formik } from "formik";
import { FC } from "react";
import { DocumentWithAcl } from "./document-acl-types";
import { UpdateDocumentVisibilityDto } from "src/api/generated.api";
import { FkStackedDropDownList } from "src/components/BootstrapFormComponents";
import { ConfimButton } from "src/components/ConfirmButton";
import { Button, Col, Form, Row } from "react-bootstrap";

const items: ("Private" | "Team" | "Public")[] = ["Private", "Team", "Public"];

export type DocumentVisibilityEditorProps = {
    document: DocumentWithAcl;
    onVisibilityUpdate: (arg: {
        id: string;
        updateDocumentVisibilityDto: UpdateDocumentVisibilityDto;
    }) => void;
};

export const DocumentVisibilityEditor: FC<DocumentVisibilityEditorProps> = ({
    document,
    onVisibilityUpdate,
}) => {
    const onsubmit = (values: UpdateDocumentVisibilityDto) => {
        onVisibilityUpdate({
            id: `${document.id}`,
            updateDocumentVisibilityDto: { visibility: values.visibility },
        });
    };
    return (
        <Formik
            onSubmit={onsubmit}
            initialValues={{
                visibility:
                    items.find((v) => v === document.visibility) || "Private",
            }}
            enableReinitialize
        >
            {(formik) => (
                <FormikForm>
                    <Row>
                        <Form.Label column sm="3">
                            Document Visibility :
                        </Form.Label>
                        <Col>
                            <FkStackedDropDownList
                                name="visibility"
                                items={items}
                            />
                        </Col>
                        {formik.dirty && (
                            <>
                                <Col md="auto">
                                    <ConfimButton
                                        onClick={formik.submitForm}
                                        message="Changing document visibility may expose its content to other users. Are you sure ?"
                                        variant="danger"
                                    >
                                        Change Visibility
                                    </ConfimButton>
                                </Col>
                                <Col md="auto">
                                    <Button
                                        onClick={() => formik.resetForm()}
                                        variant="primary"
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </>
                        )}
                    </Row>
                </FormikForm>
            )}
        </Formik>
    );
};
