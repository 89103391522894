import { Subjects } from "./Permissions";
import {
    DocumentAclTagList,
    invalidateDocumentAclEntryTag,
    invalidateDocumentTagsFromId,
    provideDocumentAclTags,
} from "./document-access-control.api";
import { api as generatedApi } from "./generated.api";

export const CourseSessionApi = generatedApi.enhanceEndpoints({
    addTagTypes: [
        "CourseSession",
        "ActivityList",
        "ActivityLinks",
        "StudentList",
        "ActivityProgress",
        "SlideSummary",
        "SlideProgress",
        "Activity",
        ...DocumentAclTagList,
    ],
    endpoints: {
        courseSessionControllerFindOne: {
            providesTags: (result) =>
                result
                    ? [
                        { type: "CourseSession" as const, id: result.id },
                        ...provideDocumentAclTags(
                            result,
                            Subjects.CourseSessions,
                        ),
                    ]
                    : [],
        },
        courseSessionControllerFindAll: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        ...result.map(({ id }) => ({
                            type: "CourseSession" as const,
                            id,
                        })),
                        "CourseSession",
                    ]
                    : [],
        },
        courseSessionControllerFindAllPaginated: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        ...result.results.map(({ id }) => ({
                            type: "CourseSession" as const,
                            id,
                        })),
                        "CourseSession",
                    ]
                    : [],
        },
        courseSessionControllerCreate: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result
                    ? [
                        { type: "CourseSession" as const, id: result.id },
                        "CourseSession",
                    ]
                    : [];
        },
        courseSessionControllerUpdate: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result
                    ? [
                        { type: "CourseSession" as const, id: result.id },
                        "CourseSession",
                    ]
                    : [];
        },
        courseSessionControllerSetCurrentMode: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result
                    ? [
                        { type: "CourseSession" as const, id: result.id },
                        "CourseSession",
                    ]
                    : [];
        },
        courseSessionControllerRemove: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                        {
                            type: "CourseSession" as const,
                            id: arg.courseSessionId,
                        },
                        "CourseSession",
                        ...invalidateDocumentTagsFromId(
                            Subjects.CourseSessions,
                            arg.courseSessionId,
                        ),
                    ]
                    : [];
        },
        courseSessionControllerGetActivities: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        {
                            type: "ActivityList" as const,
                            id: arg.courseSessionId,
                        },
                        "ActivityList",
                    ]
                    : [],
        },
        courseSessionControllerGetAllActivityProgress: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        {
                            type: "ActivityLinks" as const,
                            id: arg.courseSessionId,
                        },
                        "ActivityLinks",
                    ]
                    : [],
        },
        courseSessionControllerConnectActivity: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                        {
                            type: "ActivityLinks" as const,
                            id: arg.courseSessionId,
                        },
                        "ActivityLinks",
                    ]
                    : [];
        },
        courseSessionControllerDisconnectActivity: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                        {
                            type: "ActivityLinks" as const,
                            id: arg.courseSessionId,
                        },
                        "ActivityLinks",
                    ]
                    : [];
        },
        courseSessionControllerUpdateActivities: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                        {
                            type: "ActivityLinks" as const,
                            id: arg.courseSessionId,
                        },
                        "ActivityLinks",
                    ]
                    : [];
        },
        courseSessionStudentsControllerGetAllStudents: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        {
                            type: "StudentList" as const,
                            id: arg.courseSessionId,
                        },
                        "StudentList",
                    ]
                    : [],
        },
        courseSessionStudentsControllerAddStudent: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "StudentList" as const, id: arg.courseSessionId },
                "StudentList",
            ];
        },
        courseSessionStudentsControllerRemoveStudent: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "StudentList" as const, id: arg.courseSessionId },
                "StudentList",
            ];
        },
        courseSessionControllerGetOneActivityProgress: {
            providesTags: (result) =>
                result
                    ? [
                        { type: "ActivityProgress" as const, id: result.id },
                        "ActivityProgress",
                    ]
                    : [],
        },
        courseSessionControllerGetSlidesSummaryForActivityProgress: {
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({
                            type: "SlideSummary" as const,
                            id,
                        })),
                        "SlideSummary",
                    ]
                    : [],
        },
        courseSessionControllerGetAllSlideProgressRecordsForSlide: {
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({
                            type: "SlideProgress" as const,
                            id,
                        })),
                        "SlideProgress",
                    ]
                    : [],
        },
        courseSessionControllerSetOwner: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) =>
                result
                    ? [
                        {
                            type: "CourseSession" as const,
                            id: args.courseSessionId,
                        },
                        "CourseSession",
                    ]
                    : [];
        },
        courseSessionControllerCreateAndConnectedActivity: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                        {
                            type: "ActivityLinks" as const,
                            id: arg.courseSessionId,
                        },
                        { type: "Activity" as const, id: result.id },
                        "Activity",
                        "ActivityLinks",
                    ]
                    : [];
        },
        courseSessionControllerAddUser: {
            invalidatesTags: invalidateDocumentAclEntryTag,
        },
        courseSessionControllerChangeTeam: {
            invalidatesTags: (result, error, arg) => [
                { type: "CourseSession" as const, id: parseInt(arg.id) },
            ],
        },
        courseSessionControllerChangeVisibility: {
            invalidatesTags: (result, error, arg) => [
                { type: "CourseSession" as const, id: parseInt(arg.id) },
            ],
        },
    },
});

export const {
    useCourseSessionControllerCreateMutation: useCreateCourseSessionMutation,
    useCourseSessionControllerRemoveMutation: useDeleteCourseSessionMutation,
    useCourseSessionControllerUpdateMutation: useUpdateCourseSessionMutation,
    useCourseSessionControllerSetCurrentModeMutation:
    useSetCourseSessionModeMutation,
    useCourseSessionControllerFindOneQuery: useGetCourseSessionQuery,
    useCourseSessionControllerFindAllQuery: useGetAllCourseSessionsQuery,
    useCourseSessionControllerGetActivitiesQuery:
    useGetCourseSessionActivitiesQuery,
    useCourseSessionControllerGetAllActivityProgressQuery:
    useGetCourseSessionActivityProgressQuery,
    useCourseSessionStudentsControllerGetAllStudentsQuery:
    useGetCourseSessionStudentsQuery,
    useCourseSessionControllerConnectActivityMutation:
    useConnectActivityWithCourseSessionMutation,
    useCourseSessionControllerDisconnectActivityMutation:
    useDisconnectActivityFromCourseSessionMutation,
    useCourseSessionControllerUpdateActivitiesMutation:
    useUpdateCourseSessionActivitiesMutation,
    useCourseSessionStudentsControllerAddStudentMutation:
    useAddStudentToCourseSessionMutation,
    useCourseSessionStudentsControllerRemoveStudentMutation:
    useRemoveStudentFromCourseSessionMutation,
    useCourseSessionStatsControllerGetActivityStatsQuery:
    useGetActivityStatsQuery,
    useCourseSessionControllerGetOneActivityProgressQuery:
    useGetActivityProgressQuery,
    useCourseSessionControllerGetSlidesSummaryForActivityProgressQuery:
    useGetSlidesSummaryQuery,
    useCourseSessionControllerGetAllSlideProgressRecordsForSlideQuery:
    useGetSlideProgressForSlideQuery,
    useCourseSessionControllerSetOwnerMutation:
    useSetCourseSessionOwnerMutation,
    useCourseSessionControllerCreateAndConnectedActivityMutation:
    useCreateAndConnectActivityMutation,
    useCourseSessionControllerAddUserMutation: useCourseSessionAddUser,
    useCourseSessionControllerChangeTeamMutation: useChangeTeam,
    useCourseSessionControllerChangeVisibilityMutation: useChangeVisibility,
} = CourseSessionApi;
