import { FC, useContext } from "react";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Column, Row } from "react-table";
import {
    TeamRole,
    useTeamRoleControllerGetTeamRolesQuery,
} from "src/api/team.api";
import { EditButton } from "src/components/EditButton";
import TableSimple from "src/components/tables/TableSimple";
import { teamContext } from "../context/team-context-provider";

export const TeamRoleTable: FC<any> = () => {
    const { currentTeam } = useContext(teamContext);
    const { data, isLoading } = useTeamRoleControllerGetTeamRolesQuery(
        {
            teamId: currentTeam ? currentTeam.id : "",
        },
        { refetchOnMountOrArgChange: true },
    );

    const navigate = useNavigate();
    const columns: Column<TeamRole>[] = [
        {
            Header: "name",
            accessor: "name",
        },
        {
            Header: "permissions",
            Cell: ({ row }: { row: Row<TeamRole> }) => (
                <div>
                    {row.original.permissions.map((val, index) => (
                        <Badge key={`perm-${row.original.id}-${index}`}>
                            {val.action}
                        </Badge>
                    ))}
                </div>
            ),
        },
        {
            Header: "action",
            Cell: ({ row }: { row: Row<TeamRole> }) => (
                <>
                    <EditButton
                        onClick={() =>
                            navigate(
                                `/admin/teams/roles/edit/${row.original.id}`,
                            )
                        }
                    />
                </>
            ),
        },
    ];

    return (
        <TableSimple
            columns={columns}
            data={data || []}
            fetchData={() => { }}
            loading={isLoading}
        />
    );
};
