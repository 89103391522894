import { FC, useContext } from "react";
import { Col } from "react-bootstrap";
import { LangContext } from "../lang/lang";
import { AppInfoContext } from "src/features/AppVersionWatcher/BackendVersionProvider";

export const About: FC<any> = () => {
    const { Sentences } = useContext(LangContext);
    const version = VERSION ? VERSION : "undefined";
    const appVersion = useContext(AppInfoContext);
    return (
        <>
            <div className="bg text-center row">
                <Col className="my-auto">
                    <h1 className="display-4">{Sentences.about.en}</h1>
                    <p>Contact support :{" "}
                        <a
                            href={"mailto:" + appVersion?.supportEmail}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {appVersion?.supportEmail}
                        </a>
                    </p>
                    <p>
                        Designed and built by{" "}
                        <a
                            href="https://vr2planets.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            VR2Planets
                        </a>
                        .{" "}
                    </p>

                    <p>Frond end version: {version} </p>
                    {appVersion ? (
                        <p>Back end version: {appVersion?.version}</p>
                    ) : (
                        ""
                    )}
                    <p>Code licensed</p>
                </Col>
            </div>
        </>
    );
};
