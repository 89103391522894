import { FieldHookConfig, useField } from "formik";
import { FC } from "react";
import { BootstrapTextInput } from "src/components/BootstrapFormComponents";
import { FkEditNumber } from "src/components/FkEditNumber";

export type FkEditJobProps = FieldHookConfig<any> & {

}
export const FkEditJob: FC<FkEditJobProps> = ({...props}) => {
    const [field, meta] = useField({ ...props });

    return (<div>
        <BootstrapTextInput label="Dem file path" name="demFilename" placeholder="path of the DEM file"/>
        <BootstrapTextInput label="Tex file path" name="textureFilename" placeholder="path of the TEX file"/>
        <BootstrapTextInput label="Output directory" name="outDir" placeholder="output directory of tiles"/>
        <FkEditNumber label="Dem tile size" name="demTileSize"/>
        <FkEditNumber label="Tex tile size" name="textureTileSize"/>
    </div>)
}
