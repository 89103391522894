import { LAYER_TAG } from "./LayerApi";
import { Subjects } from "./Permissions";
import {
    DocumentAclTagList,
    invalidateDocumentAclEntryTag,
    invalidateDocumentTagsFromId,
    provideDocumentAclTags,
} from "./document-access-control.api";
import { api as generatedApi } from "./generated.api";

export const TerrainApi = generatedApi.enhanceEndpoints({
    addTagTypes: [
        "Terrain",
        "TerrainPage",
        "TerrainModelLinkList",
        "TerrainToTerrainModel",
        "TerrainToTerrainLayer",
        ...DocumentAclTagList,
        LAYER_TAG,
    ],
    endpoints: {
        terrainsControllerFindAllPaginated: {
            providesTags: (paginatedResult, error, arg) =>
                paginatedResult
                    ? [
                          ...paginatedResult.results.map(({ id }) => ({
                              type: "Terrain" as const,
                              id,
                          })),
                          { type: "Terrain" as const, id: "Page" },
                      ]
                    : [{ type: "Terrain" as const, id: "Page" }],
        },
        terrainsControllerFindOne: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                          { type: "Terrain" as const, id: result.id },
                          ...provideDocumentAclTags(result, Subjects.Terrains),
                      ]
                    : [],
        },
        terrainsControllerCreate: (endpoint) => {
            endpoint.invalidatesTags = (result) => [
                { type: "Terrain" as const, id: "Page" },
            ];
        },
        terrainsControllerUpdate: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result ? [{ type: "Terrain" as const, id: result.id }] : [];
        },
        terrainsControllerRemove: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "Terrain" as const, id: "Page" },
                ...invalidateDocumentTagsFromId(
                    Subjects.Terrains,
                    arg.terrainId,
                ),
            ];
        },
        terrainsControllerGetTerrainModelLinks: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "TerrainToTerrainModel" as const,
                              id,
                          })),
                          {
                              type: "TerrainModelLinkList" as const,
                              id: arg.terrainId,
                          },
                      ]
                    : [],
        },
        terrainsControllerUpdateTerrainModelLink: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          {
                              type: "TerrainToTerrainModel" as const,
                              id: arg.terrainToTerrainModelId,
                          },
                          {
                              type: "TerrainModelLinkList" as const,
                              id: arg.terrainId,
                          },
                      ]
                    : [];
        },
        terrainsControllerDeleteTerrainModelLinks: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          {
                              type: "TerrainModelLinkList" as const,
                              id: arg.terrainId,
                          },
                      ]
                    : [];
        },
        terrainsControllerCreateTerrainModelLink: (endpoints) => {
            endpoints.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          {
                              type: "TerrainModelLinkList" as const,
                              id: arg.terrainId,
                          },
                      ]
                    : [];
        },
        terrainsControllerGetTerrainLayers: {
            providesTags: (result, error, args) =>
                result
                    ? [
                          ...result.map((layer) => ({
                              type: LAYER_TAG,
                              id: layer.id,
                          })),
                      ]
                    : [],
        },
        terrainsControllerCreateTerrainLayer: (endpoints) => {
            endpoints.invalidatesTags = (result) => (result ? [LAYER_TAG] : []);
        },
        terrainsControllerCreateTerrainLayerLink: (endpoints) => {
            endpoints.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          {
                              type: "TerrainToTerrainLayer" as const,
                              id: arg.terrainId,
                          },
                      ]
                    : [];
        },
        terrainsControllerDetachLayer: (endpoints) => {
            endpoints.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          {
                              type: "TerrainToTerrainLayer" as const,
                              id: arg.terrainId,
                          },
                      ]
                    : [];
        },
        terrainsControllerAddUser: {
            invalidatesTags: invalidateDocumentAclEntryTag,
        },
        terrainsControllerChangeTeam: {
            invalidatesTags: (result, error, arg) => [
                { type: "Terrain" as const, id: parseInt(arg.id) },
                ...invalidateDocumentTagsFromId(Subjects.Terrains, arg.id),
            ],
        },
        terrainsControllerChangeVisibility: {
            invalidatesTags: (result, error, arg) => [
                { type: "Terrain" as const, id: parseInt(arg.id) },
            ],
        },
    },
});

export const {
    useTerrainsControllerFindAllPaginatedQuery: useFindAllTerrainsQuery,
    useTerrainsControllerFindOneQuery: useFindOneTerrainQuery,
    useTerrainsControllerGetTerrainModelLinksQuery:
        useGetTerrainModelLinksQuery,
    useTerrainsControllerGetTerrainLayersQuery: useGetTerrainLayersQuery,
    useTerrainsControllerCreateMutation: useCreateTerrainMutation,
    useTerrainsControllerCreateTerrainModelLinkMutation:
        useCreateTerrainModelLinkMutation,
    useTerrainsControllerUpdateMutation: useUpdateTerrainMutation,
    useTerrainsControllerRemoveMutation: useDeleteTerrainMutation,
    useTerrainsControllerDeleteTerrainModelLinksMutation:
        useDeleteTerrainModelLinksMutation,
    useTerrainsControllerUpdateTerrainModelLinkMutation:
        useUpdateTerrainModelLinkMutation,
    useTerrainsControllerCreateTerrainLayerMutation:
        useCreateTerrainLayerForTerrainMutation,
    useTerrainsControllerCreateTerrainLayerLinkMutation:
        useAttachLayerToTerrainMutation,
    useTerrainsControllerDetachLayerMutation: useDetachLayerFromTerrainMutation,
    useTerrainsControllerAddUserMutation: useTerrainAddUser,
    useTerrainsControllerChangeTeamMutation: useChangeTeam,
    useTerrainsControllerChangeVisibilityMutation: useChangeVisibility,
} = TerrainApi;
