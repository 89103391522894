import React, { ReactPropTypes, useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import { Terrains } from "./Terrains";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { TerrainsUnity } from "./Terrains.unity";

export default function TerrainsPageUnity() {
    const { Sentences } = useContext(LangContext);

    const ability = useContext(AbilityContext);

    return (<>
        <Container>
            {ability.can(Action.Read, Subjects.Terrains)
                ? <TerrainsUnity />
                : <ErrorBar errorMessage={Sentences.pageNotAllowed.en} />
            }
        </Container>
    </>
    )
}