import { FC, useContext, useEffect, useState } from "react";
import { useMyTeamsControllerFindMineQuery, useTeamControllerFindAllQuery } from "src/api/team.api";

import { teamContext } from "./team-context-provider";
import { FkStackedDropdownObj } from "src/components/BootstrapFormComponents";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import { selectAuthStatus, selectLoggedInUser } from "src/features/auth/authSlice";
import { NavDropdown } from "react-bootstrap";

export const TeamContextSelector: FC<unknown> = () => {
    const authStatus = useSelector(selectAuthStatus);

    const { currentTeam, setCurrentTeam } = useContext(teamContext);
    const user = useSelector(selectLoggedInUser);
    const [id, setId] = useState<number>();

    const useQueryTeamFunc = user?.role == 'ADMIN' ? useTeamControllerFindAllQuery : useMyTeamsControllerFindMineQuery;

    const { data, isLoading, isFetching } = useQueryTeamFunc({});
    const onSubmit = (values: any) => {
        localStorage.setItem('CurrentTeam', JSON.stringify(values.team))
        setCurrentTeam(values.team);
    };

    if (authStatus !== "LOGGED_IN" || data?.results.length == 1) return <></>;

    return (
        <>
            {!isFetching &&
                (<> {id}
                    <Formik

                        initialValues={{
                            team: currentTeam || { id: "", name: "not ready" },
                        }}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >

                        {(formik) => (
                            <>
                                <div className="nav-text ">Current team :&nbsp;</div>
                                <Form className="formGroupNoMargin mr-4">


                                    <FkStackedDropdownObj
                                        name="team"
                                        items={(data ? data.results : []).map((team) => ({
                                            ...team,
                                            id: team.teamId,
                                        }))}
                                        onChange={formik.submitForm}
                                    />



                                </Form>
                            </>

                        )}
                    </Formik>
                    <NavDropdown.Divider />
                </>)
            }
        </>
    );
};
