import { FC, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AppInfoContext } from "./BackendVersionProvider";

export const VersionIsNewerRefreshButton: FC<any> = () => {
    const appVersion = useContext(AppInfoContext);
    const [needRefresh, setNeedRefresh] = useState(false);

    useEffect(() => {
        setNeedRefresh(appVersion?.version !== VERSION);
    }, [appVersion]);

    return (
        <>
            {needRefresh && (
                <Button
                    className="mx-4"
                    variant="warning"
                    onClick={() => window.location.reload()}
                >
                    Newer version available. Click to refresh
                </Button>
            )}
        </>
    );
};
