import { Container } from "react-bootstrap";
import { TeamRoleEditor } from "./edit-team-role";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { teamContext } from "../context/team-context-provider";

export const TeamRoleCreatorPage = () => {
    const navigate = useNavigate();
    const { currentTeam } = useContext(teamContext);
    return (<>
        {currentTeam && (
            <Container className="section">
                <TeamRoleEditor
                    teamId={currentTeam.id}
                    onSave={(role) => navigate("/admin/teams/roles")}
                />
            </Container>
        )
        }
    </>)
};
