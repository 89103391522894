import { Field, FieldHookConfig, useField } from "formik";
import { FC } from "react";
import { Form, FormGroup, FormLabel } from "react-bootstrap";

export type RichListItem = {
    /** the value as it appear in list */
    rawItem: string;
    /** the value as it should be displayed */
    displayName?: string;
    /** a description of the value */
    description?: string;
};

export type FkCheckBoxListProps = FieldHookConfig<any> & {
    /** optional label for the field */
    label?: string;
    allItems: (string | RichListItem)[];
};

export const FkCheckBoxList: FC<FkCheckBoxListProps> = ({
    label,
    allItems,
    ...props
}) => {
    const [field, meta, helpers] = useField<string[]>({ ...props });
    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const t = e.target;
        const values = [...field.value];
        //console.log(t, values, meta.initialValue);
        const vIndex = values.findIndex((v) => v === t.name);
        if (t.checked && vIndex === -1) {
            values.push(t.name);
        } else {
            values.splice(vIndex, 1);
        }
        if (new Set(values) !== new Set(meta.initialValue)) {
            helpers.setTouched(true);
        } else {
            helpers.setTouched(false);
        }
        helpers.setValue(values);

        //helpers.setTouched(true);
    };

    return (
        <FormGroup controlId={field.name}>
            {label && <FormLabel>{label}</FormLabel>}

            {allItems.map((value, index) => (
                <div key={`${value}-${index}-checkbox`}>
                    <Field
                        as={Form.Check}
                        type="checkbox"
                        label={
                            typeof value === "string"
                                ? value
                                : value.displayName || value.rawItem
                        }
                        checked={field.value.includes(
                            typeof value === "string" ? value : value.rawItem,
                        )}
                        onChange={onChange}
                        id={`${field.name}-${index}`}
                        name={typeof value === "string" ? value : value.rawItem}
                    />
                    {/*
                    <Form.Check
                        type="checkbox"
                        name={typeof value === "string" ? value : value.rawItem}
                        label={
                            typeof value === "string"
                                ? value
                                : value.displayName || value.rawItem
                        }
                        onBlur={field.onBlur}
                        onChange={onChange}
                        checked={field.value.includes(
                            typeof value === "string" ? value : value.rawItem,
                        )}
                        isInvalid={
                            (meta.touched && meta.error !== undefined) ||
                            (meta.initialError !== undefined &&
                                meta.initialValue === field.value)
                        }
                        id={`${field.name}-${index}`}
                    />{" "}*/}
                    {typeof value !== "string" && value.description && (
                        <Form.Label>{value.description}</Form.Label>
                    )}
                </div>
            ))}
        </FormGroup>
    );
};
