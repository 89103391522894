
import React, { useState, useContext } from "react";
import { useFormikContext, useField, FormikValues} from "formik";

import MarkdownView from "react-showdown";
import { LangContext } from "src/lang/lang";

export default function FkMarkdownView(props: any) {
  const { Sentences } = useContext(LangContext);

    const [{name}, meta] = useField(props);
    const {
      values,      
    } = useFormikContext<FormikValues>();

    const [content, setContent] = useState(values[name]);
    const [markdownContent, setMarkdownContent] = useState(content);

    React.useEffect(() => {
      setContent(values[name]);
    }, [values, name])

    React.useEffect(() => {
        setMarkdownContent(content === '' ? Sentences.noContentToPreview.en : content);  
    }, [content]);
  
    return (
      <>
        {!!meta.touched && !!meta.error && <div>{meta.error}</div>}

        <MarkdownView  markdown={markdownContent} options={{ tables: true, emoji: true }}></MarkdownView>
      </>
    );
  };


  
