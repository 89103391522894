import { Form as FormikForm, Formik } from "formik";
import { FC, useState } from "react";
import {
    Team,
    UpdateTeamDto,
    useTeamControllerUpdateMutation,
} from "src/api/team.api";
import { TeamMembershipTable } from "./memberships/edit-team-membership-table";
import { M, Subjects, U } from "src/api/Permissions";
import { AbilityContext, Can } from "src/casl/Can";
import { useAbility } from "@casl/react";
import { useMyTeamsControllerFindOneWithUserMembershipQuery } from "src/api/generated.api";
import { Sentences } from "src/lang/lang";
import { Button, Col, Row } from "react-bootstrap";
import { faEdit, faUserAlt, faUserCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TeamOverview } from "./team-overview";
import { TeamEditorPopin } from "./team-editor-popin";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../auth/authSlice";

export type TeamEditorProps = {
    teamId: Team["id"];
};

export const TeamEditor: FC<TeamEditorProps> = ({ teamId }) => {
    const {
        data: team,
        isFetching,
        isLoading,
        refetch
    } = useMyTeamsControllerFindOneWithUserMembershipQuery({
        teamId,
    });

    const [showAddByUserName, setShowAddByUserName] = useState<boolean>(false);
    const [showEditTeamProps, setShowEditTeamProps] = useState(false);
    const handleCloseEditTeamProps = () => { setShowEditTeamProps(false); refetch(); }
    const handleShowEditTeamProps = () => setShowEditTeamProps(true);

    const [updateTeam] = useTeamControllerUpdateMutation();
    const onSubmit = async (values: UpdateTeamDto, formikHelpers: any) => {
        const { name } = values;
        await updateTeam({ updateTeamDto: { name }, teamId }).unwrap();
    };
    const ability = useAbility(AbilityContext);
    const user = useSelector(selectLoggedInUser);


    return (
        <>
            {team && (
                <>
                    <div className="section mb-3">
                        <Row>
                            <Col xs={'auto'}>
                                <h1>{team.name}</h1>
                            </Col>
                            <Col className="text-right">
                                <Can I={M} this={team} field="members">

                                    <Button
                                        className="w-20"
                                        onClick={handleShowEditTeamProps}>
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faEdit}
                                        />{" "}Edit team</Button>

                                </Can>
                            </Col>
                        </Row>

                        <Can I={U} this={team} field="members">
                            <div className="subtitle">
                                {Sentences.teamEdit2Subtitle.en}
                            </div>
                            <TeamOverview team={team} />
                        </Can>
                    </div>
                    <>
                        <TeamMembershipTable team={team} showAddByUserName={user?.role == 'ADMIN'} />
                    </>
                    <TeamEditorPopin team={team} show={showEditTeamProps} handleClose={handleCloseEditTeamProps} />
                </>
            )}
        </>
    );
};
