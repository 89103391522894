import { fetchBaseQuery, FetchBaseQueryError, BaseQueryFn, FetchArgs} from "@reduxjs/toolkit/dist/query";

const baseQuery = fetchBaseQuery({ baseUrl: '/'});

export const  CustomBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // try to get a new token
    await baseQuery('/api/auth/refreshToken', api, extraOptions);
    result = await baseQuery(args, api, extraOptions)
  }
  return result
}