import { FC, useContext } from "react";

import { Action } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import ErrorBar from "src/components/ErrorBar";
import { EditReleases } from "./edit-releases";
import { Subjects } from "src/api/Permissions";

export const EditReleasesPage: FC<any> = () => {
    const ability = useContext(AbilityContext);

    return (
        <>
            <Container>
                {ability.can(Action.Read, Subjects.ArtifactRelease) ? (
                    <EditReleases />
                ) : (
                    <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator." />
                )}
            </Container>
        </>
    );
};
