import { Formik, FormikHelpers } from "formik";
import { FC, useState } from "react";
import { Button, Col, Container, Form, Badge, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    ManagedLayerWithFilesDto,
    TerrainLayer,
    UpdateManagedLayerDto,
    useManagedLayerControllerBuildMutation,
} from "src/api/generated.api";
import {
    useManagedLayerControllerDeleteMutation,
    useManagedLayerControllerGetOneQuery,
    useManagedLayerControllerUpdateMutation,
    useManagedLayerControllerClearSourceFilesUsedForMutation,
} from "src/api/ManagedLayerApi";
import { BootstrapTextInput } from "src/components/BootstrapFormComponents";
import { BsFkCheckbox } from "src/components/BsFkCheckbox";
import { FkEditNumber } from "src/components/FkEditNumber";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { SimpleJobStatus } from "../job/JobStatus";
import { LayerType, LayerSourceFileTable } from "./FileList";
import { LayerJobDetails } from "./LayerJobDetails";
import { LayerStatusColors } from "./managed-layer-status-colors";
import { LayerMap } from "../terrains/TerrainMap";

export type EditLocalTmsLayerProps = {
    layer: TerrainLayer;
    editable: boolean;
};

export const ManagedLayerBuildSettingsEditor: FC<EditLocalTmsLayerProps> = ({
    layer,
    editable,
}) => {
    const { data, refetch } = useManagedLayerControllerGetOneQuery({
        layerId: layer.id,
    });

    const [updateLayer] = useManagedLayerControllerUpdateMutation();
    const [deleteLayer] = useManagedLayerControllerDeleteMutation();
    const [build] = useManagedLayerControllerBuildMutation();

    const [viewJobDetails, setViewJobDetails] = useState(false);

    const toggleJobDetails = () => setViewJobDetails((jd) => !jd);
    // a layer name -> renaming a layer leads to big work on the disk because it is the folder
    // a local tms object should provide
    // a ref to a dem file on disk, with it's sha, or undefined.
    // a ref to a tex file on disk, with it's sha, or undefined.
    // a tex size
    // a dem size
    // a layer status (either up-to-date or dirty);

    const onFormikSubmit = async (
        values: ManagedLayerWithFilesDto,
        formikbag: FormikHelpers<ManagedLayerWithFilesDto>,
    ) => {
        try {
            const dto: UpdateManagedLayerDto = {
                demTileSize: values.demTileSize,
                texTileSize: values.texTileSize,
                autoBuildAfterUpload: values.autoBuildAfterUpload,
            };
            await updateLayer({
                layerId: layer.id,
                updateManagedLayerDto: dto,
            });
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikbag.setErrors(errors);
        }
    };

    const onDeleteClick = async () => {
        await deleteLayer({ layerId: layer.id });
    };

    const onRunJobClick = async () => {
        try {
            await build({ layerId: layer.id }).unwrap();
            refetch();
        } catch (e) {
            console.log("failed to start build...");
        }
        console.log("run Job");
    };

    return (
        <>
            {data && (
                <>
                    {data.status === "up_to_date" && <LayerMap terrainLayer={layer} mapHeight="600px" />}
                    <Row>
                        <Col>
                            <h3>
                                Layer Status :{" "}
                                <Badge
                                    variant={
                                        LayerStatusColors[
                                        data.status ? data.status : "dirty"
                                        ]
                                    }
                                >
                                    {data.status === "dirty"
                                        ? "Dirty"
                                        : "Up to date"}
                                </Badge>
                            </h3>
                        </Col>
                        <Col>
                            <h3>
                                Build Status:{" "}
                                {data.jobId ? (
                                    <SimpleJobStatus jobId={data.jobId} />
                                ) : (
                                    "no job"
                                )}
                            </h3>
                        </Col>
                        {editable && (
                            <Col md="auto">
                                <Button onClick={onRunJobClick}>
                                    {data.jobId ? "Rebuild" : "Build"}
                                </Button>
                                <Button
                                    onClick={onDeleteClick}
                                    variant="danger"
                                >
                                    Delete layer
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <h3>Build Parameters</h3>
                    <Container>
                        <Formik
                            initialValues={data}
                            onSubmit={onFormikSubmit}
                            enableReinitialize
                        >
                            {(formik) => (
                                <Form
                                    onSubmit={formik.handleSubmit}
                                    onChange={formik.handleChange}
                                >
                                    <Row>
                                        <Col>
                                            <BootstrapTextInput
                                                name="name"
                                                label="managed layer id"
                                                placeholder="managed layer id"
                                                disabled
                                            />
                                        </Col>
                                        {editable && (
                                            <Col md="auto">
                                                <Button
                                                    type="submit"
                                                    disabled={!formik.dirty}
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FkEditNumber
                                                name="demTileSize"
                                                label="Dem tile size"
                                                disabled={!editable}
                                            />
                                        </Col>
                                        <Col>
                                            <FkEditNumber
                                                name="texTileSize"
                                                label="Tex tile size"
                                                disabled={!editable}
                                            />
                                        </Col>
                                    </Row>
                                    <BsFkCheckbox
                                        name="autoBuildAfterUpload"
                                        label={
                                            "Start build when upload is complete"
                                        }
                                        disabled={!editable}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Container>
                    <h4>Layer sources</h4>
                    <Container>
                        <SourceFileSectionHeader
                            managedLayerId={layer.id}
                            label="DEM"
                            usedFor="dem"
                            editable={editable}
                        />
                        <LayerSourceFileTable
                            files={data.demSourceFiles}
                            layerType={LayerType.DEM}
                            layerId={layer.id}
                        />
                    </Container>
                    <Container>
                        <SourceFileSectionHeader
                            managedLayerId={layer.id}
                            label="TEX"
                            usedFor="tex"
                            editable={editable}
                        />
                        <LayerSourceFileTable
                            files={data.texSourceFiles}
                            layerType={LayerType.TEX}
                            layerId={layer.id}
                        />
                    </Container>
                    <h4>Build log</h4>
                    <Container>
                        {data.jobId ? (
                            <Row>
                                <Button onClick={toggleJobDetails}>
                                    {viewJobDetails ? "Hide" : "View"} job
                                    details
                                </Button>
                                {viewJobDetails && (
                                    <LayerJobDetails jobId={data.jobId} />
                                )}
                            </Row>
                        ) : (
                            "No logs available"
                        )}
                    </Container>
                </>
            )}
        </>
    );
};

const SourceFileSectionHeader: FC<{
    managedLayerId: number;
    label: string;
    usedFor: string;
    editable: boolean;
}> = ({ managedLayerId, label, usedFor, editable }) => {
    const [clearSourceFiles] =
        useManagedLayerControllerClearSourceFilesUsedForMutation();
    return (
        <Row className="mt-3 mb-3">
            <Col>
                <h4>{label}</h4>
            </Col>
            {editable && (
                <Col md="auto">
                    <Button
                        variant="danger"
                        onClick={() =>
                            clearSourceFiles({
                                layerId: managedLayerId,
                                usedFor,
                            })
                        }
                    >
                        Clear files
                    </Button>
                </Col>
            )}
        </Row>
    );
};
