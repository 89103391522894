import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import EditSession from "./EditSession";

export default function EditSessionPage() {
    const { sessionId } = useParams<{ sessionId: string }>();

    const [id, setId] = useState<number>();

    useEffect(
        () => (sessionId ? setId(parseInt(sessionId)) : setId(undefined)),
        [sessionId],
    );

    return (
        <>
            <Container>
                {id ? (
                    <EditSession courseSessionId={id} />
                ) : (
                    <div>Sorry, unknown Session</div>
                )}
            </Container>
        </>
    );
}
