import { off } from "process";
import { useEffect, useState } from "react";
import TableWithPagination, {
    TableWithPaginationProps,
} from "src/components/tables/TableWithPagination";

type BigListPaginatedProps<D extends object> = Pick<
    TableWithPaginationProps<D>,
    "columns" | "data"
> & {
    loading?: boolean;
};
export function BigListPaginated<T extends object>({
    columns,
    data,
    loading,
}: BigListPaginatedProps<T>) {
    const [query, setQuery] = useState({ pageSize: 10, pageIndex: 0 });
    const [displayedData, setDisplayedData] = useState(
        [...data].splice(query.pageIndex * query.pageSize, query.pageSize),
    );

    useEffect(() => {
        const offset = query.pageIndex * query.pageSize;

        setDisplayedData([...data].splice(offset, query.pageSize));
    }, [data, query]);

    return (
        <TableWithPagination<T>
            columns={columns}
            data={displayedData}
            fetchData={(pageIndex, pageSize) => {
                setQuery((old) =>
                    old.pageIndex !== pageIndex || old.pageSize !== pageSize
                        ? { pageIndex, pageSize }
                        : old,
                );
            }}
            loading={loading || false}
            itemCount={data.length}
        />
    );
}
