import React, { FC } from "react";
import Button from "react-bootstrap/Button";

import { Action } from "src/api/Permissions";

import { Can } from "../../casl/Can";
import TableSimple from "src/components/tables/TableSimple";
import { Membership, Team, User } from "src/api/generated.api";
import { useFindAllUsersQuery } from "src/api/UserApi";
import { LangContext } from "src/lang/lang";
import { useMembershipControllerGetMembersQuery } from "src/api/team.api";
import { Column } from "react-table";

interface TeamMemberUserTableSelectorProps {
    onSelected?: (user: User) => void;
    teamId: Team["id"];
}

export const TeamMemberUserTableSelector: FC<
    TeamMemberUserTableSelectorProps
> = ({ onSelected, teamId }) => {
    const { ObjectNames, Sentences } = React.useContext(LangContext);

    const { data: users, isLoading } = useMembershipControllerGetMembersQuery({
        teamId,
    });

    const columns: Column<Membership>[] = [
        {
            Header: ObjectNames.user.en,
            width: "100%",
            Cell: ({ row }: { row: { original: Membership } }) => (
                <div>{row.original.user.username}</div>
            ),
        },
        {
            Header: "Action",
            Cell: ({ row }: { row: { original: Membership } }) => (
                <Button
                    onClick={() => {
                        if (onSelected) onSelected(row.original.user);
                    }}
                >
                    {Sentences.select.en}
                </Button>
            ),
        },
    ];

    return (
        <div>
            {/*<Can I={Action.List} a={"User"}>*/}
            <TableSimple
                loading={isLoading}
                columns={columns}
                data={users ?? []}
                fetchData={() => ({})}
            />

            {/*</Can>*/}
        </div>
    );
};
