import { Button, Col, Container, Row } from "react-bootstrap";
import { TeamRoleTable } from "./team-roles-table";
import { LangContext } from "src/lang/lang";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const TeamRolesPage = () => {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const navigate = useNavigate();
    return (
        <Container>
            <Row className="section mb-3">
                <Col>
                    <h1>{ObjectNames.teamRoles.en}</h1>
                    <div className="subtitle">
                        {Sentences.teamRolesSubtitles.en}
                    </div>
                </Col>
                <Col md="auto">
                    <Button
                        onClick={() =>
                            navigate("/admin/teams/roles/create")
                        }
                    >
                        Create new
                    </Button>
                </Col>
            </Row>
            <TeamRoleTable />
        </Container>
    );
};
