import { Socket, io } from "socket.io-client";
import {
    AccessControlEntry,
    AccessControlable,
    FindOneDocumentMembershipDto,
    UpsertDocumentMembershipDto,
    api as generatedApi,
} from "./generated.api";
import { TEAM_MEMBER } from "./team.api";

/** (unused) tag for a document inheriting from AccessControlable */
export const DOCUMENT_TAG = "Document" as const;
/** Tag for all acls of a document */
export const DOCUMENT_ACL_TAG = "DocumentAcl" as const;
/** Tag for each acl entry of a document */
export const DOCUMENT_ACL_ENTRY_TAG = "DocumentAclEntry" as const;

export const DocumentAclTagList = [
    DOCUMENT_TAG,
    DOCUMENT_ACL_TAG,
    DOCUMENT_ACL_ENTRY_TAG,
    TEAM_MEMBER,
] as const;

/** Document ACL Role tag. Not used a lot */
export const DOCUMENT_ACL_ROLE_TAG = "DocumentRole" as const;

export type UpsertDocumentMembershipDtoInterface = {
    upsertDocumentMembershipDto: FindOneDocumentMembershipDto;
};
/**
 * Create document acl tags from any document.
 * Used by other api for objects implementing AccessControlable
 */
export const provideDocumentAclTags = (
    document: { id: string | number } & AccessControlable,
    documentType: string,
) => {
    if (!document.perms) {
        console.warn(`${documentType} ${document.id} has no perms`);
    }
    return [
        ...(document.perms || [])
            .map(({ membershipId, membership }) => [
                {
                    type: DOCUMENT_ACL_ENTRY_TAG,
                    objType: documentType,
                    objId: `${document.id}`,
                    membershipId,
                },
                {
                    type: TEAM_MEMBER,
                    membershipId,
                    userId: membership?.userId,
                },
            ])
            .flat(),
        {
            type: DOCUMENT_ACL_TAG,
            objType: documentType,
            objId: `${document.id}`,
        },
    ];
};

export const invalidateDocumentTagsFromId = (
    objType: string,
    objId: string | number,
) => [
    {
        type: DOCUMENT_ACL_ENTRY_TAG,
        objType,
        objId: `${objId}`,
    },
    {
        type: DOCUMENT_ACL_TAG,
        objType: objType,
        objId: `${objId}`,
    },
];

/** Generate the tags to invalidate when revoking a user from a document */
export const invalidateDocumentAclEntryTag = (
    result: any,
    error: any,
    { upsertDocumentMembershipDto: dto }: UpsertDocumentMembershipDtoInterface,
) => [
    {
        type: DOCUMENT_ACL_ENTRY_TAG,
        objType: dto.documentType,
        objId: dto.documentId,
    },
    {
        type: DOCUMENT_ACL_TAG,
        objType: dto.documentType,
        objId: dto.documentId,
    },
];

export const DocumentAccessControlApi = generatedApi.enhanceEndpoints({
    addTagTypes: [DOCUMENT_ACL_ROLE_TAG, ...DocumentAclTagList],
    endpoints: {
        documentRoleControllerGetRoles: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: DOCUMENT_ACL_ROLE_TAG,
                              id,
                          })),
                          DOCUMENT_ACL_ROLE_TAG,
                      ]
                    : [],
        },
        documentMembershipControllerGetDocumentMemberships: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result
                              .map(
                                  ({
                                      objId,
                                      membershipId,
                                      objType,
                                      membership,
                                  }) => [
                                      {
                                          type: DOCUMENT_ACL_ENTRY_TAG,
                                          objType,
                                          objId,
                                          membershipId,
                                      } as const,
                                      {
                                          type: TEAM_MEMBER,
                                          membershipId,
                                          userId: membership?.userId,
                                      } as const,
                                  ],
                              )
                              .flat(),
                          {
                              type: DOCUMENT_ACL_TAG,
                              objType: arg.documentType,
                              objId: arg.documentId,
                          } as const,
                      ]
                    : [],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/team");
                console.log(socket.id);
                console.log(socket);
                await api.cacheDataLoaded;
                const cacheData = api.getCacheEntry();
                const uids = cacheData.data?.map(
                    ({ membership }) => membership?.userId,
                );
                if (uids && uids.length) {
                    socket.on("MembershipsChanged", (data) => {
                        if (data.userId) {
                            api.dispatch(
                                DocumentAccessControlApi.util.invalidateTags([
                                    {
                                        type: TEAM_MEMBER,
                                    } as const,
                                ]),
                            );
                        }
                    });
                    console.log(
                        `subscribing to membership changes for users : ${uids.join(
                            ";",
                        )}`,
                    );
                    socket.emit("SubscribeToUserMemberships", uids);
                }
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        documentMembershipControllerRevokeDocumentMembership: {
            invalidatesTags: (
                result,
                error,
                { findOneDocumentMembershipDto: dto },
            ) => [
                {
                    type: DOCUMENT_ACL_ENTRY_TAG,
                    objType: dto.documentType,
                    objId: dto.documentId,
                    membershipId: dto.teamMembershipId,
                },
                {
                    type: DOCUMENT_ACL_TAG,
                    objType: dto.documentType,
                    objId: dto.documentId,
                },
            ],
        },
    },
});

export const {
    useDocumentRoleControllerGetRolesQuery: useGetDocumentRoles,
    useDocumentMembershipControllerGetDocumentMembershipsQuery:
        getDocumentAclEntries,
    useDocumentMembershipControllerRevokeDocumentMembershipMutation:
        revokeDocumentAclEntry,
} = DocumentAccessControlApi;

export type { AccessControlEntry, UpsertDocumentMembershipDto };
