import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { BootstrapTextInput } from "../../components/BootstrapFormComponents";
import { useCreateTerrainMutation } from "../../api/TerrainApi";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import ErrorBar from "src/components/ErrorBar";
import { useState, useContext } from "react";
import { LangContext } from "src/lang/lang";
import { FkMyTeamsDropdown } from "../teams/FkTeamDropdown";

interface CreateTerrainPropsParams {
    onSaved?: (terrainId: number) => void;
}

export default function CreateTerrain({ onSaved }: CreateTerrainPropsParams) {
    const { Sentences } = useContext(LangContext);

    const [addTerrain] = useCreateTerrainMutation();
    const [errorMessage, setErrorMessage] = useState("");
    return (
        <>
            <h1>{Sentences.terrainCreate.en}</h1>
            <ErrorBar errorMessage={errorMessage} />
            <Formik
                initialValues={{ name: "", teamId: "" }}
                validationSchema={Yup.object({
                    name: Yup.string().required(),
                    teamId: Yup.string().uuid().required(),
                })}
                onSubmit={async (values, formikBag) => {
                    formikBag.setSubmitting(true);

                    try {
                        setErrorMessage("");
                        const terrain = await addTerrain({
                            createTerrainDto: {
                                name: values.name,
                                teamId: values.teamId,
                            },
                        }).unwrap();
                        if (onSaved) onSaved(terrain.id);
                    } catch (e) {
                        if (typeof e.data.message == "string") {
                            setErrorMessage(e.data.message);
                        } else {
                            const errors = convertApiErrorsToFormikErrors(e);
                            formikBag.setErrors(errors);
                        }
                    }
                    formikBag.setSubmitting(false);
                }}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} method="post">
                        <FkMyTeamsDropdown name="teamId" />/
                        <BootstrapTextInput
                            label={Sentences.terrainName.en}
                            name="name"
                            placeholder={Sentences.terrainName.en}
                        />
                        <Button variant="primary" type="submit">
                            {Sentences.create.en}
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
}
