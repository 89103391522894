import { Form as FormikForm, Formik } from "formik";
import { FC } from "react";
import { FkMyTeamsDropdown } from "../teams/FkTeamDropdown";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { ChangeTeamOfObjectDto } from "src/api/generated.api";
import { ConfimButton } from "src/components/ConfirmButton";

export type DocumentTeamEditProps = {
    objWithTeam: { id: string | number; teamId: string };
    onTeamChanged: (arg: {
        id: string;
        changeTeamOfObjectDto: ChangeTeamOfObjectDto;
    }) => void;
};

export const DocumentTeamEdit: FC<DocumentTeamEditProps> = ({
    objWithTeam,
    onTeamChanged,
}) => {
    const onSubmit = (changeTeamOfObjectDto: ChangeTeamOfObjectDto) => {
        onTeamChanged({ id: `${objWithTeam.id}`, changeTeamOfObjectDto });
    };

    const message = `Are you REALLY SURE to change the team of this object ? \
         This will remove all users connected to it and may break other things.`;
    return (
        <Formik<ChangeTeamOfObjectDto>
            initialValues={{
                teamId: objWithTeam.teamId,
                ifAclEntries: "clear_acl_entries",
            }}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {(formik) => (
                <FormikForm>
                    <FormGroup className="my-0" as={Row}>
                        <Form.Label column sm="3">
                            Belong to Team :
                        </Form.Label>
                        <Col>
                            <FkMyTeamsDropdown name="teamId" />
                        </Col>
                        {formik.dirty && (
                            <>
                                <Col md="auto">
                                    <ConfimButton
                                        variant="danger"
                                        onClick={formik.submitForm}
                                        message={message}
                                    >
                                        Change Team
                                    </ConfimButton>
                                </Col>
                                <Col md="auto">
                                    <Button onClick={() => formik.resetForm()}>
                                        Cancel
                                    </Button>
                                </Col>
                            </>
                        )}
                    </FormGroup>
                </FormikForm>
            )}
        </Formik>
    );
};
