import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { Ability, InferSubjects } from "@casl/ability";
import { Action } from "@shared/auth/src/permissions";
import { CourseSession, Terrain, User } from "src/api/generated.api";

// Code taken from https://casl.js.org/v4/en/package/casl-react
type stuff =
    | User
    | Terrain
    | "User"
    | "Terrain"
    | CourseSession
    | "CourseSession"
    | "all";

export type AbilitySubjects = InferSubjects<stuff>;
export type AppAbility = Ability<[Action, AbilitySubjects]>;

export const GlobalAbility = new Ability();
export const AbilityContext = createContext(GlobalAbility);
export const Can = createContextualCan(AbilityContext.Consumer);
