import { FC } from "react";
import { useGetDocumentRoles } from "src/api/document-access-control.api";
import {
    BootstrapDropdownKeysProps,
    FkStackedDropdownKeys,
} from "src/components/BootstrapFormComponents";

export const FkDocumentRoleSelector: FC<
    Omit<BootstrapDropdownKeysProps, "items">
> = ({ label, name, onChange, disabled }) => {
    const { data } = useGetDocumentRoles();
    return (
        <FkStackedDropdownKeys
            items={[{ id: "", name: "select role" }, ...(data || [])]}
            label={label}
            name={name}
            onChange={onChange}
            disabled={disabled}
        />
    );
};
