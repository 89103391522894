import { io, Socket } from "socket.io-client";
import { api as generatedApi } from "./generated.api";

export const ManagedLayerApi = generatedApi.enhanceEndpoints({
    addTagTypes: ["ManagedLayer", "Layer"],
    endpoints: {
        managedLayerControllerGetAll: {
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ layerId: id }) => ({
                              type: "ManagedLayer" as const,
                              id,
                          })),
                          "Layer",
                      ]
                    : [],
        },
        managedLayerControllerGetOne: {
            providesTags: (result) =>
                result
                    ? [{ type: "ManagedLayer" as const, id: result.layerId }]
                    : [],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/ManagedLayer");
                await api.cacheDataLoaded;
                socket.on("ManagedLayerUpdate", (_) => {
                    api.dispatch(
                        ManagedLayerApi.util.invalidateTags([
                            {
                                type: "ManagedLayer" as const,
                                id: arg.layerId,
                            },
                            { type: "Layer" as const, id: arg.layerId },
                        ]),
                    );
                });
                socket.emit("MonitorManagedLayer", [arg.layerId]);
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        managedLayerSourcesControllerToucheSourceFiles: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) =>
                args.body.flatMap(({ layerId }) => [
                    { type: "ManagedLayer" as const, id: layerId },
                    { type: "Layer" as const, id: layerId },
                ]);
        },
        managedLayerControllerUpdate: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => [
                { type: "ManagedLayer" as const, id: args.layerId },
                { type: "Layer" as const, id: args.layerId },
            ];
        },
        managedLayerControllerDelete: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => [
                { type: "ManagedLayer" as const, id: args.layerId },
                { type: "Layer" as const, id: args.layerId },
            ];
        },
        managedLayerControllerBuild: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => [
                { type: "ManagedLayer" as const, id: args.layerId },
            ];
        },
        managedLayerControllerDeleteSourceFile: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => [
                { type: "ManagedLayer" as const, id: args.layerId },
            ];
        },
        managedLayerControllerClearSourceFilesUsedFor: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => [
                { type: "ManagedLayer" as const, id: args.layerId },
            ];
        },
    },
});

export const {
    useManagedLayerControllerBuildMutation,

    useManagedLayerControllerDeleteMutation,
    useManagedLayerControllerGetAllQuery,
    useManagedLayerControllerGetOneQuery,
    useManagedLayerControllerUpdateMutation,
    useManagedLayerControllerDeleteSourceFileMutation,
    useManagedLayerControllerClearSourceFilesUsedForMutation,
    useManagedLayerControllerUploadChunkMutation,
    useManagedLayerSourcesControllerToucheSourceFilesMutation,
} = ManagedLayerApi;
