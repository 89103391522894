import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import { Button, ButtonProps } from "react-bootstrap"

export type EditButtonProps = ButtonProps & {

}

export const EditButton: FC<EditButtonProps> = (props)=>{
    return <Button {...props}><FontAwesomeIcon icon={faEdit}/>{props.children}</Button>
}