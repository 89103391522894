import { FC } from "react";
import { useMembershipControllerGetMembersQuery } from "src/api/team.api";
import {
    BootstrapDropdownKeysProps,
    FkStackedDropdownKeys,
} from "src/components/BootstrapFormComponents";

export type FKTeamMemberSelectorProps = Omit<
    BootstrapDropdownKeysProps,
    "items"
> & {
    teamId: string;
};

export const FKTeamMemberSelector: FC<FKTeamMemberSelectorProps> = ({
    teamId,
    name,
    label,
    disabled,
}) => {
    const { data } = useMembershipControllerGetMembersQuery({ teamId });

    return (
        <FkStackedDropdownKeys
            items={[
                { id: "", name: "select user" },
                ...(data || []).map((membership) => ({
                    id: membership.id,
                    name: membership.user.username,
                })),
            ]}
            name={name}
            label={label}
            disabled={disabled}
        />
    );
};
