import { FieldHookConfig, useField } from "formik";
import { FC } from "react";
import { Form } from "react-bootstrap";

declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export type FkStackedTextInputProps = FieldHookConfig<any> & {
    label : string,
    placeholder : string,
    onChange? : (evt : React.ChangeEvent<FormControlElement>) => void
}

export const FkStackedTextInput: FC<FkStackedTextInputProps> = ({ label, placeholder, onChange, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <Form.Group controlId={field.name}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as='input'
                type={props.type || "text"}
                name={field.name}
                onBlur={field.onBlur}
                disabled={props.disabled}
                onChange={ (evt) => {
                    if(onChange) {
                        onChange(evt);
                    }
                    field.onChange(evt);
                }}
                value={field.value}
                isInvalid={ (meta.touched && (meta.error !== undefined)) || ((meta.initialError !== undefined) && meta.initialValue === field.value) }
                placeholder={placeholder}
            />
            {
                (meta.touched && meta.error)
                    ? (<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>)
                    : (meta.initialError && meta.initialValue === field.value)
                    ? (<Form.Control.Feedback type="invalid">{meta.initialError}</Form.Control.Feedback>)
                    : null
            }
        </Form.Group>
      );
}