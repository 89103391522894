import { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "./authSlice";
import { useGetAuthMethods, useLocalLoginMutation } from "src/api/AuthApi";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form as FormikForm, Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { AuthMethodsDto, LoginUserDto, useRecoverAccountMutation, useValidPasswordResetLinkMutation } from "src/api/generated.api";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import axios from "axios";
import { LocalPasswordSchema } from "../users/AddAuthProvider";

interface Props {
    clientMode?: Boolean;
}

export function RecoverPassword({ clientMode }: Props) {
    let { userId, timestamp, hash } = useParams();
    const { ObjectNames, Sentences } = useContext(LangContext);
    const authStatus = useSelector(selectAuthStatus);
    const navigate = useNavigate();
    const location = useLocation();

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    const [recoverAccount] = useRecoverAccountMutation();

    const [errorMessage, setErrorMessage] = useState("");


    const onSubmit = async (values: any, formikHelpers: any) => {
        try {
            const result = await recoverAccount({ recoverAccountDto: { email: values.email } }).unwrap();
            setIsConfirmed(true);
        } catch (error) { }
    };

    if (authStatus === "LOGGED_IN" && clientMode) {
        return <Navigate replace to="/client" />;
    }
    else if (authStatus === "LOGGED_IN") {
        return <Navigate replace to="/" />;
    }
    else {
        return (

            <>

                {isConfirmed ? (<Row className="bg ">
                    <Col className="my-auto justify-content-center">

                        <Container style={{ width: 450 }}>
                            <Card>
                                <Card.Header className="text-center">
                                    <h3>Recover account</h3>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            We have sent an email to the address registered with this account with further instructions for resetting your password.
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                </Row>) : (

                    <Row className="bg ">
                        <Col className="my-auto justify-content-center">
                            <Container style={{ width: 450 }}>
                                <Card>
                                    <Card.Header className="text-center">
                                        <h3>Recover account</h3>
                                        <p>We will send a confirmation email to this address :</p>
                                    </Card.Header>
                                    <Card.Body>
                                        <div>
                                            <ErrorBar
                                                errorMessage={errorMessage}
                                            />
                                            <Formik
                                                initialValues={{
                                                    email: "",
                                                }}
                                                validationSchema={Yup.object({
                                                    email: Yup.string().email('Invalid email').required('Required'),
                                                })}
                                                onSubmit={onSubmit}
                                                enableReinitialize={true}
                                            >
                                                {(formik) => (
                                                    <FormikForm>
                                                        <FkStackedTextInput
                                                            label="Email *"
                                                            name="email"
                                                            placeholder="john.doe@gmail.com"
                                                        />

                                                        <Button
                                                            type="submit"
                                                            variant="primary"
                                                            block
                                                        >
                                                            Send Email
                                                        </Button>
                                                    </FormikForm>
                                                )}
                                            </Formik>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Col>
                    </Row>)}

            </>
        );
    }
}
