import { Container } from "react-bootstrap";
import { TeamRoleEditor } from "./edit-team-role";
import {
    TeamRole,
    useTeamRoleControllerGetTeamRolesQuery,
} from "src/api/team.api";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { teamContext } from "../context/team-context-provider";

export const TeamRoleEditorPage = () => {
    const { roleId } = useParams<{ roleId: TeamRole["id"] }>();
    const { currentTeam } = useContext(teamContext);

    const { data: roles } = useTeamRoleControllerGetTeamRolesQuery({
        teamId: currentTeam ? currentTeam.id : "",
    });

    const [role, setRole] = useState<TeamRole | undefined>();

    useEffect(() => {
        if (roles && roleId) setRole(roles.find(({ id }) => id === roleId));
    }, [roles, roleId]);

    return (
        <>
            {currentTeam &&
                (<Container>
                    {role && <TeamRoleEditor teamId={currentTeam.id} teamRole={role} />}
                </Container>)
            }
        </>

    );
};
