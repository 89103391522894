import { Socket, io } from "socket.io-client";
import { api as generatedApi } from "./generated.api";
import { LIST, MY_TEAM } from "./team.api";

export const AuthApi = generatedApi.enhanceEndpoints({
    addTagTypes: ["AuthStatus", "AuthMethods", "Token", MY_TEAM, "CourseSession", "Terrain", "TerrainModel", "Slide", "Layer"],
    endpoints: {
        authControllerAuthStatus: {
            providesTags: (result, error, arg) => [
                { type: "AuthStatus" } as const,
            ],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/abilities");
                console.log(socket.io);
                await api.cacheDataLoaded;
                const cacheData = api.getCacheEntry();
                const uid = cacheData.data?.user?.id;
                if (uid) {
                    console.log(`subscribing for user ${uid}`);
                    socket.on("abilities_updated", (data) => {
                        console.log("got ability update");
                        api.dispatch(
                            AuthApi.util.invalidateTags([
                                { type: "AuthStatus" as const },
                            ]),
                        );
                    });
                    socket.emit("SubscribeToUserAbilities", [uid]);
                }
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        authControllerGetAbilities: {
            providesTags: (result, error, arg) =>
                result ? [{ type: "AuthStatus" } as const] : [],

            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/abilities");
                console.log(socket.io);

                await api.cacheDataLoaded;
                const cacheData = api.getCacheEntry();
                const uid = cacheData.data?.user?.id;
                if (uid) {
                    console.log(`subscribing for user ${uid}`);
                    socket.on("abilities_updated", (data) => {
                        api.dispatch(
                            AuthApi.util.invalidateTags([
                                { type: "AuthStatus" as const },
                            ]),
                        );
                    });
                    socket.emit("SubscribeToUserAbilities", [uid]);
                }
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        socialCasLogin: {
            providesTags: (result, error, arg) => [
                { type: "AuthStatus" } as const,
            ],
        },
        socialGoogleLoginRedirect: {
            providesTags: (result, error, arg) => [
                { type: "AuthStatus" } as const,
            ],
        },
        authControllerLogout: (endpoint) => {
            endpoint.invalidatesTags = (result) => [
                { type: "AuthStatus" } as const,
                { type: MY_TEAM, teamId: LIST } as const,
                { type: "CourseSession" } as const,
                { type: "Terrain" } as const,
                { type: "Layer" } as const,
                { type: "Slide" } as const,
                { type: "TerrainModel" } as const,
            ];
        },
        authControllerLocalLogin: (endpoint) => {
            endpoint.invalidatesTags = (result) => [
                { type: "AuthStatus" } as const,
                { type: MY_TEAM, teamId: LIST } as const,
            ];
        },
        authControllerGetAuthMethods: {
            providesTags: (result) =>
                result ? [{ type: "AuthMethods" as const }] : [],
        },
    },
});

export const {
    useAuthControllerAuthStatusQuery: useAuthStatusQuery,
    useAuthControllerGetAbilitiesQuery: useGetAbilitiesQuery,
    useAuthControllerRefreshTokenQuery: useRefreshTokenQuery,
    useAuthControllerLocalLoginMutation: useLocalLoginMutation,
    useAuthControllerLogoutMutation: useLogoutMutation,
    useAuthControllerGetAuthMethodsQuery: useGetAuthMethods,
    useSocialCasLoginQuery,
    useSocialGoogleLoginRedirectQuery,
} = AuthApi;
