import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEventHandler, FC, useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

export type FileUploadButtonProps = {
    tooltipText: string;
    onFileSelected?: (file: File) => void;
    accept?: string;
};

export const FileUploadButton: FC<FileUploadButtonProps> = ({
    tooltipText,
    onFileSelected,
    accept,
}) => {
    const inputFile = useRef<HTMLInputElement>(null);
    const form = useRef<HTMLFormElement>(null);
    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const target = e.target as HTMLInputElement;
        let newFiles: File[] = [];
        if (target.files) {
            newFiles = Object.values(target.files).map((file: File) => file);
            console.log(newFiles);
            if (newFiles.length > 1)
                return alert("Only a single image allowed");
        }
        form?.current?.reset();
        if (onFileSelected && newFiles.length > 0) onFileSelected(newFiles[0]);
    };

    return (
        <>
            <form ref={form} onReset={() => console.log("reset")}>
                <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={handleOnChange}
                    accept={accept || "*"}
                />
            </form>

            <Button onClick={() => inputFile?.current?.click()}>
                <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                        <Tooltip id={props.id} {...props}>
                            {tooltipText}
                        </Tooltip>
                    )}
                    placement="bottom"
                >
                    <FontAwesomeIcon icon={faUpload} />
                </OverlayTrigger>
            </Button>
        </>
    );
};
