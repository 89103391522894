import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import { Container } from "react-bootstrap";
import { EditJob } from "./CreateEditJob";

export default function EditJobPage() {

    const { jobId } = useParams<{jobId:string}>();
    const [id, setId] = useState<string>();

    useEffect(()=> jobId ? setId(jobId) : setId(undefined), [jobId]);

    return (<>
        <Container>
            {id 
               ? <EditJob jobId={id}/>
               : <div>Sorry, unknown job</div>
            }            
        </Container>
        </>
    )
}