import { FC, useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
    CreateLayerDto,
    TerrainLayer,
    useLayerControllerCreateMutation,
} from "src/api/generated.api";
import { TerrainLayersTableSelector } from "./terrain-layers-table-selector";
import { v4 } from "uuid";
import { teamContext } from "../teams/context/team-context-provider";
import { Action, Subjects } from "src/api/Permissions";
import { subject } from "@casl/ability";
import { Can } from "src/casl/Can";
import { LayerManagementModeTypes } from "./terrain-layer-types";

export type LayerSelectorProps = {
    onSelectedLayer: (layerId: TerrainLayer["id"]) => void;
};

export const TerrainLayersSelector: FC<LayerSelectorProps> = ({
    onSelectedLayer,
}) => {
    const [createExternalLayer] = useLayerControllerCreateMutation();
    const { currentTeam } = useContext(teamContext);

    const onCreateLayer = async (managementMode: LayerManagementModeTypes) => {
        if (currentTeam) {
            const createLayerDto: CreateLayerDto = {
                name: "NewLayer" + v4(),
                managementMode,
                teamId: currentTeam.id,
            };
            try {
                const layer = await createExternalLayer({
                    createLayerDto,
                }).unwrap();
                onSelectedLayer(layer.id);
            } catch (error) {}
        }
    };

    return (
        <>
            <Row>
                <Col>Select a layer from the list or </Col>
                {currentTeam && (
                    <Can
                        I={Action.Create}
                        this={subject(Subjects.TerrainLayer, {
                            teamId: currentTeam.id,
                        })}
                    >
                        <Col md="auto">
                            <Button onClick={() => onCreateLayer("INTERNAL")}>
                                Import files as layer
                            </Button>
                        </Col>
                        <Col md="auto">{" or "}</Col>
                        <Col md="auto">
                            <Button onClick={() => onCreateLayer("EXTERNAL")}>
                                Create from external source
                            </Button>
                        </Col>
                    </Can>
                )}
            </Row>
            <TerrainLayersTableSelector
                onRowActionClicked={(layer) => onSelectedLayer(layer.id)}
                action="Select"
            />
        </>
    );
};
