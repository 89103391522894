import { Action } from "@shared/auth/src";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AbilityContext } from "src/casl/Can";
import ErrorBar from "src/components/ErrorBar";
import { CourseSessionProgressExplorer } from "./CourseSessionProgressExplorer";

export default function CourseSessionExplorerPage() {

    const { sessionId } = useParams<{sessionId:string}>();

    const [id, setId] = useState<number>();

    useEffect(()=> sessionId ? setId(parseInt(sessionId)) : setId(undefined), [sessionId]);
    const ability = useContext(AbilityContext);

    return (<>
        <Container fluid >
            {ability.can(Action.Read, "CourseSession")
                ?   id 
                        ? <CourseSessionProgressExplorer courseSessionId={id}/>
                        : <div>Sorry, unknown Session</div>
                    
                : <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator."/>
            }            
        </Container>
        </>
    )
}