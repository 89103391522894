import React, { useEffect } from 'react';
import { useLogoutMutation } from 'src/api/AuthApi';
import { useSelector } from "react-redux";
import { selectAuthStatus } from './authSlice';
import { useNavigate } from 'react-router-dom';
import RoutesDef from 'src/routes/RoutesDef';

interface Props {
    clientMode?: Boolean;
}

export function LogOut({ clientMode }: Props) {
    const authStatus = useSelector(selectAuthStatus);
    const navigate = useNavigate();
    const [logout, { isLoading, isSuccess }] = useLogoutMutation();
    useEffect(() => {
        if (!isLoading && authStatus === "LOGGED_IN") {
            if (clientMode) {
                logout().then(() => navigate(RoutesDef.UNITY_HOME));
            }
            else {
                logout().then(() => navigate(RoutesDef.LOGIN));
            }
        }
    })

    return (
        <div>
            {isLoading ? 'logging out' : ''}
            {isSuccess ? 'logged out' : ''}
        </div>
    )
}