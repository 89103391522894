import { FC, MouseEventHandler, useState } from "react";
import {
    TeamSummaryForUserDto,
    useMyTeamsControllerFindMineQuery,
    useTeamControllerFindAllQuery,

} from "src/api/team.api";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { faEnvelope, faMailBulk, faPlusCircle, faUserCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../auth/authSlice";
import { BootstrapDropdown, FkStackedDropdownObj } from "src/components/BootstrapFormComponents";
import { Form as FormikForm, Formik, FieldArray, Field } from "formik";

import { TeamInviteMembersDto, TeamInviteMembersLineDto, TeamRole, TeamRoleControllerGetTeamRolesApiResponse, useMembershipControllerInviteMembersMutation, useTeamRoleControllerGetTeamRolesQuery } from "src/api/generated.api";

import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { FkSimpleTextInput } from "src/components/FkSimpleTextInput";
import Select, { Options, StylesConfig, components } from "react-select";


export type TeamInviteEditorProps = {
    team: TeamSummaryForUserDto;
    show: boolean;
    handleClose: any;
    enableAdminInvitation: boolean;
};

export const SelectRolesColourStyles: StylesConfig = {
    control: (styles: any, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: "#303033",
            color: "#fff",
            border: "none",
            margin: 0
        };
    },
    container: (styles: any, { isFocused }) => {
        return {
            backgroundColor: "#303033",
            margin: 0,
        };
    },
    menuList: (styles: any) => {
        return {
            backgroundColor: "#303033",
            margin: 0
        };
    },
    singleValue: provided => ({
        ...provided,
        color: 'white',
        backgroundColor: "#303033",
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#4276ea" : "#303033",
            color: isFocused ? "#F9FAFC" : "#fff",
            display: "flex",
            paddingLeft: 0,
            margin: 0,
            fontSize: "12px",

            "& .left": {
                display: "flex",
                justifyContent: "center",
                width: 30,
                marginTop: 5
            },
            "& .right": {
                width: "100%"
            },

            "& .right > .title": {
                display: "block",
                margin: "2px 0"
            }
        };
    }
};

export const SelectRolesOption = (props: any) => {
    return (
        <components.Option {...props}>
            <div className="left">{props.isSelected ? "✔" : ""}</div>
            <div className="right">
                <strong className="title">{props.data.label}</strong>
                <div>{props.data.description}</div>
            </div>
        </components.Option>
    );
};



export const TeamInvite: FC<TeamInviteEditorProps> = ({ team, show, handleClose, enableAdminInvitation }) => {
    const user = useSelector(selectLoggedInUser);
    const { data: roles } = useTeamRoleControllerGetTeamRolesQuery({ teamId: team.teamId });

    const [inviteMembers] = useMembershipControllerInviteMembersMutation();
    const onSubmit = async (values: any, formikHelpers: any) => {
        try {
            const lines = values.inviteMembersLines.map((v: any) => { return { email: v.email, role: v.role.value } })
            const invite = await inviteMembers({ teamInviteMembersDto: { inviteMembersLines: lines }, teamId: team.teamId }).unwrap();
            handleClose()
        } catch (error) { }
    };


    const formatRoles = (roles: TeamRole[]) => {
        return roles.map((r) => ({ value: r, label: r.name, description: r.description }))
    }

    const initialValues = { inviteMembersLines: [{ email: "", role: roles ? formatRoles(roles.filter(r => r.permissions.length == 0))[0] : { value: '', label: "", description: "" } }] };
    const validationSchema = Yup.object().shape({
        inviteMembersLines: Yup.array().of(Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
            role: Yup.object().defined(),
        }))
    });





    return (<>
        {team && (
            <Modal className="inviteMemberModal" show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <FormikForm>
                            <Modal.Header closeButton>
                                <Modal.Title>Invite New Members</Modal.Title>

                            </Modal.Header>
                            <Container>Invite new members by email to join your team.</Container>
                            <Modal.Body className="show-grid">
                                <Container>
                                    <Row>
                                        <Col sm={7}>
                                            <Row className="propTitle mb-2" >Email address</Row>
                                        </Col>
                                        <Col sm={3} className="ml-2">
                                            <Row className="propTitle mb-2">Role</Row>

                                        </Col>
                                        <Col sm={1}>
                                            <Row className="propTitle mb-2">&nbsp;</Row>
                                        </Col>
                                    </Row>
                                    <FieldArray
                                        name="inviteMembersLines"
                                        render={({ insert, remove, push }) => (
                                            <>
                                                {values.inviteMembersLines.length > 0 && values.inviteMembersLines.map((line, index) => (
                                                    <Row key={index}>
                                                        <Col sm={5}>
                                                            <Row>
                                                                <FkSimpleTextInput
                                                                    style={{ width: "100%", margin: 0 }}
                                                                    type="text"
                                                                    name={`inviteMembersLines.${index}.email`}
                                                                    placeholder="Enter one email"
                                                                />
                                                            </Row>
                                                        </Col>
                                                        <Col sm={5} className="ml-2" style={{ marginBottom: 0 }}>
                                                            {roles && <Select
                                                                options={enableAdminInvitation ? formatRoles(roles) : formatRoles(roles?.filter((r) => r.permissions.length == 0))}
                                                                styles={SelectRolesColourStyles}
                                                                components={{ Option: SelectRolesOption }}
                                                                defaultValue={initialValues.inviteMembersLines[0].role}
                                                                name={`inviteMembersLines.${index}.role`}
                                                                onChange={(option: any) => setFieldValue(`inviteMembersLines.${index}.role`, option)}
                                                            />}
                                                        </Col>
                                                        <Col sm={1}>
                                                            <Row><Button
                                                                onClick={() => remove(index)}
                                                            >
                                                                X
                                                            </Button></Row>

                                                        </Col>
                                                    </Row>
                                                ))
                                                }
                                                <Row className="mt-5">
                                                    <Col>
                                                        <Button
                                                            onClick={() => { push(initialValues.inviteMembersLines[0]) }}>
                                                            <FontAwesomeIcon
                                                                className="mr-2"
                                                                icon={faPlusCircle}

                                                            />
                                                            Add another
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )} />






                                </Container>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit">
                                    Send invite
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        )
        }
    </>
    );
};
