import { FC } from "react";
import { Column, Row } from "react-table";
import {
    AccessControlEntry,
    UpsertDocumentMembershipDto,
    getDocumentAclEntries,
    revokeDocumentAclEntry,
} from "src/api/document-access-control.api";
import TableSimple from "src/components/tables/TableSimple";
import { DocumentAclEntryEditor } from "./document-acl-entry-editor";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { Can } from "src/casl/Can";
import { Action } from "src/api/Permissions";
import { subject } from "@casl/ability";

export type DocumentAclEntriesEditorProps = {
    documentId: string;
    documentType: string;
    teamId: string;
    onUpsert: (dto: UpsertDocumentMembershipDto) => Promise<unknown>;
};

export const DocumentAclEntriesEditor: FC<DocumentAclEntriesEditorProps> = ({
    documentId,
    documentType,
    teamId,
    onUpsert,
}) => {
    const { data } = getDocumentAclEntries({ documentId, documentType });
    const [revoke] = revokeDocumentAclEntry();

    const onClickRevoke = (ace: AccessControlEntry) => {
        revoke({
            findOneDocumentMembershipDto: {
                documentId: ace.objId,
                documentType: ace.objType,
                teamMembershipId: ace.membershipId,
            },
        });
    };

    const columns: Column<AccessControlEntry>[] = [
        {
            Header: "username",
            accessor: (row) => row.membership?.user.username,
        },
        { Header: "role", accessor: (row) => row.role.name },
        {
            Header: "actions",
            Cell: ({ row }: { row: Row<AccessControlEntry> }) => (
                <>
                    <Can
                        I={Action.Share}
                        this={subject(documentType, {
                            id: documentId,
                            perms: data,
                            teamId,
                        })}
                        field="perms"
                    >
                        <DeleteButtonWithConfirm
                            onClick={() => onClickRevoke(row.original)}
                        />
                    </Can>
                </>
            ),
        },
    ];

    return (
        <>
            <Can
                I={Action.Share}
                this={subject(documentType, {
                    id: documentId,
                    perms: data,
                    teamId,
                })}
                field="perms"
            >
                <DocumentAclEntryEditor
                    documentId={documentId}
                    documentType={documentType}
                    teamId={teamId}
                    onSubmit={onUpsert}
                />
            </Can>
            <TableSimple
                columns={columns}
                data={data || []}
                fetchData={() => {}}
                loading={false}
            ></TableSimple>
        </>
    );
};
