import { FieldHookConfig, useField } from "formik";
import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useMyTeamsControllerFindMineQuery, useTeamControllerFindAllQuery } from "src/api/team.api";
import { selectLoggedInUser } from "../auth/authSlice";
import { useSelector } from "react-redux";

export type FkMyTeamsDropdownProps = FieldHookConfig<any> & {
    /** optional label for the field */
    label?: string;
};

export const FkMyTeamsDropdown: FC<FkMyTeamsDropdownProps> = ({
    label,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    const user = useSelector(selectLoggedInUser);

    const useQueryTeamFunc = user?.role == 'ADMIN' ? useTeamControllerFindAllQuery : useMyTeamsControllerFindMineQuery
    const { data } = useQueryTeamFunc({});

    return (
        <Form.Group as={Row} controlId={field.name}>
            {label && (
                <Form.Label column sm="2">
                    {label}
                </Form.Label>
            )}
            <Col sm={label ? "10" : "12"}>
                {data && (
                    <>
                        <Form.Control
                            as="select"
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={(evt) => {
                                console.log(evt);
                                field.onChange(evt);
                            }}
                            value={field.value}
                            isInvalid={
                                (meta.touched && meta.error !== undefined) ||
                                (meta.initialError !== undefined &&
                                    meta.initialValue === field.value)
                            }
                            disabled={props.disabled}
                        >
                            <option key="teamSelectorNoVal" value="">
                                Select a team
                            </option>
                            {data?.results.map((item) => (
                                <option key={item.teamId} value={item.teamId}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Control>
                        {meta.touched && meta.error ? (
                            <Form.Control.Feedback type="invalid">
                                {meta.error}
                            </Form.Control.Feedback>
                        ) : meta.initialError &&
                            meta.initialValue === field.value ? (
                            <Form.Control.Feedback type="invalid">
                                {meta.initialError}
                            </Form.Control.Feedback>
                        ) : null}
                    </>
                )}
            </Col>
        </Form.Group>
    );
};
