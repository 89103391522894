import { FC } from "react";
import { Column } from "react-table";
import { useGetPackageVersions } from "src/api/BinaryArtifactApi";
import { PackageVersionSummaryDto } from "src/api/generated.api";
import TableSimple from "src/components/tables/TableSimple";

export const ArtifactColumns: Column<PackageVersionSummaryDto>[] = [
    {
        Header: "namespace",
        Cell: ({ row }: { row: { original: PackageVersionSummaryDto } }) => (
            <>{row.original.namespace}</>
        ),
    },
    {
        Header: "packageName",
        Cell: ({ row }: { row: { original: PackageVersionSummaryDto } }) => (
            <>{row.original.packageName}</>
        ),
    },
    {
        Header: "version",
        Cell: ({ row }: { row: { original: PackageVersionSummaryDto } }) => (
            <>{row.original.version}</>
        ),
    },
    {
        Header: "version",
        Cell: ({ row }: { row: { original: PackageVersionSummaryDto } }) => (
            <>{row.original.revision}</>
        ),
    },
];

export type BinaryArtifactVersionTableProps = {
    columnSelector?: string[];
    columnOverride?: Column<PackageVersionSummaryDto>[];
    namespace: string;
    packageName: string;
};

export const keepColumns = (columnSelector: string[]) =>
    columnSelector
        .map((name) => ArtifactColumns.find(({ Header }) => Header === name))
        .filter((c): c is Column<PackageVersionSummaryDto> => !!c);

export const BinaryArtifactVersionTable: FC<BinaryArtifactVersionTableProps> =
    ({ columnSelector, columnOverride, namespace, packageName }) => {
        const columns: Column<PackageVersionSummaryDto>[] =
            columnOverride ||
            (columnSelector ? keepColumns(columnSelector) : ArtifactColumns);

        const { data, isFetching, isLoading } = useGetPackageVersions(
            { namespace, packageName },
            { refetchOnMountOrArgChange: true },
        );
        const fetchData = () => {};
        return (
            <TableSimple<PackageVersionSummaryDto>
                columns={columns}
                data={data || []}
                loading={isFetching || isLoading}
                fetchData={fetchData}
            />
        );
    };
