import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import { AuthApi } from "../../api/AuthApi";
import { LoginStatusDto, User } from "../../api/generated.api";

export interface AuthState {
    status: "UNKNOWN" | "NOT_LOGGED_IN" | "LOGGED_IN";
    user?: User;
    rules?: any;
    token?: string;
}

const initialState: AuthState = {
    status: "UNKNOWN",
};

function setAuthStateFromPayload(
    state: AuthState,
    payload?: LoginStatusDto,
): void {
    console.log("state before", state);
    console.log("newAuthState");
    if (!payload || payload.status === "NOT_LOGGED_IN") {
        state.status = "NOT_LOGGED_IN";
        state.rules = null;
        state.user = undefined;
        return;
    }

    if (payload.status === "LOGGED_IN") {
        state.user = payload.user;
        state.rules = payload.rules;
        state.status = "LOGGED_IN";
        return;
    }
}

export const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            AuthApi.endpoints.authControllerGetAbilities.matchFulfilled,
            (state, { payload }) => {
                setAuthStateFromPayload(state, payload);
            },
        );
        builder.addMatcher(
            AuthApi.endpoints.authControllerGetAbilities.matchRejected,
            (state) => {
                setAuthStateFromPayload(state);
            },
        );
        builder.addMatcher(
            AuthApi.endpoints.authControllerLocalLogin.matchFulfilled,
            (state, { payload }) => {
                setAuthStateFromPayload(state, payload);
            },
        );
        builder.addMatcher(
            AuthApi.endpoints.authControllerLocalLogin.matchRejected,
            (state) => {
                setAuthStateFromPayload(state);
            },
        );
        builder.addMatcher(
            AuthApi.endpoints.authControllerLogout.matchFulfilled,
            (state) => {
                setAuthStateFromPayload(state);
            },
        );
        builder.addMatcher(
            AuthApi.endpoints.authControllerLogout.matchRejected,
            (state) => {
                setAuthStateFromPayload(state);
            },
        );
        builder.addMatcher(
            AuthApi.endpoints.socialCasLogin.matchFulfilled,
            (state, { payload }) => {
                setAuthStateFromPayload(state, payload);
            },
        );
        builder.addMatcher(
            AuthApi.endpoints.socialGoogleLoginRedirect.matchFulfilled,
            (state, { payload }) => {
                setAuthStateFromPayload(state, payload);
            },
        );
    },
});

export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectLoggedInUserName = (state: RootState) =>
    state.auth.user?.username;
export const selectLoggedInUser = (state: RootState) => state.auth.user;
export const selectRules = (state: RootState) => state.auth.rules;

export default authSlice.reducer;
