import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { IconWithTooltip, IconWithTooltipProps } from "./IconWithTooltip";

export const DeleteButton: FC<ButtonProps> = (props) => (
    <Button variant="danger" {...props}>
        <FontAwesomeIcon icon={faTrash} />
    </Button>
);

export type DeleteIconProps = ButtonProps & Omit<IconWithTooltipProps, "icon">;

export const DeleteIcon: FC<DeleteIconProps> = (props) => (
    <IconWithTooltip icon={faTrash} {...props} />
);
