export interface ErrorValues {
    [field: string]: string;
}

/**
 * Convert validation error to formik errors
 * @example
 * const onFormikSubmit = async (values: SomeDtoToSubmit, formikBag: FormikHelpers<SomeDtoToSubmit>) => {
 *     try {
 *         const resultObj = await createOrUpdateMutation({dtoArg: values}).unwrap();
 *         //do something with the resultObj
 *     } catch (e) {
 *         // or convert the api errors to formik
 *         const errors = convertApiErrorsToFormikErrors(e);
 *         // and reinject it into formik
 *         formikBag.setErrors(errors);
 *     }
 * }
 * @param e
 * @returns
 */
export function convertApiErrorsToFormikErrors(e: any): ErrorValues {
    const payload = e.data.message as {
        classname: string;
        property: string;
        message: string;
    }[];

    const errors: ErrorValues = {};

    if (payload !== undefined) {
        for (let i = 0; i < payload.length; i++) {
            if (
                payload[i].property === undefined ||
                payload[i].message === undefined
            ) {
                throw e;
            }
            errors[payload[i].property] = payload[i].message;
        }
    } else {
        throw e;
    }

    return errors;
}

export function extractErrorMessageFromError(e: any): string {
    const payload = e.data.message as string;
    if (payload !== undefined) {
        return payload;
    } else {
        return JSON.stringify(e);
    }
}
