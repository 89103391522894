import { useAbility } from "@casl/react";
import { Form as FormikForm, Formik } from "formik";
import { FC } from "react";
import { Button } from "react-bootstrap";

import {
    TeamRole,
    Team,
    useTeamRoleControllerCreateRoleMutation,
    useTeamRoleControllerUpdateRoleMutation,
    useMyTeamsControllerFindMineQuery,
} from "src/api/team.api";
import { AbilityContext } from "src/casl/Can";
import { FkCheckBoxList } from "src/components/FkCheckboxList";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";
import { Subjects, U } from "src/api/Permissions";
import { useMyTeamsControllerFindOneWithUserMembershipQuery } from "src/api/generated.api";
import { ObjectNames, Sentences } from "src/lang/lang";


const permissions = [
    {
        rawItem: "RENAME_TEAM",
        displayName: "Rename Team",
        description: "allows to rename a team",
    },
    {
        rawItem: "ADD_REMOVE_USERS",
        displayName: "Add or Remove Users",
        description: "allows to add or remove users to the team",
    },
    {
        rawItem: "MANAGE_DOCUMENTS",
        displayName: "Manage Documents",
        description:
            "Read, Update, Create, Delete, Share all documents type belonging to this team, even if the document is private.",
    },
    {
        rawItem: "CREATE_CONTENT",
        displayName: "Create Content",
        description:
            "allows to create terrain, layers and teaching content. Can also READ all documents of this team marked as visibility:team.",
    },
    {
        rawItem: "CREATE_SESSION",
        displayName: "Create Session",
        description:
            "allows to create interactive teaching sessions. Can also READ all ACTIVITIES of this team marked as visibility:team",
    },
];

export type TeamRoleEditorProps = {
    teamRole?: TeamRole;
    teamId: Team["id"];
    isEdit?: boolean;
    onSave?: (role: TeamRole) => void;
};

export const TeamRoleEditor: FC<TeamRoleEditorProps> = ({
    teamRole,
    teamId,
    isEdit,
    onSave,
}) => {
    const [updateRole] = useTeamRoleControllerUpdateRoleMutation();
    const [createRole] = useTeamRoleControllerCreateRoleMutation();


    const handleSubmit = async (values: any) => {
        const { name, permissions } = values;
        let updatedRole: TeamRole;
        if (teamRole && values.id) {
            await updateRole({
                roleId: teamRole.id,
                teamId,
                updateTeamRoleDto: values,
            }).unwrap();
            updatedRole = teamRole;
        } else {
            updatedRole = (await createRole({
                createTeamRoleDto: { name, permissions },
                teamId,
            }).unwrap()) as TeamRole;
        }
        if (onSave) {
            onSave(updatedRole);
        }
    };

    const {
        data: team,
        isFetching,
        isLoading,
    } = useMyTeamsControllerFindOneWithUserMembershipQuery({
        teamId,
    });

    const ability = useAbility(AbilityContext);

    return (
        <>
            {team && (
                <div className="section mb-3">
                    <h1>{Sentences.editTeamRoleTitle.en}</h1>
                    <div className="subtitle mb-4">
                        {Sentences.editTeamRoleSubtitles.en}
                    </div>
                    <Formik
                        initialValues={{
                            ...(teamRole
                                ? {
                                    ...teamRole,
                                    permissions: [...teamRole.permissions.map(o => o.action)],
                                }
                                : { name: "", permissions: [] }),
                        }}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {(formikProps) => (
                            <FormikForm
                                onSubmit={formikProps.handleSubmit}
                                method="post"
                            >
                                <FkStackedTextInput
                                    label="Role name"
                                    placeholder="a role"
                                    name="name"
                                />

                                <FkCheckBoxList
                                    allItems={permissions}
                                    name="permissions"
                                    label="Permissions"
                                />
                                {ability.can(U, team, "name") && (<Button disabled={!formikProps.dirty} type="submit">
                                    Save
                                </Button>)}
                            </FormikForm>
                        )}
                    </Formik>
                </div>
            )}
        </>
    );
};
