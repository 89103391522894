import { useContext } from "react";
import { Col, Row } from "react-bootstrap"
import { LangContext } from "src/lang/lang"

export const Home = () => {
    const { Sentences } = useContext(LangContext);
    return (<>
        <Row className="bg text-center">
            <Col className="my-auto">
                <h1 className="display-4">
                    {Sentences.welcome.en}
                </h1>
            </Col>
        </Row>
    </>)
}