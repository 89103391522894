import { useContext } from "react";
import { CreateLayerDto } from "src/api/generated.api";
import { LangContext } from "src/lang/lang";

export type LayerManagementModeTypes = NonNullable<
    CreateLayerDto["managementMode"]
>;

export const useLayerManagementMode = () => {
    const { Sentences } = useContext(LangContext);
    const meshSourceTypes: Array<{
        id: LayerManagementModeTypes;
        name: string;
    }> = [
            { id: "INTERNAL", name: Sentences.layerIsInternal.en },
            { id: "EXTERNAL", name: Sentences.layerIsExternal.en }
        ];
    return meshSourceTypes;
};

export const uploadableTypes = new Set<LayerManagementModeTypes>(["INTERNAL"]);
