import { faCog, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { FC, useContext } from "react";
import {
    Accordion,
    Row,
    Col,
    Button,
    AccordionCollapse,
    Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGetLayerQuery } from "src/api/LayerApi";
import { BootstrapTextInput } from "src/components/BootstrapFormComponents";
import { EditButton } from "src/components/EditButton";
import { FkEditKeyValues } from "src/components/FkEditKeyValues";
import { LangContext } from "src/lang/lang";
import { LayerMap } from "../terrains/TerrainMap";
import { ParamButton } from "./terrain-layer-editor";
import { Can } from "src/casl/Can";
import { subject } from "@casl/ability";
import { Action, Subjects } from "src/api/Permissions";
import { TerrainLayer } from "src/api/generated.api";

type LayerViewerGenericProps = {
    action?: string;
    onActionClicked?: (id: number) => void;
    showEdit?: boolean;
};

export type LayerViewerByIdProps = LayerViewerGenericProps & {
    layerId: TerrainLayer["id"];
};

export type LayerViewerProps = LayerViewerGenericProps & {
    layer: TerrainLayer;
};

export const LayerViewerById: FC<LayerViewerByIdProps> = ({
    layerId,
    ...props
}) => {
    const { data } = useGetLayerQuery(
        { layerId },
        { refetchOnMountOrArgChange: true },
    );
    return <>{data && <LayerViewer layer={data} {...props} />}</>;
};

export const LayerViewer: FC<LayerViewerProps> = ({
    layer,
    action,
    onActionClicked,
    showEdit,
}) => {
    const { Sentences } = useContext(LangContext);

    const navigate = useNavigate();

    const onClicked = () => {
        if (onActionClicked) {
            onActionClicked(layer.id);
        }
    };

    return (
        <>
            {layer && (
                <div>
                    <Formik
                        initialValues={layer}
                        enableReinitialize
                        onSubmit={() => { }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} method="post">
                                <Accordion>
                                    <Row>
                                        <Col>
                                            <BootstrapTextInput
                                                label="Layer name"
                                                name="name"
                                                placeholder="layer name"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <BootstrapTextInput
                                                label="Management mode"
                                                name="managementMode"
                                                placeholder="Mode"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="auto">
                                            <ParamButton eventKey="0">
                                                <FontAwesomeIcon icon={faCog} />
                                            </ParamButton>
                                        </Col>

                                        <Col>
                                            <BootstrapTextInput
                                                label="Url"
                                                name="url"
                                                placeholder={
                                                    Sentences
                                                        .layerDetailsJsonUrl.en
                                                }
                                                disabled
                                            />
                                        </Col>
                                        {showEdit && (
                                            <Can
                                                I={Action.Update}
                                                this={subject(
                                                    Subjects.TerrainLayer,
                                                    { ...layer },
                                                )}
                                            >
                                                <Col md="auto">
                                                    <EditButton
                                                        onClick={() =>
                                                            navigate(
                                                                `/terrain-layers/${layer.id}`,
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Can>
                                        )}
                                        {action && onActionClicked && (
                                            <Col md="auto">
                                                <Button
                                                    variant="danger"
                                                    onClick={onClicked}
                                                >
                                                    {action}
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                    <AccordionCollapse eventKey="0">
                                        <FkEditKeyValues
                                            disabled
                                            name="connectionParams"
                                            label={
                                                Sentences
                                                    .connectionHeaderAndValues
                                                    .en
                                            }
                                        />
                                    </AccordionCollapse>
                                </Accordion>
                            </Form>
                        )}
                    </Formik>
                    <LayerMap terrainLayer={layer} mapHeight="600px" />
                </div>
            )}
        </>
    );
};
