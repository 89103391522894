import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { useSocialCasLoginQuery } from 'src/api/generated.api';
import RoutesDef from 'src/routes/RoutesDef';

export function CasLogin() {
    const location = useLocation();
    const navigate = useNavigate();
    const { ticket } = queryString.parse(location.search);

    const { data, isSuccess, isError } = useSocialCasLoginQuery({ ticket: ticket && !Array.isArray(ticket) ? ticket : "" });

    useEffect(() => {
        if (isError) {
            navigate(RoutesDef.LOGIN);
        }

        if (isSuccess) {
            navigate(RoutesDef.HOME);
        }
    }, [isError, isSuccess, navigate]);

    return (<>
        {data ? JSON.stringify(data) : "loading"}
    </>);

}