import { FC, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { Column } from "react-table";
import { Job, JobLogDto } from "src/api/generated.api";
import { useClearJobLogMutation, useGetJobLogsQuery } from "src/api/JobApi";
import TableSimple from "src/components/tables/TableSimple";
import { LangContext } from "src/lang/lang";

export type JobLogsProps = {
    jobId: Job['id'];
}

export const JobLogs: FC<JobLogsProps> = ({ jobId }) => {
    const {ObjectNames, Sentences } = useContext(LangContext);

    const { data: jobLogs, isFetching } = useGetJobLogsQuery({jobId}, {refetchOnMountOrArgChange: true});
    const [ clearLogs ] = useClearJobLogMutation();

    const columns: Column<JobLogDto>[] = [
        {
            Header: ObjectNames.timestamp.en,
            width: 230,
            Cell: ({ row } : { row : { original : JobLogDto} }) => (
                <div>
                    {row.original.timestamp}
                </div>
            )
        },
        {
            Header: Sentences.logLine.en,
            width: undefined,
            Cell: ({ row } : { row : { original : JobLogDto} }) => (
                <div>
                    {row.original.log}
                </div>
            )
        }
    ];

    return (<><TableSimple 
        columns={columns} 
        loading={isFetching}
        data={jobLogs || []}
        fetchData={(pageIndex, pageNumber)=>null}
    />
    <Button onClick={()=>clearLogs({jobId})}>Clear Logs</Button>
    </>);
}
