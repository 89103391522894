import React from "react";
import { FkDraggableChoiceItem, FkDraggableChoiceItemProps } from "./FkDraggableChoiceItem";

export default function FKReorderChoiceItem(props: FkDraggableChoiceItemProps) {
    return (
        <FkDraggableChoiceItem {...props}>
            <div>{props.index +1 }</div>
        </FkDraggableChoiceItem>
    );
}
