import { ReactPropTypes, useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { UserEditor } from "./user-editor";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../auth/authSlice";

export default function EditUserPage() {
    const { Sentences } = useContext(LangContext);

    const { userId } = useParams<{ userId: string }>();
    const ability = useContext(AbilityContext);
    const user = useSelector(selectLoggedInUser);
    return (userId ? <>
        <Container>
            {ability.can(Action.Read, Subjects.Users)
                ? <UserEditor id={parseInt(userId)} />
                : <ErrorBar errorMessage={Sentences.pageNotAllowed.en} />
            }
        </Container>
    </> : <></>
    )
}