import { FC } from "react"
import { measurementTypes, drawTypes } from "./Types"
export type GeoObjectProps = {
    geojson: any
}

export const GeoObject : FC<GeoObjectProps> = ({geojson}) => {

    const properties: any = geojson["properties"];
    const componentType: string = properties ? properties["TypeReference"]?.toUpperCase() : undefined;

    const geomType: string = geojson["geometry"]["type"];
    if (componentType && Object.keys(measurementTypes).includes(componentType)) {        
        return (<><img height="35px" src={measurementTypes[componentType].icon} style={{filter: "brightness(0.4)"}} alt={measurementTypes[componentType].name}/> Measurement { measurementTypes[componentType].name }</>)
    } else {
        return (<><img height="35px" src={drawTypes[geomType].icon} style={{filter: "brightness(0.4)"}} alt={drawTypes[geomType].name}/> { drawTypes[geomType].name }</>)
    }    
}