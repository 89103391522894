import CompasIcon from '../../../design-system/Icons/Icons-x65_SVG/Compas_Tool.svg';
import ClinometerIcon from '../../../design-system/Icons/Icons-x65_SVG/Clinometer_Tool.svg';

import AngleHorizontalIcon from '../../../design-system/Icons/Icons-x65_SVG/HorizontalAngle_Tool.svg';
import Angle3PointsIcon from '../../../design-system/Icons/Icons-x65_SVG/Angle_Tool.svg';
import AngleVerticalIcon from '../../../design-system/Icons/Icons-x65_SVG/VerticalAngle_Tool.svg';

import DistanceHorizontalIcon from '../../../design-system/Icons/Icons-x65_SVG/HorizontalDistance_Tool.svg';
import DistancePointToPointIcon from '../../../design-system/Icons/Icons-x65_SVG/PointToPoint_Tool.svg';
import DistanceVerticalIcon from '../../../design-system/Icons/Icons-x65_SVG/VerticalPoint_Tool.svg';

import PlaneMultiPointsIcon from '../../../design-system/Icons/Icons-x65_SVG/Multipoints_Tool.svg';
import PlaneIcon from '../../../design-system/Icons/Icons-x65_SVG/Plane_Tool.svg';
import Plane3Points from '../../../design-system/Icons/Icons-x65_SVG/3Points_Tool.svg';

import PositionIcon from '../../../design-system/Icons/Icons-x65_SVG/Position_Tool.svg';

import LineStringIcon from '../../../design-system/Icons/Icons-x65_SVG/Line_Tool.svg';
import PolygonIcon from '../../../design-system/Icons/Icons-x65_SVG/Polygon_Tool.svg';
import PointIcon from '../../../design-system/Icons/Icons-x65_SVG/Point_Tool.svg';

type DrawnObjectsDetails = {
    name: string,
    icon: any,
}

export const measurementTypes: { [key: string]: DrawnObjectsDetails } = {
    "CCB16595-D78F-44E9-997E-DFBE1497C516": { name: "Clinometer", icon: ClinometerIcon },
    "B63131EC-0868-46FC-808A-C2E9A0D3EC97": { name: "AngleHorizontal", icon: AngleHorizontalIcon },
    "7996352A-9FD3-402D-82F1-32CA5D50AC95": { name: "AngleThreePoints", icon: Angle3PointsIcon },
    "A38464D9-7BC4-4F27-9403-BF10FFA8985F": { name: "AngleVertical", icon: AngleVerticalIcon },
    "06E0679E-5AC6-4095-9D72-78BD2436E0C4": { name: "Compass", icon: CompasIcon },
    "BD47452B-B636-4A8E-8A45-C37EFC3F1932": { name: "DistanceHorizontal", icon: DistanceHorizontalIcon },
    "0608FFFC-D35B-4DE6-9ACB-CA7E62BC9183": { name: "DistancePointToPoint", icon: DistancePointToPointIcon },
    "4DF13CBC-0B0E-49BB-8AE9-6DB34029FE82": { name: "DistanceVertical", icon: DistanceVerticalIcon },
    "29AE54E9-FAB6-4335-B906-E08429872ACF": { name: "Plane", icon: PlaneIcon },
    "7936F799-0D8C-4507-8292-29A5104A4112": { name: "Plane3Point", icon: Plane3Points },
    "6BDCB9DE-513E-45DE-8D3B-380939B4D6F6": { name: "PlaneMultiPoint", icon: PlaneMultiPointsIcon },
    "3992C75B-F087-4B5C-8E78-30BF15242BF4": { name: "Position", icon: PositionIcon },
}

export const drawTypes: {[key: string]: DrawnObjectsDetails} = {
    "Point": { name: "Point", icon: PointIcon },
    "LineString": { name: "LineString", icon: LineStringIcon },
    "Polygon": { name: "Polygon", icon: PolygonIcon },
    "MultiPoint": { name: "MultiPoint", icon: PointIcon }, 
    "MultiLineString": { name: "MultiLineString", icon: LineStringIcon }, 
    "MultiPolygon": { name: "MultiPolygon", icon: PolygonIcon }
}