import { FC } from "react"
import { Container, Row } from "react-bootstrap"
import { JobLogs } from "../job/JobLogs"
import { JobStatus } from "../job/JobStatus"

export const LayerJobDetails: FC<{jobId: string}> = ({ jobId }) => {
    return (<Container>
        <Row>Job: {jobId}</Row>
        <Row><JobStatus jobId={jobId}/></Row>
        <Row><JobLogs jobId={jobId}/></Row>
    </Container>)

}