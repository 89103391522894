import { FieldHookConfig, useField } from 'formik'
import { FC } from 'react'
import { Col, Container, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { FkEditVectorObj } from './FkEditVectorObj';

export type FkEditBoundsProps = FieldHookConfig<any> & {
    /** optional label for the field */
    label? : string,
}

export const FkEditBounds: FC<FkEditBoundsProps> = ({ label, ...props }) => {
    const [{name}] = useField({ ...props });
    return (
      <FormGroup>
        <>
            {label &&             
            <FormLabel>{label}</FormLabel>}
            <Row>
                <Col><FkEditVectorObj name={`${name}['pMin']`} label="Bound Min"/></Col>
                <Col><FkEditVectorObj name={`${name}['pMax']`} label="Bound Max"/></Col>
            </Row>
        </>
      </FormGroup>
    );
  };