import React, { useEffect, useRef, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import {
    useGetActivitySlidesSummaryQuery,
    useUpdateActivitySlidesOrderMutation,
} from "src/api/ActivityApi";
import { DndProvider, useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { SlideSummaryDto } from "src/api/generated.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ObjectNames } from "src/lang/lang";

interface PageSelectorProps {
    activityId: number;
    onPageSelected?: (page: any) => void;
    onOrderChanged?: (pageList: PageOrderItem[]) => void;
    onAddClick?: () => void;
    currentPage?: SlideSummaryDto;
    editable: boolean;
}

interface PageItemProps {
    id: number;
    title: string;
    index: number;
    movePage: (dragIndex: number, dropIndex: number) => void;
    onDrop: () => void;
    onClick?: () => void;
}

const ItemTypes = {
    PAGE: "page",
};

interface DragItem {
    index: number;
    id: number;
    type: string;
}

interface PageOrderItem {
    id: number;
    title: string;
}

function PageItem({
    id,
    title,
    index,
    movePage,
    onClick,
    onDrop,
}: PageItemProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.PAGE,
        item: () => ({
            id,
            index,
            type: ItemTypes.PAGE,
        }),
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [{ handlerId }, drop] = useDrop(() => ({
        accept: ItemTypes.PAGE,
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver(),
            };
        },
        drop: (item: DragItem, monitor: DropTargetMonitor) => {
            onDrop();
        },
        hover: (item: DragItem, monitor: DropTargetMonitor) => {
            if (!ref.current) {
                return;
            }
            const dragId = item.id;
            const hoverId = id;

            if (dragId === hoverId) {
                return;
            }

            movePage(dragId, hoverId);
        },
    }));
    const opacity = isDragging ? 0.1 : 1;
    drag(drop(ref));

    return (
        <Nav.Item
            ref={ref}
            key={id}
            style={{ opacity }}
            data-handler-id={handlerId}
        >
            <Nav.Link eventKey={id} onClick={onClick}>
                <FontAwesomeIcon icon={faEllipsisV} /> {title}
            </Nav.Link>
        </Nav.Item>
    );
}

export default function PageSelector({
    activityId,
    onPageSelected,
    currentPage,
    onOrderChanged,
    onAddClick,
    editable,
}: PageSelectorProps) {
    const { data: pages } = useGetActivitySlidesSummaryQuery(
        { activityId },
        { refetchOnMountOrArgChange: true },
    );
    const [updateSlides] = useUpdateActivitySlidesOrderMutation();

    const [pageOrder, setPageOrder] = useState<PageOrderItem[]>([]);
    const [needSave, setNeedSave] = useState<Boolean>(false);

    useEffect(() => {
        if (pages) {
            setPageOrder(
                pages.map(({ id, title }, index) => ({
                    id,
                    title: title ? title : `${ObjectNames.slide.en} ${index}`,
                })),
            );
        }
    }, [pages]);

    const movePage = (dragId: number, hoverId: number) => {
        setPageOrder((prevPageOrder) => {
            const dragIndex = prevPageOrder.findIndex(
                ({ id }) => id === dragId,
            );
            const hoverIndex = prevPageOrder.findIndex(
                ({ id }) => id === hoverId,
            );
            if (dragIndex > -1 && hoverIndex > -1) {
                return update(prevPageOrder, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevPageOrder[dragIndex]],
                    ],
                });
            }
            return prevPageOrder;
        });
    };

    const onDrop = () => {
        setNeedSave(true);
    };

    const renderPageItem = (page: PageOrderItem, index: number) => {
        return (
            <PageItem
                key={page.id}
                id={page.id}
                title={page.title}
                index={index}
                movePage={editable ? movePage : () => {}}
                onDrop={editable ? onDrop : () => {}}
            />
        );
    };

    useEffect(() => {
        if (needSave) {
            setNeedSave(false);
            if (onOrderChanged) onOrderChanged(pageOrder);
            updateSlides({
                activityId,
                body: pageOrder.map(({ id }) => id),
            }).unwrap();
        }
    }, [needSave, activityId, onOrderChanged, pageOrder, updateSlides]);
    return (
        <div className="pageSelector">
            <DndProvider backend={HTML5Backend}>
                <Navbar.Brand>Slides</Navbar.Brand>
                <Navbar>
                    <Nav
                        className="flex-column"
                        activeKey={currentPage?.id}
                        onSelect={(selectedKey: any) => {
                            if (onPageSelected)
                                onPageSelected(
                                    pageOrder?.find(
                                        ({ id }) =>
                                            id === parseInt(selectedKey),
                                    ),
                                );
                        }}
                    >
                        {pageOrder?.map((page, index) =>
                            renderPageItem(page, index),
                        )}
                        {editable && (
                            <Button onClick={onAddClick}>New Slide</Button>
                        )}
                    </Nav>
                </Navbar>
            </DndProvider>
        </div>
    );
}
