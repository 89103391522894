import { Container } from "react-bootstrap";
import { UserEditor } from "./user-editor";
import { selectLoggedInUser } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { FC } from "react";
import { MyProfileEditor } from "./my-profile-editor";

export const MyProfilePage: FC<any> = () => {
    const user = useSelector(selectLoggedInUser);

    if (user) {
        return (
            <Container>
                <div className="section">
                    <MyProfileEditor />
                </div>
            </Container>
        );
    }
    else {
        return (<></>)
    }

};
