import { Formik, useFormikContext } from "formik";
import {
    Button,
    Container,
    Form,
    Row,
    Col,
    Accordion,
    Card,
} from "react-bootstrap";
import * as Yup from "yup";
import { BootstrapTextInput } from "../../components/BootstrapFormComponents";

import {
    useFindOneTerrainQuery,
    useGetTerrainModelLinksQuery,
    useGetTerrainLayersQuery,
    useUpdateTerrainMutation,
    useDeleteTerrainModelLinksMutation,
    useCreateTerrainModelLinkMutation,
    useDeleteTerrainMutation,
    useAttachLayerToTerrainMutation,
    useDetachLayerFromTerrainMutation,
    useTerrainAddUser,
    useChangeTeam,
    useChangeVisibility,
} from "src/api/TerrainApi";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { FC, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { TerrainLayer, TerrainToTerrainModel } from "src/api/generated.api";
import { LayerViewer, LayerViewerById } from "../layer/terrain-layer-viewer";
import { TerrainModelLinkEditor } from "./terrain-models/terrain-model-link-editor";
import { FkEditBounds } from "src/components/FkEditBounds";
import axios from "axios";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { TerrainLayersSelector } from "../layer/terrain-layers-selector";
import { TerrainModelTableSelector } from "./terrain-models/terrain-models-table-selector";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { Action, Subjects } from "src/api/Permissions";
import { ModalDocumentACLEditor } from "../document-access-control/modal-document-acl-editor";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "src/casl/Can";
import { subject } from "@casl/ability";
type EditTerrainParams = {
    terrainId: number;
};

type UpdateBoundsProps = {
    layers: TerrainLayer[];
};

const UpdateBounds: FC<UpdateBoundsProps> = ({ layers }) => {
    // Grab values and submitForm from context
    const { setFieldValue } = useFormikContext();

    async function getBoundsFromLayer() {
        if (layers && layers.length === 1) {
            const layer = layers[0];
            const res = await axios.get(
                `/api/layers/${layer.id}/retrieve-bounds`,
            );
            const bounds = res.data.bounds;
            setFieldValue("bounds", {
                pMin: { x: bounds.minx, y: bounds.miny, z: bounds.minz || 0 },
                pMax: { x: bounds.maxx, y: bounds.maxy, z: bounds.maxz || 0 },
            });
        }
    }

    return <Button onClick={getBoundsFromLayer}>copy bounds from layer</Button>;
};

export default function TerrainEditor({ terrainId }: EditTerrainParams) {
    /** Queries */
    const {
        data: terrain,
        isLoading,
        error,
        isError,
    } = useFindOneTerrainQuery({ terrainId });
    const { data: terrainModelLinks } = useGetTerrainModelLinksQuery(
        { terrainId },
        { refetchOnMountOrArgChange: true },
    );
    const { data: layers, refetch: refetchLayers } = useGetTerrainLayersQuery({
        terrainId,
    });

    /** Mutations */
    const [upsertMembership] = useTerrainAddUser();
    const [changeTeam] = useChangeTeam();
    const [changeVisibility] = useChangeVisibility();

    const [updateTerrain] = useUpdateTerrainMutation();
    const [deleteTerrain] = useDeleteTerrainMutation();
    const [attachLayer] = useAttachLayerToTerrainMutation();
    const [detachLayer] = useDetachLayerFromTerrainMutation();

    const [deleteTerrainModelLinks] = useDeleteTerrainModelLinksMutation();
    const [createTerrainModelLink] = useCreateTerrainModelLinkMutation();

    /** Context */
    const ab = useAbility(AbilityContext);
    const navigate = useNavigate();

    /** States */
    const { ObjectNames, Sentences } = useContext(LangContext);

    const [editable, setEditable] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showChooseLocalLayer, setShowChooseLocalLayer] = useState(false);
    const [models, setModels] = useState<TerrainToTerrainModel[]>([]);
    const [showTerrainModelSelector, setShowTerrainModelSelector] =
        useState(false);

    useEffect(() => {
        if (terrainModelLinks) {
            setModels(terrainModelLinks);
        }
    }, [terrainModelLinks]);

    useEffect(() => {
        isError
            ? setErrorMessage(
                error ? JSON.stringify(error) : "Error loading terrain",
            )
            : setErrorMessage("");
    }, [isError, error]);

    useEffect(() => {
        if (layers && layers.length > 0) {
            setShowChooseLocalLayer(false);
        }
    }, [layers]);

    useEffect(() => {
        setEditable(
            terrain !== undefined &&
            ab.can(
                Action.Update,
                subject(Subjects.Terrains, { ...terrain }),
            ),
        );
    }, [terrain, ab]);

    if (isLoading) {
        return <h1> Is Loading </h1>;
    }

    function deleteTerrainModelLink(terrainModelLink: number): void {
        deleteTerrainModelLinks({
            terrainId,
            deleteTerrainToTerrainModelsDto: { ids: [terrainModelLink] },
        });
    }

    const onSelectedLayer = async (terrainLayerId: TerrainLayer["id"]) => {
        try {
            await attachLayer({ terrainId, terrainLayerId }).unwrap();
            refetchLayers();
        } catch (e) {
            setErrorMessage(JSON.stringify(e));
        }
    };

    const onDetachLayer = async (terrainLayerId: number) => {
        try {
            await detachLayer({ terrainId, terrainLayerId }).unwrap();
            refetchLayers();
        } catch (e) {
            setErrorMessage(JSON.stringify(e));
        }
    };

    async function performDeleteTerrain(): Promise<void> {
        await deleteTerrain({ terrainId }).unwrap();
        navigate("/terrains");
    }

    return (
        <>
            <ErrorBar errorMessage={errorMessage} />
            {terrain && (
                <>
                    <Formik
                        initialValues={{
                            name: terrain.name,
                            description: terrain.description,
                            bounds: terrain.bounds,
                            teamId: terrain.teamId,
                        }}
                        enableReinitialize={true}
                        validationSchema={Yup.object({
                            name: Yup.string().required(),
                            description: Yup.string(),
                            teamId: Yup.string().uuid(),
                        })}
                        onSubmit={async (values, formikBag) => {
                            formikBag.setSubmitting(true);

                            try {
                                await updateTerrain({
                                    terrainId,
                                    updateTerrainDto: { ...values },
                                }).unwrap();
                            } catch (e) {
                                const errors =
                                    convertApiErrorsToFormikErrors(e);
                                formikBag.setErrors(errors);
                            }
                            formikBag.setSubmitting(false);
                        }}
                    >
                        {(formik) => (
                            <>
                                <Form
                                    onSubmit={formik.handleSubmit}
                                    method="post"
                                >
                                    <Container className="section">
                                        <Row>
                                            <Col>
                                                <h1>
                                                    {ObjectNames.terrain.en}
                                                </h1>

                                                <ModalDocumentACLEditor
                                                    document={terrain}
                                                    onVisibilityUpdate={
                                                        changeVisibility
                                                    }
                                                    documentType={
                                                        Subjects.Terrains
                                                    }
                                                    onTeamChanged={changeTeam}
                                                    objWithTeam={terrain}
                                                    documentId={`${terrain.id}`}
                                                    teamId={terrain.teamId}
                                                    onUpsert={(dto) =>
                                                        upsertMembership({
                                                            id: dto.documentId,
                                                            upsertDocumentMembershipDto:
                                                                dto,
                                                        }).unwrap()
                                                    }
                                                />
                                            </Col>
                                            {editable && (
                                                <Col
                                                    md="auto"
                                                    className="d-flex align-items-center"
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="primary"
                                                        disabled={!formik.dirty}
                                                    >
                                                        {Sentences.save.en}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </Container>

                                    <Container>
                                        <div className="section">
                                            <BootstrapTextInput
                                                label={Sentences.terrainName.en}
                                                name="name"
                                                placeholder={
                                                    Sentences.terrainName.en
                                                }
                                                disabled={!editable}
                                            />

                                            <BootstrapTextInput
                                                label="Description"
                                                name="description"
                                                placeholder="description"
                                                disabled={!editable}
                                            />
                                        </div>
                                        <div className="section">
                                            <FkEditBounds
                                                name="bounds"
                                                label={
                                                    Sentences.terrainBounds.en
                                                }
                                                disabled={!editable}
                                            />
                                            {layers && layers.length === 1 && (
                                                <UpdateBounds layers={layers} />
                                            )}
                                        </div>
                                    </Container>
                                </Form>
                            </>
                        )}
                    </Formik>
                    <Container className="section">
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant="NavLink"
                                                eventKey="0"
                                            >
                                                {
                                                    Sentences
                                                        .textureAndElevation.en
                                                }
                                            </Accordion.Toggle>
                                        </Col>
                                        {layers &&
                                            layers.length === 0 &&
                                            editable && (
                                                <Col md="auto">
                                                    <Button
                                                        onClick={() =>
                                                            setShowChooseLocalLayer(
                                                                (v) => !v,
                                                            )
                                                        }
                                                    >
                                                        {showChooseLocalLayer
                                                            ? "Cancel"
                                                            : "Choose layer"}
                                                    </Button>
                                                </Col>
                                            )}
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div>
                                            {layers?.map((layer) => (
                                                <LayerViewer
                                                    key={`layer_${layer.id}`}
                                                    showEdit={ab.can(
                                                        Action.Update,
                                                        subject(
                                                            Subjects.TerrainLayer,
                                                            { ...layer },
                                                        ),
                                                    )}
                                                    layer={layer}
                                                    action={"Detach Layer"}
                                                    onActionClicked={
                                                        ab.can(
                                                            Action.Update,
                                                            subject(
                                                                Subjects.Terrains,
                                                                { ...terrain },
                                                            ),
                                                        )
                                                            ? onDetachLayer
                                                            : undefined
                                                    }
                                                />
                                            ))}
                                            {layers?.length === 0 && (
                                                <div>
                                                    This terrain has no texture
                                                    / elevation layer
                                                </div>
                                            )}
                                            {showChooseLocalLayer &&
                                                editable && (
                                                    <TerrainLayersSelector
                                                        onSelectedLayer={
                                                            onSelectedLayer
                                                        }
                                                    />
                                                )}
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="NavLink"
                                        eventKey="1"
                                    >
                                        {ObjectNames.models3d.en}
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <Container>
                                            {models && models.length > 0 ? (
                                                models.map(
                                                    (modelLink, index) => (
                                                        <div key={modelLink.id}>
                                                            <TerrainModelLinkEditor
                                                                terrainId={
                                                                    terrainId
                                                                }
                                                                value={
                                                                    modelLink
                                                                }
                                                                onDeleteClick={
                                                                    deleteTerrainModelLink
                                                                }
                                                            />
                                                            <hr />
                                                        </div>
                                                    ),
                                                )
                                            ) : (
                                                <Card className="text-center">
                                                    <Card.Body>
                                                        <Card.Title>
                                                            {
                                                                Sentences
                                                                    .terrainNoModelAttached
                                                                    .en
                                                            }
                                                        </Card.Title>
                                                        <Card.Text>
                                                            {
                                                                Sentences
                                                                    .terrainUseButtonToAttachModel
                                                                    .en
                                                            }
                                                            <br />
                                                            <br />
                                                            <br />
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Container>
                                        {editable && (
                                            <Container className="mt-3">
                                                <Row>
                                                    <Col md="auto">
                                                        {!showTerrainModelSelector ? (
                                                            <Button
                                                                onClick={() => {
                                                                    setShowTerrainModelSelector(
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    Sentences
                                                                        .terrainModelAdd
                                                                        .en
                                                                }
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    setShowTerrainModelSelector(
                                                                        false,
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    Sentences
                                                                        .close
                                                                        .en
                                                                }
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                                {showTerrainModelSelector && (
                                                    <TerrainModelTableSelector
                                                        onTerrainModelSelected={(
                                                            terrainModel,
                                                        ) => {
                                                            setShowTerrainModelSelector(
                                                                false,
                                                            );
                                                            createTerrainModelLink(
                                                                {
                                                                    terrainId,
                                                                    terrainModelId:
                                                                        terrainModel.id,
                                                                },
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Container>
                                        )}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Container>
                    <Can
                        I={Action.Delete}
                        this={subject(Subjects.Terrains, { ...terrain })}
                    >
                        <DeleteButtonWithConfirm
                            onClick={performDeleteTerrain}
                            message={Sentences.terrainDeleteAreYouSure.en}
                        >
                            {Sentences.terrainDelete.en}
                        </DeleteButtonWithConfirm>
                    </Can>
                </>
            )}
        </>
    );
}
