import { FC, useState } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { Confirm, ConfirmProps } from "./Confirm";

export type ConfirmButtonProps = ButtonProps &
    Pick<ConfirmProps, "message"> & {
        onClick?: () => void;
    };

export const ConfimButton: FC<ConfirmButtonProps> = ({
    onClick,
    message,
    children,
    ...props
}) => {
    const [confirm, setConfirm] = useState(false);
    return (
        <>
            <Confirm
                showConfirmation={confirm}
                onPerform={() => {
                    if (onClick) onClick();
                    setConfirm(false);
                }}
                onCancel={() => setConfirm(false)}
                message={message}
            />
            <Button onClick={() => setConfirm(true)} {...props}>
                {children}
            </Button>
        </>
    );
};
