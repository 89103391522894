import { Action } from "@shared/auth/src";
import { useContext } from "react";
import { Container } from "react-bootstrap";
import { AbilityContext } from "src/casl/Can";
import ErrorBar from "src/components/ErrorBar";
import Sessions from "./Sessions";

export default function SessionsPage() {
    const ability = useContext(AbilityContext);

    return (
        <>
            <Container>
                {ability.can(Action.Read, "CourseSession") ? (
                    <Sessions />
                ) : (
                    <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator." />
                )}
            </Container>
        </>
    );
}
