import { FC } from "react";
import { useChunkUploader } from "src/features/uploader/ChunkUploaderService";

export const UploaderMonitor: FC<any> = () => {
    const { uploadQueue } = useChunkUploader();
    return (
        <>
            {uploadQueue.length > 0 && (
                <div>Uploading: {uploadQueue.length}</div>
            )}
        </>
    );
};
