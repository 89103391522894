import { api as generatedApi } from "./generated.api";

export const SlideApi = generatedApi.enhanceEndpoints({
    addTagTypes: ["Slide", "SlideSummary", "SlideMedias"],
    endpoints: {
        getAllSlides: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Slide" as const,
                              id,
                          })),
                          "Slide",
                      ]
                    : [],
        },
        getSlide: {
            providesTags: (result, error, arg) =>
                result
                    ? [{ type: "Slide" as const, id: result.id }, "Slide"]
                    : [],
        },
        slidesControllerCopySlide: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          { type: "Slide" as const, id: arg.slideId },
                          { type: "SlideSummary" as const, id: arg.slideId },
                          "Slide",
                      ]
                    : [];
        },
        deleteSlide: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result
                    ? [
                          { type: "Slide" as const, id: result.id },
                          { type: "SlideSummary" as const, id: result.id },
                          "Slide",
                      ]
                    : [];
        },
        updateSlide: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result
                    ? [
                          { type: "Slide" as const, id: result.id },
                          { type: "SlideSummary" as const, id: result.id },
                          "Slide",
                      ]
                    : [];
        },
        slidesControllerLinkTerrain: {
            invalidatesTags: (result, error, args) => {
                return [{ type: "Slide" as const, id: args.slideId }, "Slide"];
            },
        },
        unlinkTerrainFromSlide: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => {
                return [{ type: "Slide" as const, id: args.slideId }, "Slide"];
            };
        },
        slidesFilesControllerUploadGeoJsonToSlide: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => {
                return [{ type: "Slide" as const, id: args.slideId }, "Slide"];
            };
        },
        slidesControllerDeleteGeofeature: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => {
                return [{ type: "Slide" as const, id: args.slideId }, "Slide"];
            };
        },
        slidesControllerClearGeofeature: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => {
                return [{ type: "Slide" as const, id: args.slideId }, "Slide"];
            };
        },
        slidesFilesControllerGetAllMedia: {
            providesTags: (result, error, args) =>
                result
                    ? [{ type: "SlideMedias" as const, id: args.slideId }]
                    : [],
        },
        uploadFileToSlide: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => {
                return [{ type: "SlideMedias" as const, id: args.slideId }];
            };
        },
        deleteFileFromSlide: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => {
                return [{ type: "SlideMedias" as const, id: args.slideId }];
            };
        },
    },
});

export const {
    useGetSlideQuery,
    useGetAllSlidesQuery,
    useSlidesControllerCopySlideMutation: useCreateSlideCopyMutation,
    useDeleteSlideMutation,
    useUpdateSlideMutation,
    useUnlinkTerrainFromSlideMutation,
    useSlidesFilesControllerUploadGeoJsonToSlideMutation,
    useUploadFileToSlideMutation,
    useDeleteFileFromSlideMutation,
    useSlidesFilesControllerGetAllMediaQuery,
    useSlidesControllerDeleteGeofeatureMutation,
    useSlidesControllerClearGeofeatureMutation,
    useSlidesControllerLinkTerrainMutation: useSlideLinkTerrain,
} = SlideApi;
