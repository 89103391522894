import { FC } from "react";
import { GeoFeature } from "src/api/generated.api";
import { GeoObject } from "../terrains/measurements/GeoObject";
import { BigListPaginated } from "../../components/tables/BigListPaginated";
import { DeleteButton } from "src/components/DeleteButton";

export type GeoFeatureTableProps = {
    geofeatures: GeoFeature[];
    onDeleteGeoJsonClicked?: (id: GeoFeature["id"]) => void;
    loading?: boolean;
};

export const GeoFeaturesTable: FC<GeoFeatureTableProps> = ({
    geofeatures,
    loading,
    onDeleteGeoJsonClicked,
}) => {
    return (
        <BigListPaginated<GeoFeature>
            columns={[
                {
                    Header: "Type",
                    width: undefined,
                    Cell: ({
                        row,
                    }: {
                        row: {
                            original: GeoFeature;
                        };
                    }) => (
                        <GeoObject geojson={JSON.parse(row.original.content)} />
                    ),
                },
                {
                    Header: "Id",
                    width: undefined,
                    accessor: "id",
                },
                {
                    Header: "Action",
                    width: 125,
                    Cell: ({
                        row,
                    }: {
                        row: {
                            original: GeoFeature;
                        };
                    }) => (
                        <>
                            {onDeleteGeoJsonClicked && (
                                <DeleteButton
                                    onClick={() =>
                                        onDeleteGeoJsonClicked(row.original.id)
                                    }
                                />
                            )}
                        </>
                    ),
                },
            ]}
            data={geofeatures}
            loading={loading || false}
        />
    );
};
