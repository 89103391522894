import { FC } from "react";
import { User } from "src/api/generated.api";

import { FieldHookConfigWithLabel } from "src/components/interfaces/FkInterfaces";
import { SearchUsersAutocomplete } from "./SearchUsersAutocomplete";
import { useField } from "formik";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export type FkSearchUsersAutocompleteProps = FieldHookConfigWithLabel<any> & {
    fieldIsId: boolean;
    labelColWidth?: any;
};

export const FkSearchUsersAutocomplete: FC<FkSearchUsersAutocompleteProps> = ({
    label,
    fieldIsId,
    labelColWidth,
    ...props
}) => {
    const [field, meta, helpers] = useField({ ...props });
    const onChange = async (selectedUsers: User[]) => {
        const val = fieldIsId
            ? selectedUsers.map(({ id }) => id)
            : selectedUsers;
        if (selectedUsers.length > 0) {
            await helpers.setValue(field.multiple ? val : val[0], true);
        } else {
            await helpers.setValue(field.multiple ? [] : undefined, true);
        }
        await helpers.setTouched(true, true);
    };
    let labelWidth = labelColWidth ? labelColWidth : 'auto';
    return (

        <Form.Group as={Row} controlId={field.name} className={props.className}>
            {label && <Form.Label column sm={labelWidth}>{label}</Form.Label>}
            <Col>
                <SearchUsersAutocomplete onChange={onChange} />
                {meta.touched && meta.error ? (
                    <div>{meta.error}</div>
                ) : meta.initialError && meta.initialValue === field.value ? (
                    <div>{meta.initialError}</div>
                ) : null}
            </Col>
        </Form.Group>
    );
};
