import { FC, useMemo } from "react";
import { FileUploadStatus, useChunkUploader } from "./ChunkUploaderService";
import { ProgressBar } from "react-bootstrap";

export type UploaderProgressBarProps = {
    uploadId: string;
}

export const UploaderProgressBar: FC<UploaderProgressBarProps> = ({uploadId})=> {
    
    const {uploadQueue} =useChunkUploader();

    const task = useMemo(()=>uploadQueue.find(({uploadId: fid}) => fid === uploadId), [uploadId, uploadQueue]);

    return (<>{task !== undefined
        ? <ProgressBar
            animated={task.status === FileUploadStatus.RUNNING}
            variant={
                task.status === FileUploadStatus.ERROR
                    ? "warning"
                    : "info"
            }
            label={
                <div style={{ minWidth: 30 }}>{`${Math.round(
                    100 * task.progress,
                )}%${
                    task.status !== FileUploadStatus.RUNNING
                        ? " - " + task.status
                        : ""
                }`}</div>
            }
            now={100 * task.progress}
            />
        : <div>Upload task not found in queue</div>
    }
    </>)
}