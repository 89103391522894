import { FC, ReactNode, createContext, useEffect, useState } from "react";
import { Team } from "src/api/generated.api";
import { useMyTeamsControllerFindMineQuery } from "src/api/team.api";

export type TeamContext = {
    currentTeam?: Team;
    setCurrentTeam: (team: Team) => void;
};

export const teamContext = createContext<TeamContext>({
    setCurrentTeam: () => { },
});

export type TeamContextProviderProps = {
    children: ReactNode;
};
export const TeamContextProvider: FC<TeamContextProviderProps> = ({
    children,
}) => {
    const { data } = useMyTeamsControllerFindMineQuery({});

    const [currentTeam, setCurrentTeam] = useState<Team>();

    useEffect(() => {
        setCurrentTeam((old) => {

            const team_user_pref = localStorage.getItem('CurrentTeam');
            if (team_user_pref) {
                try {
                    return JSON.parse(team_user_pref);
                }
                catch (error) {
                }
            }

            if (data && data.total > 0) {
                if (!data.results.find(({ teamId }) => teamId === old?.id)) {
                    return {
                        ...data.results[0],
                        id: data.results[0].teamId,
                    } as any;
                } else {
                    return old;
                }
            }
            return;
        });
    }, [data, setCurrentTeam]);

    return (
        <teamContext.Provider value={{ currentTeam, setCurrentTeam }}>
            {children}
        </teamContext.Provider>
    );
};
