/**
 * It's important for casl to keep the "manage" keep word low cap
 */
export enum Action {
  Manage = "manage",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
  Share = "share",
  List = "list",
  List_All = "list_all",
}

export const M = Action.Manage;
export const C = Action.Create;
export const R = Action.Read;
export const U = Action.Update;
export const S = Action.Share;
export const D = Action.Delete;
export const L = Action.List;

export enum Subjects {
  All = "all",
  Users = "User",
  UserList = "UserList",
  Terrains = "Terrain",
  TerrainModel = "TerrainModel",
  TerrainLayer = "TerrainLayer",
  Activities = "Activity",
  Slide = "Slide",
  CourseSessions = "CourseSession",
  ArtifactRelease = "ArtifactRelease",
  Team = "Team",
  TeamMembership = "Membership",
  TeamRole = "TeamRole",
  TeamForUser = "TeamSummaryForUserDto",
  AccessControl = "AccessControl",
  TeamSummaryForUserDto = "TeamSummaryForUserDto",
}
