import { useEffect, useState, useContext, FC } from "react";
import Button from "react-bootstrap/Button";

import { Col, Container, Form, Row } from "react-bootstrap";
import { Can } from "../../casl/Can";
import TableWithPagination from "../../components/tables/TableWithPagination";

import { Action, Subjects } from "src/api/Permissions";
import {
    useFindAllUsersPaginatedQuery,
    useCreateUserMutation,
    useRemoveUserMutation,
} from "src/api/UserApi";
import { CreateUserDto, User } from "src/api/generated.api";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikHelpers } from "formik";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { subject } from "@casl/ability";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import RoutesDef from "src/routes/RoutesDef";
import moment from "moment";
import { DeleteIcon } from "src/components/DeleteButton";
import ConfirmDelete from "src/components/ConfirmDelete";

export default function AllUsersPage() {
    const { Sentences } = useContext(LangContext);

    const [filter, setFilter] = useState("");
    const [sort, setSort] = useState("");

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(0);

    const usersQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort
    }

    const {
        data: pageOfUsers,
        isLoading: loadingUsers,
        error: getError,
        isError: getIsError,
    } = useFindAllUsersPaginatedQuery(usersQueryProps);

    const [createUser, { isError: deleteIsError, error: deleteError }] =
        useCreateUserMutation();
    const [removeUser, { isError: createIsError, error: createError }] =
        useRemoveUserMutation();

    const [errorMsg, setErrorMsg] = useState("");
    const navigate = useNavigate();


    const [userToDelete, setUserToDelete] = useState<
        User | undefined
    >();

    const performDeleteUser = async () => {
        if (userToDelete) {
            const { id } = userToDelete;
            setUserToDelete(undefined);
            try {
                await removeUser({ id }).unwrap();
            } catch (error) {
                setErrorMsg("failed to delete user");
            }
        }
    };

    const columns = [
        {
            Header: "Username",
            width: undefined,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>{row.original.username}</div>
            ),
        },
        {
            Header: "Email",
            width: undefined,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>{row.original.email}</div>
            ),
        },
        {
            Header: "Name",
            width: undefined,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>{row.original.firstName} {row.original.lastName}</div>
            ),
        },
        {
            Header: "Role",
            width: 120,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>{row.original.role}</div>
            ),
        },
        {
            Header: "Last activity",
            width: 140,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>{row.original.lastActivityDate ? moment(row.original.lastActivityDate).format('YYYY-MM-DD HH:mm:ss UTC Z') : ''}</div>
            ),
        },
        {
            Header: "Created at",
            width: 125,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Actions",
            width: 125,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>
                    <Can
                        I={Action.Update}
                        a={Subjects.Users}
                    >
                        <Link to={"/users/" + row.original.id}>
                            <Button variant="secondary">
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </Link>
                    </Can>
                    <Can
                        I={Action.Delete}
                        a={Subjects.Users}
                    >
                        <Button variant="secondary ml-2"

                            id={`deleteUser${row.original.id}`}

                            onClick={() => setUserToDelete(row.original)}

                        ><FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Can>
                </div>
            ),
        },
    ];

    const createUserSchema = Yup.object({
        username: Yup.string()
            .min(3, "too short!")
            .max(320, "too long!")
            .matches(RegExp("^[a-z0-9\\-_.+@]+$"), {
                excludeEmptyString: true,
                message:
                    "Can only contain a-z, 0-9 hyphens (-), dots (.) and underscores (_). No space.",
            })
            .required("Required !"),
        role: Yup.string(),
    });

    const createUserInitialValues: CreateUserDto = {
        username: "",
        role: "STANDARD",
    };



    const onCreate = async (
        values: CreateUserDto,
        formikBag: FormikHelpers<CreateUserDto>,
    ) => {
        try {
            const user = await createUser({ createUserDto: values }).unwrap();
            navigate(RoutesDef.USERS + `/${user.id}`);
        } catch (e) {
            try {
                const errors = convertApiErrorsToFormikErrors(e);
                formikBag.setErrors(errors);
            } catch (newErr) {
                setErrorMsg(JSON.stringify(newErr));
            }
        }
    };

    useEffect(() => {
        const isErr = getIsError || deleteIsError || createIsError;
        const err = [getError, deleteError, createError].filter(
            (v) => v !== undefined,
        );
        setErrorMsg(isErr ? JSON.stringify(err) : "");
    }, [
        getIsError,
        deleteIsError,
        createIsError,
        getError,
        deleteError,
        createError,
    ]);

    return (
        <>
            <Can not I={Action.Read} a={Subjects.Users}>
                <Navigate replace to="/" />
            </Can>
            <Container>
                <ConfirmDelete
                    showConfirmation={userToDelete !== undefined}
                    onCancelDelete={() => setUserToDelete(undefined)}
                    onPerformDelete={performDeleteUser}
                />
                <ErrorBar errorMessage={errorMsg} />
                <Row className="section mb-3">
                    <Col>
                        <h1>Users</h1>
                        <div className="subtitle">{Sentences.userEditSubtitle.en}</div>
                    </Col>
                    <Col className="text-right">
                        <Link to={RoutesDef.USERS_CREATE} className="btn-primary btn">
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            New
                        </Link>
                    </Col>
                </Row>


                <div>
                    <Can I={Action.Read} a={Subjects.Users}>
                        <TableWithPagination
                            fetchData={(
                                pageIndexToFetch: number,
                                pageSizeToFetch: number,
                                filterToFetch: string,
                                sortToFetch: string
                            ) => {
                                setPageIndex(pageIndexToFetch);
                                setPageSize(pageSizeToFetch);
                                setFilter(filterToFetch);
                                setSort(sortToFetch);
                            }}
                            loading={loadingUsers}
                            columns={columns}
                            data={pageOfUsers?.results ?? []}
                            itemCount={pageOfUsers?.total ?? 0}
                            enableFiltering={true}
                            filteringProp={'username'}
                            sortableProps={[
                                { name: 'Username', accessor: 'username' },
                                { name: 'Name', accessor: 'firstName' },
                                { name: 'Email', accessor: 'email' },
                                { name: 'Role', accessor: 'role' },
                                { name: 'Created at', accessor: 'createdAt' },
                                { name: 'Last activity', accessor: 'lastActivityDate' }
                            ]}
                        />
                    </Can>
                </div>
            </Container>
        </>

    );
};
