import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectAuthStatus } from "src/features/auth/authSlice";
import RoutesDef from "src/routes/RoutesDef";

interface Props {
    clientMode?: Boolean;
}

const PrivateRoutes = ({ clientMode }: Props) => {
    const authStatus = useSelector(selectAuthStatus);
    return authStatus === 'LOGGED_IN' ? <Outlet /> : <Navigate replace to={clientMode ? RoutesDef.UNITY_LOGIN : RoutesDef.LOGIN} />;
};

export default PrivateRoutes

