import { FC, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
    ArtifactRelease,
    PackageVersionSummaryDto,
    useDeleteRelease,
    useGetPaginatedReleases,
    useUpdateRelease,
} from "src/api/BinaryArtifactApi";
import { PageQuery } from "src/types/common-props";
import { AddBinaryArtifactRelease } from "./add-binary-artifact-release";
import { DeleteButton } from "src/components/DeleteButton";
import { ObjectNames, Sentences } from "src/lang/lang";
import { keepColumns } from "./binary-artifact-version-table";
import { faDownload, faRss } from "@fortawesome/free-solid-svg-icons";
import { IconWithTooltip } from "../../components/IconWithTooltip";
import { PackageVersionSelector } from "./binary-artifact-version-table-selector";
import TableSimple from "src/components/tables/TableSimple";
import { Column } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "src/casl/Can";
import { Action, Subjects } from "src/api/Permissions";

export const EditReleases: FC<any> = () => {
    const [pageQuery, setPageQuery] = useState<PageQuery>({
        limit: 10,
        offset: 0,
    });

    const {
        data: releasePage,
        isLoading,
        isFetching,
    } = useGetPaginatedReleases(pageQuery);

    const [showCreateRelease, setShowCreateRelease] = useState(false);
    const onNewRelease = () => {
        setShowCreateRelease((old) => !old);
    };

    return (
        <>
            <Container className="section">
                <Row>
                    <Col>
                        <h1>{ObjectNames.binaryArtifactsRelease.en}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="subtitle">
                            {Sentences.binaryArtifactsReleaseEditSubtitle.en}
                        </div>
                    </Col>
                    <Can I={Action.Create} a={Subjects.ArtifactRelease}>
                        <Col md="auto">
                            <Button
                                onClick={() => setShowCreateRelease((o) => !o)}
                            >
                                {showCreateRelease
                                    ? "Cancel"
                                    : "Create new release"}
                            </Button>
                        </Col>
                    </Can>
                </Row>
            </Container>
            <Container className="section">
                <Can I={Action.Create} a={Subjects.ArtifactRelease}>
                    {showCreateRelease && (
                        <Row>
                            <Col>
                                <AddBinaryArtifactRelease
                                    onSaveId={onNewRelease}
                                />
                            </Col>
                        </Row>
                    )}
                </Can>
                {releasePage ? (
                    <Row>
                        <Col>
                            {releasePage.results.map((release, index) => (
                                <>
                                    <ReleaseView
                                        key={`release-${release.id}`}
                                        release={release}
                                    />
                                    {index + 1 !==
                                        releasePage.results.length && <hr />}
                                </>
                            ))}{" "}
                            {releasePage.results.length === 0 && (
                                <div>No registred releases</div>
                            )}
                        </Col>
                    </Row>
                ) : (
                    <div>"loading releases"</div>
                )}
            </Container>
        </>
    );
};

type ReleaseViewProps = {
    release: ArtifactRelease;
};
const ReleaseView: FC<ReleaseViewProps> = ({ release }) => {
    const [showSelector, setShowSelector] = useState(false);

    const [updateRelease] = useUpdateRelease();
    const [deleteRelease] = useDeleteRelease();

    const ab = useAbility(AbilityContext);
    const columns: Column<PackageVersionSummaryDto>[] = [
        ...keepColumns(["namespace", "packageName", "version", "revision"]),
        {
            Header: "File",
            Cell: ({
                row,
            }: {
                row: { original: PackageVersionSummaryDto };
            }) => (
                <>
                    {row.original.packageName}-msi&nbsp;
                    <a
                        href={`/api/bin-client/r/${release.id}/download/${row.original.packageName}-msi`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button className="d-inline">
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </a>
                </>
            ),
        },
    ];

    return (
        <>
            <Row className="mb-2">
                <Col>
                    <h3>Release</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    name: {release.name} / channel: {release.channel}
                </Col>
                <Col md="auto">
                    <a
                        type="application/rss+xml"
                        href={`/api/bin-client/rr?name=${release.name}&channel=${release.channel}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button className="d-inline">
                            <IconWithTooltip
                                icon={faRss}
                                id="rsstt"
                                tooltipText="Get Sparkleapp Rss"
                            />
                        </Button>
                    </a>
                </Col>
                <Can I={Action.Delete} a={Subjects.ArtifactRelease}>
                    <Col md="auto">
                        <DeleteButton
                            onClick={() =>
                                deleteRelease({ releaseId: release.id })
                            }
                        />
                    </Col>
                </Can>
            </Row>
            <Row>
                <Col>
                    <h4>Artifact</h4>
                    {release.artifact ? (
                        <TableSimple
                            columns={columns}
                            data={[release.artifact]}
                            fetchData={() => {}}
                            loading={false}
                        />
                    ) : (
                        <>No registred artifact</>
                    )}
                </Col>
            </Row>{" "}
            <Can I={Action.Update} a={Subjects.ArtifactRelease}>
                <Row>
                    <Col>
                        <Button
                            onClick={() => {
                                setShowSelector((old) => !old);
                            }}
                        >
                            {showSelector
                                ? "Cancel"
                                : `${
                                      release.artifact ? "Change" : "Select"
                                  } Release Artifact`}
                        </Button>
                    </Col>
                </Row>
                {showSelector && (
                    <Row>
                        <Col>
                            <PackageVersionSelector
                                onPackageVersionSelected={(
                                    packageVersion: PackageVersionSummaryDto,
                                ) => {
                                    setShowSelector(false);
                                    updateRelease({
                                        releaseId: release.id,
                                        updateArtifactReleaseDto: {
                                            channel: release.channel,
                                            name: release.name,
                                            artifact: packageVersion,
                                        },
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </Can>
        </>
    );
};
