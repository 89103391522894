import { FC, ReactPropTypes, useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { TerrainLayers } from "./terrain-layers";

export const TerrainLayersPage = () => {
    const { Sentences } = useContext(LangContext);

    const ability = useContext(AbilityContext);

    return (
        <>
            {ability.can(Action.Read, Subjects.Terrains) ? (
                <TerrainLayers />
            ) : (
                <ErrorBar errorMessage={Sentences.pageNotAllowed.en} />
            )}
        </>
    );
};
