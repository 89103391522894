import { FieldHookConfig, useField } from "formik";
import { FC, useContext } from "react";
import {
    Button,
    Col,
    Container,
    FormGroup,
    FormLabel,
    Nav,
    Row,
    Tab,
} from "react-bootstrap";
import { FkEditVectorObj } from "./FkEditVectorObj";
import SampleMarkdown from "src/features/activities/SampleMarkdown";
import { LangContext } from "src/lang/lang";
import { BootstrapTextArea } from "./BootstrapFormComponents";
import FkMarkdownView from "./FkMarkdownView";

export type FkEditMarkdown = FieldHookConfig<any> & {
    /** optional label for the field */
    label?: string;
};

export const FkEditMarkdown: FC<FkEditMarkdown> = ({
    label,
    disabled,
    ...props
}) => {
    const { Sentences } = useContext(LangContext);
    const [{ name }] = useField({ ...props });
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="edit">
            <Nav variant="tabs" className="mb-3">
                <Nav.Item>
                    <Nav.Link eventKey="edit">{Sentences.edit.en}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="preview">
                        {Sentences.preview.en}
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="edit">
                    <BootstrapTextArea
                        label={Sentences.content.en}
                        name={name}
                        rows={7}
                        placeholder={Sentences.generateSampleContentDesc.en}
                        disabled={disabled}
                    />
                </Tab.Pane>
                <Tab.Pane
                    eventKey="preview"
                    style={{ maxHeight: 600 }}
                    className="overflow-auto"
                >
                    <FkMarkdownView name={name} />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
};
