import { FC } from "react";
import { Badge } from "react-bootstrap";
import { useGetActivityStatsQuery } from "src/api/CourseSessionApi";

export type ActivityStatsProps = {
    activityProgressId: number,
    courseSessionId: number
}

export const ActivityStats: FC<ActivityStatsProps> = ({activityProgressId, courseSessionId})=>{
    const {data: stats, isFetching } = useGetActivityStatsQuery({activityProgressId, courseSessionId})

    return (<div>
                {isFetching && "fetching stats..."}
                {stats && <>
                    <Badge>Total slides: {stats.summary.total}</Badge>
                    <Badge>Vistited: {stats.summary.visited}</Badge>
                    <Badge>Answered: {stats.summary.answered}</Badge>
                    <Badge>With data attached: {stats.summary.withData}</Badge>
                </>}
            </div>)
}