import { FC, MouseEventHandler, useState } from "react";
import {
    TeamSummaryForUserDto,
    useMyTeamsControllerFindMineQuery,
    useTeamControllerFindAllQuery,
    useTeamControllerUpdateMutation,

} from "src/api/team.api";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { faEnvelope, faMailBulk, faPlusCircle, faUserCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../auth/authSlice";
import { BootstrapDropdown, BootstrapTextInput, FkStackedDropdownObj } from "src/components/BootstrapFormComponents";
import { Form as FormikForm, Formik, FieldArray, Field } from "formik";

import { TeamInviteMembersDto, TeamInviteMembersLineDto, useMembershipControllerInviteMembersMutation, useTeamControllerCreateMutation, useTeamRoleControllerGetTeamRolesQuery } from "src/api/generated.api";

import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { FkSimpleTextInput } from "src/components/FkSimpleTextInput";

export type TeamInviteEditorProps = {
    show: boolean;
    handleClose: any;
};

export const TeamCreatorPopin: FC<TeamInviteEditorProps> = ({ show, handleClose }) => {

    const [createTeam] = useTeamControllerCreateMutation();
    const onSubmit = async (values: any, formikHelpers: any) => {
        const { name } = values;
        try {
            const team = await createTeam({ createTeamDto: { name: values.name, storageCapacityLimitByAdmin: values.storageCapacityLimitByAdmin, adminCountLimit: values.adminCountLimit, guestCountLimitByAdmin: values.guestCountLimitByAdmin } }).unwrap();
        } catch (error) { }
        handleClose();
    };

    const initialValues = {};
    const validationSchema = Yup.object().shape({
        inviteMembersLines: Yup.array().of(Yup.object().shape({
            name: Yup.string().required('Required'),
            adminCountLimit: Yup.number().defined(),
            guestCountLimitByAdmin: Yup.number().defined(),
            storageCapacityByAdmin: Yup.number().defined(),
        }))
    });

    return (<>

        <Modal className="inviteMemberModal" show={show} onHide={handleClose} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched }) => (
                    <FormikForm>
                        <Modal.Header closeButton>
                            <Modal.Title>New team</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <Container>
                                <BootstrapTextInput
                                    labelColWidth={4}
                                    label="Name *"
                                    name="name"
                                    placeholder="Name"

                                />
                                <BootstrapTextInput
                                    labelColWidth={4}
                                    label="Admin count limit"
                                    name="adminCountLimit"
                                    placeholder="1"
                                    type="number"

                                />
                                <BootstrapTextInput
                                    labelColWidth={4}
                                    label="Guest count limit by admin"
                                    name="guestCountLimitByAdmin"
                                    placeholder="5"
                                    type="number"

                                />
                                <BootstrapTextInput
                                    labelColWidth={4}
                                    label="Storage capacity by admin (Go)"
                                    name="storageCapacityLimitByAdmin"
                                    placeholder="200"
                                    type="number"

                                />









                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Save team
                            </Button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal>


    </>
    );
};
