import { FieldArray, FieldHookConfig, useField } from 'formik'
import { FC } from 'react'
import { Button, Col, Container, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { BootstrapTextInput } from './BootstrapFormComponents';

export type FkEditKeyValuesProps = FieldHookConfig<any> & {
    /** optional label for the field */
    label? : string,
    disabled?: boolean,
}

export const FkEditKeyValues: FC<FkEditKeyValuesProps> = ({ label, disabled, ...props }) => {
    const [field] = useField({ ...props });
    return (
      <FormGroup>
        {label && <FormLabel>{label}</FormLabel>}
        <FieldArray name={field.name}>
            {({ insert, remove, push, move }) => <div>
                <Container>
                {field.value.map((val: any, index: number) => {
                    return (<Row key={index}>
                            <Col><BootstrapTextInput disabled={disabled} name={`${field.name}[${index}].key`} placeholder="key" label='key'/></Col>
                            <Col><BootstrapTextInput disabled={disabled} name={`${field.name}[${index}].value`} placeholder="value" label='Value'/></Col>
                            {(disabled === undefined || !disabled ) && <Col md="auto"><Button variant="danger" onClick={() => remove(index)}>X</Button></Col>}
                            </Row>
                        );
                })}
                {field.value.length === 0 && "No records to display"} 
                </Container>
                {(disabled === undefined || !disabled ) && <Button onClick={()=>push({key: "", value: ""})}>Add new key / value row</Button>}
            </div>}
        </FieldArray>
        </FormGroup>
    );
};
