import { FC } from "react";
import { Can } from "src/casl/Can";
import { Action, Subjects } from "src/api/Permissions";
import { DocumentTeamEdit, DocumentTeamEditProps } from "./document-team-edit";
import { subject } from "@casl/ability";

export type DocumentTeamEditIfCanProps = DocumentTeamEditProps & {
    documentType: Subjects;
};

export const DocumentTeamEditIfCan: FC<DocumentTeamEditIfCanProps> = ({
    objWithTeam,
    documentType,
    onTeamChanged,
}) => {
    return (
        <Can
            I={Action.Update}
            this={subject(documentType, {
                ...objWithTeam,
            })}
            field="teamId"
        >
            <DocumentTeamEdit
                objWithTeam={objWithTeam}
                onTeamChanged={onTeamChanged}
            />
        </Can>
    );
};
