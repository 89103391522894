import { Formik, Form as FormikForm } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import {
    BootstrapDropdownList,
    BootstrapTextInput,
} from "src/components/BootstrapFormComponents";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { AuthProvider } from "src/api/generated.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TableSimple from "src/components/tables/TableSimple";
import { roles } from "./UserTools";
import { LangContext } from "src/lang/lang";
import axios from "axios";
import { useFindMineUserQuery, useGetMyAuthProvidersQuery, useRemoveMyAuthProviderMutation, useUpdateMineUserMutation } from "src/api/UserApi";
import { AddMyAuthProviderForm } from "./AddMyAuthProvider";
import { AbilityContext } from "src/casl/Can";
import { Action } from "@shared/auth/src";
import { Subjects } from "src/api/Permissions";


export function MyProfileEditor() {
    const { Sentences } = useContext(LangContext);
    const {
        data: user,
        isFetching,
        isError,
        error,
    } = useFindMineUserQuery();

    const { data: authProviders, isFetching: fetchingAuthProviders } =
        useGetMyAuthProvidersQuery();

    const [authMethods, setAuthMethods] = useState({
        useGoogleOauth: false,
        useCas: false,
    });

    const [updateUser] = useUpdateMineUserMutation();

    const [removeAuthProvider] = useRemoveMyAuthProviderMutation();

    const [showAddPasswordForm, setShowAddPasswordForm] = useState(false);
    const [providerType, setProviderType] = useState("");

    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setErrorMsg(isError ? JSON.stringify(error) : "");
    }, [isError, error]);

    const ability = useContext(AbilityContext);

    const onAddPassword = () => {
        setProviderType("local");
        setShowAddPasswordForm(true);
    };

    const onAddCasAccount = () => {
        setProviderType("cas");
        setShowAddPasswordForm(true);
    };

    const onAddGoogleAccount = () => {
        setProviderType("google");
        setShowAddPasswordForm(true);
    };

    const columns = [
        {
            Header: "Authentication provider",
            width: undefined,
            Cell: ({ row }: { row: { original: AuthProvider } }) => (
                <div>{row.original.providerType}</div>
            ),
        },
        {
            Header: "Action",
            width: 125,
            Cell: ({ row }: { row: { original: AuthProvider } }) => (

                <Button
                    variant="outline-dark"
                    className="ml-1"
                    onClick={() =>
                        removeAuthProvider({
                            authProviderId: row.original.id,
                        })
                    }
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>

            ),
        },
    ];

    useEffect(() => {
        axios
            .get("/api/auth/methods")
            .then((value) => setAuthMethods(value.data));
    }, []);

    return (
        <div>
            <ErrorBar errorMessage={errorMsg} />
            {isFetching ? (
                <div>Loading</div>
            ) : (
                <div>
                    {user && (
                        <Formik
                            validationSchema={Yup.object().shape({
                                username: Yup.string()
                                    .min(3, "too short!")
                                    .max(320, "too long!")
                                    .matches(RegExp("^[a-z0-9\\-_.+@]+$"), {
                                        excludeEmptyString: true,
                                        message:
                                            "Can only contain a-z, 0-9 hyphens (-), dots (.) and underscores (_). No space.",
                                    })
                                    .required("Required !"),
                            })}
                            initialValues={user}
                            enableReinitialize
                            onSubmit={async (values, formikBag) => {
                                formikBag.setSubmitting(true);
                                try {
                                    await updateUser({
                                        updateUserDto: {
                                            username: values["username"],
                                            role: values["role"],
                                            email: values["email"] ? values["email"] : null,
                                            firstName: values["firstName"],
                                            lastName: values["lastName"]
                                        },
                                    });
                                } catch (e) {
                                    try {
                                        const errors =
                                            convertApiErrorsToFormikErrors(e);
                                        formikBag.setErrors(errors);
                                    } catch (newErr) {
                                        setErrorMsg(JSON.stringify(newErr));
                                    }
                                }
                            }}
                        >
                            {(formik) => (
                                <>
                                    <FormikForm
                                        onSubmit={formik.handleSubmit}
                                        method="post"
                                    >
                                        <Container className="section">
                                            <Row>
                                                <Col>
                                                    <h1>
                                                        My profile
                                                    </h1>
                                                </Col>
                                                <Col
                                                    md="auto"
                                                    className="align-items-end d-flex"
                                                >

                                                    <Button
                                                        disabled={!formik.dirty}
                                                        type="submit">
                                                        {Sentences.save.en}
                                                    </Button>

                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container>
                                            <div className="section">
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="Username *"
                                                    name="username"
                                                    placeholder="username"
                                                />
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="Email"
                                                    name="email"
                                                    placeholder="email"
                                                />
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="First name"
                                                    name="firstName"
                                                    placeholder="firstname"
                                                />
                                                <BootstrapTextInput
                                                    labelColWidth={2}
                                                    label="Last name"
                                                    name="lastName"
                                                    placeholder="lastname"
                                                />
                                                <Row><Col sm="2">
                                                    Role
                                                </Col><Col>{user.role}</Col></Row>
                                            </div>
                                        </Container>
                                    </FormikForm>
                                </>
                            )}
                        </Formik>
                    )}
                </div>
            )}
        </div>
    );
}
