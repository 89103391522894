import React, {useEffect, useState} from 'react';
import {io} from 'socket.io-client';

export function WebSocketTest() {
  const [response, setResponse] =useState("");
  const [token, setToken] =useState("");

  useEffect(()=>{
    const socket = io("http://localhost:3000");
    
    socket.on("connId", data => {
      setResponse(data);
    });

    socket.on("token", data => {
      setToken(data);
    });
    return () => {socket.disconnect()};
  }, []);
  return (<div>
    <p><time dateTime={response}>{response}</time></p>
    <p><time dateTime={token}>{token}</time></p>
  </div>)
};
