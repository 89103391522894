import { User, api as generatedApi } from "./generated.api";

export const UserApi = generatedApi.enhanceEndpoints({
    addTagTypes: ["User", "AuthProvider"],
    endpoints: {
        findAllUsers: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        ...result.map(({ id }) => ({
                            type: "User" as const,
                            id,
                        })),
                        "User",
                    ]
                    : ["User"],
        },
        findAllUsersPaginated: {
            providesTags: (paginatedResult, error, arg) =>
                paginatedResult
                    ? [
                        ...paginatedResult.results.map(({ id }) => ({
                            type: "User" as const,
                            id,
                        })),
                        "User",
                    ]
                    : ["User"],
        },
        findOneUser: {
            providesTags: (result, error, arg) =>
                result ? [{ type: "User" as const, id: result.id }] : [],
        },
        createUser: (endpoint) => {
            endpoint.invalidatesTags = (result) => [{ type: "User" } as const];
        },
        updateUser: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "User" as const, id: arg.id },
            ];
        },
        addLocalPassword: (endpoint) => {
            endpoint.invalidatesTags = (result, arg) =>
                arg
                    ? [{ type: "AuthProvider" as const }]
                    : [{ type: "AuthProvider" as const }];
        },
        addSocialAccount: (endpoint) => {
            endpoint.invalidatesTags = (result, arg) =>
                arg
                    ? [{ type: "AuthProvider" as const }]
                    : [{ type: "AuthProvider" as const }];
        },
        getUserAuthProviders: {
            providesTags: (result, error, arg) =>
                result ? [{ type: "AuthProvider" as const, id: arg.id }] : [],
        },
        removeAuthProvider: (endpoint) => {
            endpoint.invalidatesTags = (result, arg) => [
                { type: "AuthProvider" as const },
            ];
        },
        removeUser: (endpoint) => {
            endpoint.invalidatesTags = (result) => [{ type: "User" } as const];
        },


        findMineUser: {
            providesTags: (result, error, arg) =>
                result ? [{ type: "User" as const }] : [],
        },
        updateMineUser: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "User" as const },
            ];
        },

        addMyLocalPassword: (endpoint) => {
            endpoint.invalidatesTags = (result, arg) =>
                arg
                    ? [{ type: "AuthProvider" as const }]
                    : [{ type: "AuthProvider" as const }];
        },
        addMySocialAccount: (endpoint) => {
            endpoint.invalidatesTags = (result, arg) =>
                arg
                    ? [{ type: "AuthProvider" as const }]
                    : [{ type: "AuthProvider" as const }];
        },

        getMyAuthProviders: {
            providesTags: (result, error, arg) =>
                result ? [{ type: "AuthProvider" as const }] : [],
        },
        removeMyAuthProvider: (endpoint) => {
            endpoint.invalidatesTags = (result, arg) => [
                { type: "AuthProvider" as const },
            ];
        },

    },
});

export type Role = User["role"];

export const {
    useFindAllUsersQuery,
    useFindAllUsersPaginatedQuery,
    useFindOneUserQuery,
    useCreateUserMutation,
    useRemoveUserMutation,
    useAddLocalPasswordMutation,
    useAddSocialAccountMutation,
    useGetUserAuthProvidersQuery,
    useRemoveAuthProviderMutation,
    useUpdateUserMutation,

    useUpdateMineUserMutation,
    useFindMineUserQuery,
    useGetMyAuthProvidersQuery,
    useAddMyLocalPasswordMutation,
    useAddMySocialAccountMutation,
    useRemoveMyAuthProviderMutation
} = UserApi;
