import { Formik } from "formik";
import { FC } from "react"
import { Button, Form } from "react-bootstrap";
import { useCreateJobMutation, useGetJobQuery, useUpdateJobMutation } from "src/api/JobApi"
import { useStartJobMutation } from "src/api/JobApi";
import { FkEditJob } from "./FkEditJob";
import { JobHistory } from "./JobHistory";
import { JobLogs } from "./JobLogs";
import { JobStatus } from "./JobStatus";
import { v4 as uuidv4 } from "uuid";
import { CreateJobDto, UpdateJobDto } from "src/api/generated.api";

export type CreateJobProps = {    
    onJobSaved? : (jobId: string) => void,
}

export const CreateJob : FC<CreateJobProps> = ({onJobSaved}) => {

    const [ createJob ] = useCreateJobMutation();    
    const initialValues = {
        "demFilename": "../mnt_rgealti_org_lag_1m.tif",
        "textureFilename": "../ortho_orgon_lagnes_1m_L93_decoup.tif",
        "outDir": "../tiles",
        "demTileSize": 256,
        "textureTileSize": 256
    }

    async function onFormikSubmit(values: any, formikBag: any) {
        try {
            const dto: CreateJobDto = {
                id: uuidv4(), 
                ...values
            }
            console.log(dto);
            const job = await createJob({createJobDto: dto}).unwrap();
            if (onJobSaved) {
                onJobSaved(job.id);
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (<div>
        <Formik initialValues={initialValues} onSubmit={onFormikSubmit}>
        {formik => (<>
            <Form 
                onSubmit={formik.handleSubmit} 
                onChange={formik.handleChange}
                method="post"                
            >
                <FkEditJob name=""></FkEditJob>
                <Button type="submit">Save</Button>
            </Form>
            </>            
        )}
        </Formik>
    </div>)
}

export type EditJobProps = CreateJobProps & {    
    jobId: string
}

export const EditJob : FC<EditJobProps> = ({jobId, onJobSaved}) => {

    const [ updateJob ] = useUpdateJobMutation();
    const { data, isFetching } = useGetJobQuery({id: jobId}, {refetchOnMountOrArgChange:true});    
    const [ startJob ] = useStartJobMutation();
    async function onFormikSubmit(values: any, formikBag: any) {
        try {
            const job = await updateJob({updateJobDto: values, id: jobId}).unwrap();
            if (onJobSaved) {
                onJobSaved(job.id);
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (<div>
        {data && <>
            <Formik initialValues={data.args} onSubmit={onFormikSubmit} enableReinitialize={true}>
            {formik => (<>
                <Form 
                    onSubmit={formik.handleSubmit} 
                    onChange={formik.handleChange}
                    method="post"                
                >
                    <FkEditJob name=""></FkEditJob>
                    <Button type="submit">Save</Button>
                </Form>
                </>            
            )}
            </Formik>
            <Button onClick={()=> startJob({jobId: data.id})}>Start</Button>    
            <JobStatus jobId={data.id}/>    
            <JobHistory jobId={data.id}/>
            <JobLogs jobId={data.id}/>    
            </>

            
        }
    </div>)
}
