import { subject } from "@casl/ability";
import { Form as FormikForm, Formik, FormikHelpers } from "formik";
import { FC, useCallback, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Subjects, U } from "src/api/Permissions";
import {
    Membership,
    TeamRole,
    TeamSummaryForUserDto,
    useMembershipControllerUpdateMemberMutation,
    useMyTeamsControllerFindMineQuery,
    useMyTeamsControllerFindOneWithUserMembershipQuery,
    useTeamRoleControllerGetTeamRolesQuery,
} from "src/api/generated.api";
import { AbilityContext } from "src/casl/Can";
import { FkStackedDropdownObj } from "src/components/BootstrapFormComponents";
import { SaveButton } from "src/components/SaveButton";
import Select, { Options, StylesConfig, components } from "react-select";
import { SelectRolesColourStyles, SelectRolesOption } from "../team-invite";

export type TeamMembershipEditorProps = {
    teamMembership: Membership;
    enableAdminInvitation: boolean;
};

export const TeamMembershipEditor: FC<TeamMembershipEditorProps> = ({
    teamMembership,
    enableAdminInvitation
}) => {
    const { data: roles } = useTeamRoleControllerGetTeamRolesQuery({
        teamId: teamMembership.teamId,
    });

    const { data: team } = useMyTeamsControllerFindOneWithUserMembershipQuery({
        teamId: teamMembership.teamId,
    });
    const ability = useContext(AbilityContext);
    const canUpdate = useCallback(
        () => team && ability.can(U, team, "members"),
        [ability, team],
    );

    const [updateMembership] = useMembershipControllerUpdateMemberMutation();

    const onSubmit = async (
        values: any,
        formikHelpers: FormikHelpers<Membership>,
    ) => {
        const { teamId, userId } = teamMembership;
        await updateMembership({
            teamId,
            userId,
            updateTeamMembershipDto: { role: values.role.value },
        }).unwrap();
    };

    const formatRoles = (roles: TeamRole[]) => {
        return roles.map((r) => ({ value: r, label: r.name, description: r.description }))
    }

    return (
        <>
            <Formik
                initialValues={teamMembership}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ dirty, values, errors, touched, setFieldValue }) => (
                    <FormikForm>
                        <Row>
                            <Col>
                                {(canUpdate() && roles) ? <Select
                                    options={enableAdminInvitation ? formatRoles(roles) : formatRoles(roles?.filter((r) => r.permissions.length == 0))}
                                    styles={SelectRolesColourStyles}
                                    components={{ Option: SelectRolesOption }}
                                    defaultValue={formatRoles([teamMembership.role])[0]}
                                    onChange={(option: any) => setFieldValue(`role`, option)}
                                /> : <span>{formatRoles([teamMembership.role])[0].label}</span>}
                            </Col>
                            {canUpdate() && (
                                <Col md="auto">
                                    <SaveButton
                                        type="submit"
                                        disabled={!dirty}
                                    />
                                </Col>
                            )}
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </>
    );
};
