import { FC, useState } from "react";
import { Column } from "react-table";
import { PackageDto, PackageVersionSummaryDto } from "src/api/generated.api";
import { Button, Dropdown } from "react-bootstrap";
import { OnSelectedId } from "src/types/common-props";
import {
    BinaryArtifactVersionTable,
    keepColumns,
} from "./binary-artifact-version-table";
import { useGetPackages } from "src/api/BinaryArtifactApi";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

export type BinaryArtifactTableSelectorProps = {
    namespace: string;
    packageName: string;
    selectedVersion?: PackageVersionSummaryDto["version"];
} & OnSelectedId<PackageVersionSummaryDto>;

export const BinaryArtifactTableSelector: FC<BinaryArtifactTableSelectorProps> =
    ({ onSelectedId, selectedVersion, ...props }) => {
        const columns: Column<PackageVersionSummaryDto>[] = [
            ...keepColumns(["namespace", "packageName", "version"]),
            {
                Header: "action",
                Cell: ({
                    row,
                }: {
                    row: { original: PackageVersionSummaryDto };
                }) => (
                    <>
                        {selectedVersion === row.original.version ? (
                            <>currently selected</>
                        ) : (
                            <Button
                                onClick={() => {
                                    if (onSelectedId)
                                        onSelectedId(row.original);
                                }}
                            >
                                Select
                            </Button>
                        )}
                    </>
                ),
            },
        ];

        return (
            <BinaryArtifactVersionTable columnOverride={columns} {...props} />
        );
    };

type PackageSelectorProps = {
    onPackageSelected: (packageDto: PackageDto) => void;
};

export const PackageSelector: FC<PackageSelectorProps> = ({
    onPackageSelected,
}) => {
    const { data } = useGetPackages();
    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Choose package
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {(data || []).map((item) => (
                    <Dropdown.Item
                        key={`${item.namespace}.${item.packageName}`}
                        onClick={() => onPackageSelected(item)}
                    >
                        {item.namespace}::{item.packageName}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

type PackageVersionSelectorProps = {
    onPackageVersionSelected: (
        packageVersion: PackageVersionSummaryDto,
    ) => void;
};

export const PackageVersionSelector: FC<PackageVersionSelectorProps> = ({
    onPackageVersionSelected,
}) => {
    const [packageState, setPackageState] = useState<PackageDto | undefined>();
    const onPackageSelected = (packageDto: PackageDto) =>
        setPackageState(packageDto);

    return (
        <>
            <PackageSelector onPackageSelected={onPackageSelected} />
            {packageState && (
                <BinaryArtifactTableSelector
                    namespace={packageState.namespace}
                    packageName={packageState.packageName}
                    onSelectedId={onPackageVersionSelected}
                />
            )}
        </>
    );
};
