import { FC, useState } from "react";
import { Column, Row } from "react-table";
import {
    Membership,
    Team,
    useMembershipControllerGetMembersQuery,
    useMembershipControllerRemoveMemberMutation,
    TeamSummaryForUserDto,
} from "src/api/team.api";
import TableSimple from "src/components/tables/TableSimple";
import { TeamMembershipEditor } from "./edit-team-membership";
import { Can } from "src/casl/Can";
import { C, D, Subjects, U } from "src/api/Permissions";
import { subject } from "@casl/ability";
import { DeleteIcon } from "src/components/DeleteButton";
import ConfirmDelete from "src/components/ConfirmDelete";
import ErrorBar from "src/components/ErrorBar";
import { AddTeamMembership } from "./add-team-membership";
import { Badge, Button } from "react-bootstrap";
import { Role } from "src/api/UserApi";
import { useMembershipControllerInviteMembersMutation } from "src/api/generated.api";

export type TeamMembershipTableProps = {
    team: TeamSummaryForUserDto;
    showAddByUserName: boolean;
};

export const TeamMembershipTable: FC<TeamMembershipTableProps> = ({ team, showAddByUserName }) => {
    const { teamId, adminCountLimit, guestCountLimitByAdmin, storageCapacityLimitByAdmin } = team;

    const { data: members, isLoading } = useMembershipControllerGetMembersQuery(
        { teamId },
    );

    let adminCount: number = 0;
    let guestCount: number = 0;
    let enableAdminInvitation: boolean = false;
    let enableGuestInvitation: boolean = false;

    if (members) {
        adminCount = members.filter((m) => m?.role.permissions.length).length;
        guestCount = members.length - adminCount;
        enableAdminInvitation = adminCount < adminCountLimit;
        enableGuestInvitation = guestCount < guestCountLimitByAdmin;
    }

    const [revokeMembership] = useMembershipControllerRemoveMemberMutation();

    const [memberToRevoke, setMemberToRevoke] = useState<
        Membership | undefined
    >();
    const [errorMessage, setErrorMessage] = useState("");
    const performRevokeMember = async () => {
        if (memberToRevoke) {
            const { teamId, userId } = memberToRevoke;
            setMemberToRevoke(undefined);
            try {
                await revokeMembership({ teamId, userId }).unwrap();
            } catch (error) {
                setErrorMessage("failed to revoke user");
            }
        }
    };

    const [inviteMembers] = useMembershipControllerInviteMembersMutation();
    const [alreadyReInvited, setAlreadyReInvited] = useState<string[]>([]);

    const inviteAgain = async (data: Membership) => {
        try {
            const lines = [{ email: data.user.email, role: data.role }];
            const invite = await inviteMembers({ teamInviteMembersDto: { inviteMembersLines: lines }, teamId: team.teamId }).unwrap();
            setAlreadyReInvited([...alreadyReInvited, data.user.email])
        } catch (error) { }
    }

    const columns: Column<Membership>[] = [
        {
            Header: "Username",
            Cell: ({ row }: { row: Row<Membership> }) => (
                <>{row.original.user.username}</>
            ),
            width: "auto",
        },
        {
            Header: "Activated",
            width: "auto",
            Cell: ({ row }: { row: Row<Membership> }) => (
                <>
                    {row.original.user.lastActivityDate ? <Badge variant="success">✓</Badge> : (row.original.user.email && !alreadyReInvited.includes(row.original.user.email) ? <Button onClick={() => { inviteAgain(row.original) }} variant="secondary" style={{ fontSize: 12 }}>Invite again</Button> : <></>)}
                </>
            ),
        },
        {
            Header: "Role",
            width: "auto",
            Cell: ({ row }: { row: Row<Membership> }) => (
                <>
                    <TeamMembershipEditor enableAdminInvitation={row.original.role?.permissions.length > 0 || enableAdminInvitation} teamMembership={row.original} />
                </>
            ),
        },

        {
            Header: "Action",
            width: "60px",
            Cell: ({ row }: { row: Row<Membership> }) => (
                <>
                    <Can I={U} this={team} field="members">
                        <DeleteIcon
                            id={`revokeUser${row.original.id}`}
                            tooltipText={`Revoke membership of ${row.original.user.username}`}
                            onClick={() => setMemberToRevoke(row.original)}
                        />
                    </Can>
                </>
            ),
        },
    ];
    return (
        <>
            <ConfirmDelete
                showConfirmation={memberToRevoke !== undefined}
                onCancelDelete={() => setMemberToRevoke(undefined)}
                onPerformDelete={performRevokeMember}
            />
            <ErrorBar
                errorMessage={errorMessage}
                onDismiss={() => setErrorMessage("")}
            />
            {showAddByUserName &&
                <AddTeamMembership teamId={teamId} />
            }
            <TableSimple
                columns={columns}
                data={members || []}
                fetchData={() => { }}
                loading={isLoading}
            />
        </>
    );
};
