import React, { FC, useState } from "react";
import { User } from "src/api/generated.api";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useFindAllUsersPaginatedQuery } from "src/api/UserApi";
import Typeahead from "react-bootstrap-typeahead/types/core/Typeahead";
export type SearchUsersAutocompleteProps = React.RefAttributes<Typeahead> & {
    onChange: (selectedUsers: User[]) => void;
};

export const SearchUsersAutocomplete: FC<SearchUsersAutocompleteProps> = ({
    onChange,
    ...props
}) => {
    const [query, setQuery] = useState({ limit: 5, offset: 0, filter: "" });
    const { data, isLoading } = useFindAllUsersPaginatedQuery(query);
    return (
        <>
            <AsyncTypeahead
                options={data?.results || []}
                labelKey="username"
                isLoading={isLoading}
                useCache={false}
                onSearch={(query) =>
                    setQuery({ limit: 5, offset: 0, filter: "username:like:" + query })
                }
                promptText="Type username"
                onChange={(opt) => onChange(opt as User[])}
                {...props}
            />
        </>
    );
};
