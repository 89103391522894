import { useField } from "formik";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { FC } from "react";
import { FieldHookConfig } from "formik/dist/Field";

declare type FormControlElement =
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLTextAreaElement;

type BootstrapTextInputProps = FieldHookConfig<any> & {
    label: string;
    placeholder: string;
    labelColWidth?: any;
    onChange?: (evt: React.ChangeEvent<FormControlElement>) => void;
};

export function BootstrapTextInput({
    label,
    placeholder,
    onChange,
    labelColWidth,
    ...props
}: BootstrapTextInputProps) {
    const [field, meta] = useField(props);
    let labelWidth = labelColWidth ? labelColWidth : 'auto'
    return (
        <Form.Group as={Row} controlId={field.name} className={props.className}>
            <Form.Label column sm={labelWidth}>
                {label}
            </Form.Label>
            <Col>
                <Form.Control
                    as="input"
                    type={props.type ? props.type : "text"}
                    name={field.name}
                    onBlur={field.onBlur}
                    disabled={props.disabled}
                    onChange={(evt) => {
                        if (onChange) {
                            onChange(evt);
                        }
                        field.onChange(evt);
                    }}
                    value={field.value}
                    isInvalid={
                        (meta.touched && meta.error !== undefined) ||
                        (meta.initialError !== undefined &&
                            meta.initialValue === field.value)
                    }
                    placeholder={placeholder}
                />
                {meta.touched && meta.error ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                ) : meta.initialError && meta.initialValue === field.value ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.initialError}
                    </Form.Control.Feedback>
                ) : null}
            </Col>
        </Form.Group>
    );
}
type BootstrapTextAreaProps = FieldHookConfig<any> & {
    label: string;
    placeholder: string;
    rows: number;
    labelColWidth?: any;
    onChange?: (evt: React.ChangeEvent<FormControlElement>) => void;
};

export function BootstrapTextArea({
    label,
    placeholder,
    rows,
    onChange,
    disabled,
    labelColWidth,
    ...props
}: BootstrapTextAreaProps) {
    const [field, meta] = useField(props);
    let labelWidth = labelColWidth ? labelColWidth : 'auto'
    return (
        <Form.Group as={Row} controlId={field.name}>
            <Form.Label column sm={labelWidth}>{label}</Form.Label>
            <Col>
                <Form.Control
                    as="textarea"
                    rows={rows}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(evt) => {
                        if (onChange) {
                            onChange(evt);
                        }

                        field.onChange(evt);
                    }}
                    value={field.value}
                    placeholder={placeholder}
                    isInvalid={
                        (meta.touched && meta.error !== undefined) ||
                        (meta.initialError !== undefined &&
                            meta.initialValue === field.value)
                    }
                    disabled={disabled}
                />
            </Col>

            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            ) : meta.initialError && meta.initialValue === field.value ? (
                <Form.Control.Feedback type="invalid">
                    {meta.initialError}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
}
/*
export function BootstrapNumber({ label, placeholder, rows, onChange, ...props }) {
    const [field, meta] = useField(props);
    return (
        <Form.Group controlId={field.name}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as='textarea'
                rows={rows}
                name={field.name}
                onBlur={field.onBlur}
                onChange={ (evt) => {
                    if(onChange) {
                        onChange(evt);
                    }
                    field.onChange(evt);
                }}
                value={field.value}
                placeholder={placeholder}
                isInvalid={(meta.touched && meta.error) || (meta.initialError && meta.initialValue === field.value)}
            />
            {
                (meta.touched && meta.error)
                    ? (<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>)
                    : (meta.initialError && meta.initialValue === field.value)
                    ? (<Form.Control.Feedback type="invalid">{meta.initialError}</Form.Control.Feedback>)
                    : null
            }
        </Form.Group>
    );
};
*/
type BootstrapDropdownProps = FieldHookConfig<any> & {
    label: string;
    items: { id: string | number; name: string }[];
    labelColWidth?: any;
    onChange?: (evt: React.ChangeEvent<FormControlElement>) => void;
};

export function BootstrapDropdown({
    label,
    items,
    onChange,
    labelColWidth,
    ...props
}: BootstrapDropdownProps) {
    const [field, meta] = useField(props);
    let labelWidth = labelColWidth ? labelColWidth : 'auto'
    return (
        <Form.Group as={Row} controlId={field.name}>
            <Form.Label column sm={labelWidth}>
                {label}
            </Form.Label>
            <Col>
                <Form.Control
                    as="select"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(evt) => {
                        if (onChange) {
                            onChange(evt);
                        }
                        field.onChange(evt);
                    }}
                    value={field.value}
                    isInvalid={
                        (meta.touched && meta.error !== undefined) ||
                        (meta.initialError !== undefined &&
                            meta.initialValue === field.value)
                    }
                    disabled={props.disabled}
                >
                    {items.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Form.Control>
                {meta.touched && meta.error ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                ) : meta.initialError && meta.initialValue === field.value ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.initialError}
                    </Form.Control.Feedback>
                ) : null}
            </Col>
        </Form.Group>
    );
}

export type BootstrapDropdownKeysProps = FieldHookConfig<any> & {
    label?: string;
    items: { id: string; name: string }[];
    onChange?: (evt: React.ChangeEvent<FormControlElement>) => void;
    disabled?: boolean;
    valueIsFullObject?: boolean;
    labelColWidth?: any;
};
/**
 * A FORMIK Dropdown that take a list of objects as input and fill the formik value with the selected object ID.
 * Objects selection is based on id. Dropdown display is based on a "name" attribute on object.
 */
export const BootstrapDropdownKeys: FC<BootstrapDropdownKeysProps> = ({
    label,
    items,
    onChange,
    disabled,
    labelColWidth,
    ...props
}) => {
    const [field, meta] = useField(props);
    const labelWidth = labelColWidth ? labelColWidth : 'auto';
    return (
        <Form.Group as={Row} controlId={field.name}>
            {label && (
                <Form.Label column sm={labelWidth}>
                    {label}
                </Form.Label>
            )}
            <Col sm="10">
                <Form.Control
                    as="select"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(evt) => {
                        if (onChange) {
                            onChange(evt);
                        }
                        field.onChange(evt);
                    }}
                    value={field.value}
                    disabled={disabled}
                    isInvalid={
                        (meta.touched && meta.error !== undefined) ||
                        (meta.initialError !== undefined &&
                            meta.initialValue === field.value)
                    }
                >
                    {items.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Form.Control>
                {meta.touched && meta.error ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                ) : meta.initialError && meta.initialValue === field.value ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.initialError}
                    </Form.Control.Feedback>
                ) : null}
            </Col>
        </Form.Group>
    );
};

/**
 * A FORMIK Dropdown that take a list of objects as input and fill the formik value with the selected object ID.
 * Objects selection is based on id. Dropdown display is based on a "name" attribute on object.
 */
export const FkStackedDropdownKeys: FC<BootstrapDropdownKeysProps> = ({
    label,
    items,
    onChange,
    valueIsFullObject,
    labelColWidth,
    ...props
}) => {
    const [field, meta] = useField(props);
    let labelWidth = labelColWidth ? labelColWidth : 'auto';
    return (
        <Form.Group as={Row} controlId={field.name} className={props.className}>
            <Form.Label column sm={labelWidth}>{label}</Form.Label>
            <Col >
                <Form.Control
                    as="select"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(evt) => {
                        if (onChange) {
                            onChange(evt);
                        }
                        field.onChange(evt);
                    }}
                    value={valueIsFullObject ? items[field.value] : field.value}
                    isInvalid={
                        (meta.touched && meta.error !== undefined) ||
                        (meta.initialError !== undefined &&
                            meta.initialValue === field.value)
                    }
                    disabled={props.disabled}
                >
                    {items.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Form.Control>
            </Col>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            ) : meta.initialError && meta.initialValue === field.value ? (
                <Form.Control.Feedback type="invalid">
                    {meta.initialError}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
};
/**
 * A FORMIK Dropdown that take a list of objects as input and fill the formik value with the selected COMPLETE object.
 * Objects selection is based on id. Dropdown display is based on a "name" attribute on object.
 */
export const FkStackedDropdownObj: FC<BootstrapDropdownKeysProps> = ({
    label,
    items,
    onChange,
    valueIsFullObject,
    labelColWidth,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);
    let labelWidth = labelColWidth ? labelColWidth : 'auto';
    return (
        <Form.Group as={Row} controlId={field.name} className={props.className}>
            {label && <Form.Label column sm={labelWidth}>{label}</Form.Label>}
            <Col className="p-0">
                <Form.Control
                    as="select"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(evt) => {
                        const obj = items.find(
                            ({ id }) => id === evt.currentTarget.value,
                        );
                        helpers.setValue(obj);
                        if (onChange) {
                            onChange(evt);
                        }
                    }}
                    value={field.value.id}
                    isInvalid={
                        (meta.touched && meta.error !== undefined) ||
                        (meta.initialError !== undefined &&
                            meta.initialValue === field.value)
                    }
                    disabled={props.disabled}
                >
                    {items.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}

                        </option>
                    ))}
                </Form.Control>
            </Col>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            ) : meta.initialError && meta.initialValue === field.value ? (
                <Form.Control.Feedback type="invalid">
                    {meta.initialError}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
};

type BootstrapDropdownListProps = FieldHookConfig<any> & {
    label?: string;
    items: string[];
    labelColWidth?: any;
    onChange?: (evt: React.ChangeEvent<FormControlElement>) => void;
};

export const BootstrapDropdownList: FC<BootstrapDropdownListProps> = ({
    label,
    items,
    onChange,
    labelColWidth,
    ...props
}) => {
    const [field, meta] = useField(props);
    let labelWidth = labelColWidth ? labelColWidth : 'auto';
    return (
        <Form.Group as={Row} controlId={field.name} className={props.className}>
            {label && (
                <Form.Label column sm={labelWidth}>
                    {label}
                </Form.Label>
            )}
            <Col>
                <Form.Control
                    as="select"
                    disabled={props.disabled}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(evt) => {
                        if (onChange) {
                            onChange(evt);
                        }
                        field.onChange(evt);
                    }}
                    value={field.value}
                    isInvalid={
                        (meta.touched && meta.error !== undefined) ||
                        (meta.initialError !== undefined &&
                            meta.initialValue === field.value)
                    }
                >
                    {items.map((item, idx) => (
                        <option key={idx} value={item}>
                            {item}
                        </option>
                    ))}
                </Form.Control>
                {meta.touched && meta.error ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                ) : meta.initialError && meta.initialValue === field.value ? (
                    <Form.Control.Feedback type="invalid">
                        {meta.initialError}
                    </Form.Control.Feedback>
                ) : null}
            </Col>
        </Form.Group>
    );
};

/**
 * A Dropdown that take a list of string as input.
 */
export const FkStackedDropDownList: FC<BootstrapDropdownListProps> = ({
    label,
    items,
    onChange,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                as="select"
                disabled={props.disabled}
                name={field.name}
                onBlur={field.onBlur}
                onChange={(evt) => {
                    if (onChange) {
                        onChange(evt);
                    }
                    field.onChange(evt);
                }}
                value={field.value}
                isInvalid={
                    (meta.touched && meta.error !== undefined) ||
                    (meta.initialError !== undefined &&
                        meta.initialValue === field.value)
                }
            >
                {items.map((item, idx) => (
                    <option key={idx} value={item}>
                        {item}
                    </option>
                ))}
            </Form.Control>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
            ) : meta.initialError && meta.initialValue === field.value ? (
                <Form.Control.Feedback type="invalid">
                    {meta.initialError}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
};
