import { Form, Formik, FormikProps } from "formik";
import { FC, useState, useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    TerrainToTerrainModel,
    UpdateTerrainModelLinkDto,
} from "src/api/generated.api";
import { useUpdateTerrainModelLinkMutation } from "src/api/TerrainApi";
import { BsFkCheckbox } from "src/components/BsFkCheckbox";
import ErrorBar from "src/components/ErrorBar";
import { FkEditVectorObj } from "src/components/FkEditVectorObj";
import { LangContext } from "src/lang/lang";

export interface TerrainModelLinkEditorProps {
    terrainId: number;
    value: TerrainToTerrainModel;
    onDeleteClick?: (terrainToTerrainModelId: number) => void;
}

export const TerrainModelLinkEditor: FC<TerrainModelLinkEditorProps> = ({
    terrainId,
    value,
    onDeleteClick,
}) => {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const [updateTerrainModelLink] = useUpdateTerrainModelLinkMutation();
    const [errorMessage, setErrorMessage] = useState("");
    return (
        <Formik
            initialValues={value}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                updateTerrainModelLink({
                    terrainId,
                    terrainToTerrainModelId: values.id,
                    updateTerrainModelLinkDto:
                        values as UpdateTerrainModelLinkDto,
                })
                    .unwrap()
                    .then(() => {
                        setErrorMessage("");
                        actions.setSubmitting(false);
                    })
                    .catch((err) => {
                        setErrorMessage(JSON.stringify(err));
                        actions.setSubmitting(false);
                    });
            }}
        >
            {(props: FormikProps<any>) => (
                <Form>
                    <ErrorBar errorMessage={errorMessage} />
                    <Row>
                        <Col>
                            {ObjectNames.terrainModels.en}:{" "}
                            <Link
                                to={`/terrain-model/${value.terrainModel.id}`}
                            >
                                {value.terrainModel.name}
                            </Link>
                        </Col>
                        <Col xs="auto">
                            <BsFkCheckbox label="visible" name="visible" />
                        </Col>
                        <Col xs="auto">
                            <BsFkCheckbox
                                label="override georef"
                                name="overrideGeoRef"
                            />
                        </Col>
                        <Col xs="auto">
                            <Button disabled={!props.dirty} type="submit">
                                {props.isSubmitting
                                    ? Sentences.saving.en
                                    : Sentences.save.en}
                            </Button>
                        </Col>
                        <Col xs="auto">
                            <Button
                                variant="danger"
                                onClick={() =>
                                    onDeleteClick
                                        ? onDeleteClick(value.id)
                                        : null
                                }
                            >
                                X
                            </Button>
                        </Col>
                    </Row>
                    {props.values.overrideGeoRef && (
                        <>
                            <Row>
                                <Col>
                                    <FkEditVectorObj
                                        label="position"
                                        name="meshPosition"
                                    />
                                </Col>
                                <Col>
                                    <FkEditVectorObj
                                        label="rotation"
                                        name="meshRotation"
                                    />
                                </Col>
                                <Col>
                                    <FkEditVectorObj
                                        label="scale"
                                        name="meshScale"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col
                                    md="auto"
                                    className="d-flex align-items-end"
                                >
                                    <Button
                                        onClick={() => {
                                            props.setFieldValue(
                                                "meshPosition",
                                                props.values.terrainModel
                                                    .meshPosition,
                                            );
                                            props.setFieldValue(
                                                "meshRotation",
                                                props.values.terrainModel
                                                    .meshRotation,
                                            );
                                            props.setFieldValue(
                                                "meshScale",
                                                props.values.terrainModel
                                                    .meshScale,
                                            );
                                        }}
                                    >
                                        {Sentences.modelPoseResetToDefault.en}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};
