import { subject } from "@casl/ability";
import {
    CreateTeamMembershipDto,
    CreateTeamRoleDto,
    Membership,
    Team,
    TeamRole,
    TeamRoleControllerGetTeamRolesApiResponse,
    UpdateTeamMembershipDto,
    UpdateTeamRoleDto,
    CreateTeamDto,
    UpdateTeamDto,
    api as generatedApi,
    TeamControllerFindAllApiArg,
    MyTeamsControllerFindMineApiArg,
    TeamSummaryForUserDto,
    MembershipControllerGetMembersApiResponse,
} from "./generated.api";
import { Subjects } from "./Permissions";
import { Socket, io } from "socket.io-client";

export const ROLE = "TeamRole";
export const TEAM = "Team";
export const TEAM_MEMBERS = "TeamMembers" as const;
export const TEAM_MEMBER = "TeamMember" as const;
export const MY_TEAM = "MyTeam";
export const LIST = "List";
export const TeamApi = generatedApi.enhanceEndpoints({
    addTagTypes: [ROLE, TEAM, TEAM_MEMBER, TEAM_MEMBERS, MY_TEAM],
    endpoints: {
        teamRoleControllerGetTeamRoles: {
            providesTags: (result, error, args) =>
                result
                    ? [
                        ...result.map(
                            ({ id }) => ({ type: ROLE, id } as const),
                        ),
                        { type: ROLE, id: LIST } as const,
                    ]
                    : [{ type: ROLE, id: LIST } as const],
            transformResponse: (
                response: TeamRoleControllerGetTeamRolesApiResponse,
            ) => response.map((r) => subject(Subjects.TeamRole, r)),
        },
        teamRoleControllerUpdateRole: {
            invalidatesTags: (r, e, { roleId: id }) => [
                { type: ROLE, id } as const,
            ],
        },
        teamRoleControllerCreateRole: {
            invalidatesTags: [{ type: ROLE, id: LIST } as const],
        },
        membershipControllerGetMembers: {
            providesTags: (result, error, { teamId }) =>
                result
                    ? [
                        ...result.map(
                            ({ teamId, userId }) =>
                            ({
                                type: TEAM_MEMBER,
                                teamId,
                                userId,
                            } as const),
                        ),
                        { type: TEAM_MEMBERS, teamId } as const,
                    ]
                    : [{ type: TEAM_MEMBERS, teamId } as const],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/team");
                console.log(socket.io);
                await api.cacheDataLoaded;
                const cacheData = api.getCacheEntry();
                const uids = cacheData.data?.map(({ userId }) => userId);
                if (uids && uids.length) {
                    socket.on("MembershipsChanged", (data) => {
                        console.log(data);
                        if (data.teamId) {
                            api.dispatch(
                                TeamApi.util.invalidateTags([
                                    {
                                        type: TEAM_MEMBERS,
                                    },
                                ]),
                            );
                        }
                    });
                    socket.emit("SubscribeToUserMemberships", [uids]);
                }
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        membershipControllerGetMember: {
            providesTags: (result, error, { teamId, userId }) =>
                result ? [{ type: TEAM_MEMBER, teamId, userId }] : [],
        },
        membershipControllerAddMembers: {
            invalidatesTags: (result, error, { teamId }) => [
                { type: TEAM_MEMBERS, teamId },
            ],
        },
        membershipControllerRemoveMember: {
            invalidatesTags: (result, error, { teamId }) => [
                { type: TEAM_MEMBERS, teamId },
            ],
        },
        membershipControllerUpdateMember: {
            invalidatesTags: (result, error, { teamId, userId }) => [
                { type: TEAM_MEMBER, teamId, userId },
                { type: TEAM, teamId },
                { type: MY_TEAM, teamId },
            ],
        },
        teamControllerFindAll: {
            providesTags: (resp, err, arg) =>
                resp
                    ? [
                        ...resp.results.map(
                            ({ teamId }) => ({ type: TEAM, teamId } as const),
                        ),
                        { type: TEAM, teamId: LIST },
                    ]
                    : [{ type: TEAM, teamId: LIST }],
        },
        teamControllerUpdate: {
            invalidatesTags: (res, err, { teamId }) => [
                { type: TEAM, teamId },
                {
                    type: MY_TEAM,
                    teamId,
                },
            ],
        },
        teamControllerRemove: {
            invalidatesTags: (res, err, { teamId }) => [
                { type: TEAM, teamId },
                { type: TEAM, teamId: LIST },
                {
                    type: MY_TEAM,
                    teamId,
                },
            ],
        },
        teamControllerCreate: {
            invalidatesTags: (res, err, arg) => [{ type: TEAM, teamId: LIST }],
        },
        teamControllerFindOne: {
            providesTags: (res, err, arg) =>
                res ? [{ type: TEAM, teamId: res.id }] : [],
        },
        myTeamsControllerFindMine: {
            providesTags: (resp, error, arg) =>
                resp
                    ? [
                        ...resp.results.map(
                            ({ teamId }) =>
                            ({
                                type: MY_TEAM,
                                teamId,
                            } as const),
                        ),
                        { type: MY_TEAM, teamId: LIST } as const,
                    ]
                    : [{ type: MY_TEAM, teamId: LIST } as const],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/team");
                console.log(socket.id);
                console.log(socket);
                await api.cacheDataLoaded;
                const cacheData = api.getCacheEntry();
                const uids = cacheData.data?.results.map(
                    ({ userMembership }) => userMembership?.userId,
                );
                if (uids && uids.length) {
                    socket.on("MembershipsChanged", (data) => {
                        console.log("Got team data");
                        if (data.teamId) {
                            api.dispatch(
                                TeamApi.util.invalidateTags([
                                    {
                                        type: MY_TEAM,
                                    } as const,
                                ]),
                            );
                        }
                    });
                    console.log(
                        `subscribing to membership changes for users : ${uids.join(
                            ";",
                        )}`,
                    );
                    socket.emit("SubscribeToUserMemberships", uids);
                }
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        myTeamsControllerFindOneWithUserMembership: {
            providesTags: (resp, error, arg) =>
                resp
                    ? [
                        {
                            type: MY_TEAM,
                            teamId: resp.teamId,
                        } as const,
                    ]
                    : [],
            transformResponse: (resp: TeamSummaryForUserDto) =>
                subject("TeamSummaryForUserDto", { ...resp }),
        },
    },
});

export const {
    useTeamRoleControllerGetTeamRolesQuery,
    useTeamRoleControllerCreateRoleMutation,
    useTeamRoleControllerUpdateRoleMutation,
    useMembershipControllerAddMembersMutation,
    useMembershipControllerGetMembersQuery,
    useMembershipControllerGetMemberQuery,
    useMembershipControllerRemoveMemberMutation,
    useMembershipControllerUpdateMemberMutation,
    useTeamControllerCreateMutation,
    useTeamControllerFindAllQuery,
    useTeamControllerFindOneQuery,
    useTeamControllerRemoveMutation,
    useTeamControllerUpdateMutation,
    useMyTeamsControllerFindMineQuery,
} = TeamApi;

export type {
    CreateTeamRoleDto,
    TeamRole,
    UpdateTeamRoleDto,
    CreateTeamMembershipDto,
    UpdateTeamMembershipDto,
    Membership,
    Team,
    CreateTeamDto,
    UpdateTeamDto,
    TeamControllerFindAllApiArg,
    MyTeamsControllerFindMineApiArg,
    TeamSummaryForUserDto,
    MembershipControllerGetMembersApiResponse,
};
