import { useField } from "formik";
import { FC } from "react";
import {
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    InputGroup,
    Row,
} from "react-bootstrap";
import { FieldHookConfigWithLabel } from "./interfaces/FkInterfaces";

export const FkEditMatrix: FC<FieldHookConfigWithLabel<any>> = ({
    label,
    ...props
}) => {
    const [field, meta] = useField({ ...props });
    return (
        <FormGroup>
            {label && <FormLabel>{label}</FormLabel>}
            {field.value.map((row: number[], rowidx: any) => (
                <Row key={rowidx}>
                    {row.map((col: number, colIdx) => (
                        <Col className="px-1">
                            <InputGroup key={rowidx}>
                                <FormControl
                                    as="input"
                                    type="number"
                                    name={`${field.name}[${rowidx}][${colIdx}]`}
                                    onBlur={field.onBlur}
                                    onChange={field.onChange}
                                    value={field.value[rowidx][colIdx]}
                                    isInvalid={
                                        (meta.touched &&
                                            meta.error !== undefined) ||
                                        (meta.initialError !== undefined &&
                                            meta.initialValue[rowidx][
                                                colIdx
                                            ] === field.value[rowidx][colIdx])
                                    }
                                />
                                {meta.touched && meta.error ? (
                                    <FormControl.Feedback type="invalid">
                                        {meta.error}
                                    </FormControl.Feedback>
                                ) : meta.initialError &&
                                  meta.initialValue[rowidx][colIdx] ===
                                      field.value[rowidx][colIdx] ? (
                                    <FormControl.Feedback type="invalid">
                                        {meta.initialError}
                                    </FormControl.Feedback>
                                ) : null}
                            </InputGroup>
                        </Col>
                    ))}
                </Row>
            ))}
        </FormGroup>
    );
};
