import { FC, useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import ErrorBar from "src/components/ErrorBar";
import { EditTerrainModel } from "./terrain-model-editor";
import { useParams } from "react-router-dom";

export const EditTerrainModelPage: FC<any> = () => {
    const ability = useContext(AbilityContext);
    const { terrainModelId } = useParams<{ terrainModelId: string }>();

    return (
        <>
            <Container>
                {ability.can(Action.Read, Subjects.Terrains) ? (
                    terrainModelId ? (
                        <EditTerrainModel
                            terrainModelId={parseInt(terrainModelId) ?? -1}
                        />
                    ) : (
                        <div>Sorry, unknown terrain</div>
                    )
                ) : (
                    <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator." />
                )}
            </Container>
        </>
    );
};
