import { io, Socket } from "socket.io-client";
import { api as generatedApi, JobEventDto, JobLogDto } from "./generated.api";

type JobEvent = {
    id: number;
    jobId: string;
    timestamp: string;
    status: JobEventDto["status"];
}

type JobLog = {
    id: number;
    jobId: string;
    timestamp: string;
    log: string;
}

export const JobApi = generatedApi.enhanceEndpoints({
    addTagTypes: ["Job", "JobEvents", "JobLogs"],
    endpoints: {
        jobControllerFindAll: {
            providesTags: (result, error, arg) => result 
                ? [...result.map(({id})=>({type: "Job" as const, id})), "Job"]
                : []
        },
        jobControllerFindOne: {
            providesTags: (result, error, arg) => result 
                ? [{type: "Job" as const, id: result.id}, "Job"]
                : []
        },
        jobControllerRemove: (endpoint) => {
            endpoint.invalidatesTags = (result) => result             
                ? [{type: "Job" as const, id: result.id}, "Job"]
                : []
        },
        jobControllerUpdate: (endpoint) => {
            endpoint.invalidatesTags = (result) => result             
                ? [{type: "Job" as const, id: result.id}, "Job"]
                : []
        },
        jobControllerCreate: (endpoint) => {
            endpoint.invalidatesTags = (result) => result             
                ? [{type: "Job" as const, id: result.id}, "Job"]
                : []
        },
        startJob: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => result             
                ? [{type: "JobEvents" as const, id: args.jobId}, "JobEvents"]
                : []
        },
        jobLifeCycleControllerGetJobHistory: {
            providesTags: (result, error, args) => result
                ? [{type: "JobEvents" as const, id: args.jobId}, "JobEvents"]
                : []
        },
        jobLifeCycleControllerCleanHistory: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => [{type: "JobEvents" as const, id: args.jobId}, "JobEvents"]
        },
        jobLifeCycleControllerGetJobLogs: {
            providesTags: (result, error, args) => result
                ? [{type: "JobLogs" as const, id: args.jobId}, "JobLogs"]
                : [],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/JobLog");
                await api.cacheDataLoaded;
                socket.on("JobLog", (data)=>{
                    const {log, timestamp} = JSON.parse(data) as JobLog;
                    const jobLogDto: JobLogDto = {log, timestamp};
                    api.updateCachedData((draft)=> { draft.push(jobLogDto)});
                });
                socket.emit("MonitorLog", [arg.jobId]);
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        jobLifeCycleControllerClearLogs: (endpoint) => {
            endpoint.invalidatesTags = (result, error, args) => [{type: "JobLogs" as const, id: args.jobId}, "JobLogs"]
        },
        jobLifeCycleControllerGetJobStatus: {
            providesTags: (result, error, args) => result
                ? [{type: "JobEvents" as const, id: args.jobId}, "JobEvents"]
                : [],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/JobLog");
                await api.cacheDataLoaded;
                socket.on("JobStatus", (data)=>{
                    const {status, timestamp} = JSON.parse(data) as JobEvent;
                    const jobEventDto: JobEventDto = {status, timestamp};
                    api.updateCachedData((_)=> jobEventDto);
                });
                socket.emit("MonitorLog", [arg.jobId]);
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
    }
});

export const {
    useJobControllerFindAllQuery: useGetAllJobsQuery,
    useJobControllerFindOneQuery: useGetJobQuery,
    useJobLifeCycleControllerGetJobStatusQuery: useGetJobStatusQuery,
    useJobLifeCycleControllerGetJobHistoryQuery: useGetJobHistoryQuery,
    useJobLifeCycleControllerCleanHistoryMutation: useCleanJobHistoryMutation,
    useJobLifeCycleControllerGetJobLogsQuery: useGetJobLogsQuery,
    useJobLifeCycleControllerClearLogsMutation: useClearJobLogMutation,
    useJobControllerCreateMutation: useCreateJobMutation,
    useJobControllerUpdateMutation: useUpdateJobMutation,
    useJobControllerRemoveMutation: useDeleteJobMutation,
    useStartJobMutation,
    useStopJobMutation,
} = JobApi;