
import React from 'react';
import { Outlet } from 'react-router-dom';
import { MainNavbarUnity } from 'src/app-parts/MainNavbar.unity';

const UnityLayout: React.FC = () => {

    return (
        <>
            <MainNavbarUnity />
            <div className="box">
                <Outlet />
            </div>
        </>
    );

}

export default UnityLayout

