import { FC, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Column, Row } from "react-table";
import {
    MyTeamsControllerFindMineApiArg,
    TeamSummaryForUserDto,
    useMyTeamsControllerFindMineQuery,
} from "src/api/team.api";
import ErrorBar from "src/components/ErrorBar";
import TableWithPagination from "src/components/tables/TableWithPagination";
import { Sentences } from "src/lang/lang";

export const MyTeamsPage: FC<any> = () => {
    return (
        <Container>
            <div className="section">
                <MyTeams />
            </div>
        </Container>
    );
};

type RowType = Row<TeamSummaryForUserDto>;
export const MyTeams: FC<any> = () => {
    const [query, setQuery] = useState<MyTeamsControllerFindMineApiArg>({
        limit: 10,
        offset: 0,
    });
    const { data, isFetching, isLoading, isError, error } =
        useMyTeamsControllerFindMineQuery(query);

    const columns: Column<TeamSummaryForUserDto>[] = [
        { Header: "Team Name", accessor: "name" },
        {
            Header: "My Role",
            Cell: ({ row }: { row: RowType }) => (
                <>
                    {row.original.userMembership && (
                        <Badge>{row.original.userMembership.role.name}</Badge>
                    )}
                </>
            ),
        },
        { Header: "Visibility", accessor: "visibility" },
        { Header: "Members", accessor: "numUsers" },
        {
            Header: "Actions",
            Cell: ({ row }: { row: RowType }) => (
                <>
                    <Link to={`/my/teams/details/${row.original.teamId}`}>
                        <Button>Details</Button>
                    </Link>
                </>
            ),
        },
    ];
    return (
        <>
            <div className="section mb-3">
                <h1>{Sentences.myTeamTitle.en}</h1>
                <div className="subtitle">
                    {Sentences.myTeamSubtitle.en}
                </div>
            </div>
            <div>
                <ErrorBar errorMessage={isError ? JSON.stringify(error) : ""} />
                <TableWithPagination
                    columns={columns}
                    data={data?.results || []}
                    fetchData={(pageIndex, pageSize) => {
                        const { limit, offset } = query;
                        const l = pageSize;
                        const o = pageSize * pageIndex;
                        if (limit !== l || offset !== o) {
                            setQuery({ limit: l, offset: o });
                        }
                    }}
                    itemCount={data?.total || 0}
                    loading={isLoading}
                />
            </div>
        </>
    );
};
