import { useContext } from "react";
import { CreateTerrainModelDto } from "src/api/generated.api";
import { LangContext } from "src/lang/lang";

export type MeshDataSourceTypes = CreateTerrainModelDto["meshDataSourceType"];

export const useMeshSourceTypes = () => {
    const { Sentences } = useContext(LangContext);
    const meshSourceTypes: Array<{
        id: MeshDataSourceTypes;
        name: string;
    }> = [
            { id: "TILESV2_FROM_INTERNAL_SOURCE", name: Sentences.tilesLocal.en },
            { id: "TILESV2_FROM_EXTERNAL_SOURCE", name: Sentences.tilesRemote.en },
            { id: "GLB_FROM_EXTERNAL_SOURCE", name: Sentences.glbFileRemote.en },
            { id: "GLB_FROM_INTERNAL_SOURCE", name: Sentences.glbFileLocal.en },
            { id: "FILE_IN_SHARED_ASSETS_FOLDER", name: Sentences.modelEmbeded.en },
            { id: "FILE_AS_REMOTE_ASSET_BUNDLE", name: Sentences.modelRemote.en },
            { id: "TILES_FROM_EXTERNAL_SOURCE", name: Sentences.tilesRemote.en },
            { id: "TILES_FROM_INTERNAL_SOURCE", name: Sentences.oldTilesLocal.en },
        ];
    return meshSourceTypes;
};

export const uploadableTypes = new Set<MeshDataSourceTypes>([
    "TILES_FROM_INTERNAL_SOURCE",
    "TILESV2_FROM_INTERNAL_SOURCE",
    "GLB_FROM_INTERNAL_SOURCE",
]);
