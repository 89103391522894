
import { useNavigate } from "react-router";
import { Container } from "react-bootstrap";
import { CreateJob } from "./CreateEditJob";
import { Job } from "src/api/generated.api";
import { LangContext } from "src/lang/lang";
import { Column } from "react-table";
import TableSimple from "src/components/tables/TableSimple";
import { useGetAllJobsQuery } from "src/api/JobApi";
import { FC, useContext } from "react";


export const JobsPage: FC<any> = () => {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const navigate = useNavigate();

    const { data, isFetching } = useGetAllJobsQuery(undefined, { refetchOnReconnect: true });

    const columns: Column<Job>[] = [
        {
            Header: "Job Id",
            width: undefined,
            Cell: ({ row }: { row: { original: Job } }) => (
                <div>
                    {row.original.id}
                </div>
            )
        },
        {
            Header: Sentences.jobStatus.en,
            width: undefined,
            Cell: ({ row }: { row: { original: Job } }) => (
                <div>
                    {row.original.status}
                </div>
            )
        },
        {
            Header: 'Action',
            width: undefined,
            Cell: ({ row }: { row: { original: Job } }) => (
                <div>
                    {row.original.status}
                </div>
            )
        },
    ]

    return (<>

        <Container>
            <div className="section mb-3">
                <h1>Jobs</h1>
                <div className="subtitle"></div>
            </div>
            <TableSimple
                data={data || []}
                fetchData={(pageIndex, pageNumber) => null}
                columns={columns}
                loading={isFetching}
            />
        </Container>
    </>
    )
}