import { FC } from "react";
import { useCourseSessionStatsControllerGetActivityProgressSummariesQuery } from "src/api/generated.api";
import ErrorBar from "src/components/ErrorBar";
import { ActivityProgressSummaryView } from "../activity-progress/ActivityProgressSummaryView";
import { CourseSessionProgressNav } from "./CourseSessionProgressNav";
import { Row } from "react-bootstrap";

export type CourseSessionProgressOverviewProps = {
    courseSessionId: number;
    onSlideProgressClick?: (slideProgressId: number) => void;
};

export const CourseSessionProgressOverview: FC<
    CourseSessionProgressOverviewProps
> = ({ courseSessionId, onSlideProgressClick }) => {
    const {
        data: apSummaries,
        error,
        isError,
        isFetching,
    } = useCourseSessionStatsControllerGetActivityProgressSummariesQuery(
        { courseSessionId },
        { refetchOnMountOrArgChange: true, pollingInterval: 30000 },
    );

    return (
        <>
            {isFetching && <div>Loading session structure...</div>}
            <ErrorBar errorMessage={isError ? JSON.stringify(error) : ""} />
            {apSummaries && (
                <CourseSessionProgressNav
                    activityProgressSummaries={apSummaries}
                    onSelect={(eventKey: string | null) => {
                        if (onSlideProgressClick && eventKey !== null)
                            onSlideProgressClick(parseInt(eventKey));
                    }}
                />
            )}
            {/*
            { apSummaries && apSummaries.length > 0 
                ? <>
                    <h3>Slide Decks</h3>
                    <div>
                        { apSummaries.map(apSummary => <ActivityProgressSummaryView key={`ap-${apSummary.activityProgressId}`} data={apSummary} onSlideProgressClick={onSlideProgressClick}/>) }
                    </div>
                  </>
                : <div>This session has no Slide Decks.</div>
            } */}
        </>
    );
};
