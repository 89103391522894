import { api as generatedApi } from "./generated.api";

export const SlideProgressApi = generatedApi.enhanceEndpoints({
    addTagTypes: ["SlideProgress"],
    endpoints: {
        slideProgressControllerFindOne: {
            providesTags: (result, error, arg) => result ? [{ type: 'SlideProgress' as const, id: result.id }] : []
        }
    },
});

export const {
    useSlideProgressControllerFindOneQuery: useGetSlideProgressQuery 
} = SlideProgressApi;