import { FC, useContext } from "react";
import { ReactComponent as LogoVRExplorer } from "../design-system/Logo/Logo_SVG/LogoVRExplorer.svg";
import { ReactComponent as LogoVirtuafield } from "../design-system/Logo/Logo_SVG/Logo.svg";
import { VersionInfo } from "../api/generated.api";
import { AppInfoContext } from "src/features/AppVersionWatcher/BackendVersionProvider";

export const AppLogo: FC<any> = () => {
    const context: VersionInfo | undefined = useContext(AppInfoContext);

    if (!context) {
        return <></>;
    }

    if (context.appName === "VRExplorer") {
        return <LogoVRExplorer />;
    }

    if (context.appName === "Virtuafield") {
        return <LogoVirtuafield />;
    }
    return <>{context.appName}</>;
};
