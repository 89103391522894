import { Container } from "react-bootstrap";
import MySessions from "./MySessions";

export default function SessionRecordsPage() {
    return (
        <>
            <Container>
                <MySessions />
            </Container>
        </>
    );
}
