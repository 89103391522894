import { FC, useContext, useEffect, useState } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import ErrorBar from "src/components/ErrorBar";
import { useNavigate, useParams } from "react-router-dom";
import { LangContext } from "src/lang/lang";
import { LayerEditor } from "./terrain-layer-editor";

export const LayerEditorPage: FC<unknown> = () => {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { layerId } = useParams<{ layerId: string }>();

    const [id, setId] = useState<number>();
    const ability = useContext(AbilityContext);
    useEffect(
        () => (layerId ? setId(parseInt(layerId)) : setId(undefined)),
        [layerId],
    );
    const navigate = useNavigate();

    return (
        <>
            <Container>
                {ability.can(Action.Update, Subjects.Terrains) ? (
                    id ? (
                        <LayerEditor
                            layerId={id}
                            onDeletedLayer={() =>
                                navigate("/terrain-layers")
                            }
                        />
                    ) : (
                        <div>{Sentences.layerNotFound.en}</div>
                    )
                ) : (
                    <ErrorBar errorMessage={Sentences.pageNotAllowed.en} />
                )}
            </Container>
        </>
    );
};
