import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form } from "formik";
import { FC, useEffect, useState, useContext } from "react";
import {
    Button,
    Row,
    Col,
    Accordion,
    AccordionCollapse,
    useAccordionToggle,
    Container,
} from "react-bootstrap";
import {
    useChangeTeam,
    useChangeVisibility,
    useDeleteLayerMutation,
    useGetLayerQuery,
    useLayerAddUser,
    useUpdateLayerMutation,
} from "src/api/LayerApi";
import { BootstrapTextInput } from "src/components/BootstrapFormComponents";
import { FkEditKeyValues } from "src/components/FkEditKeyValues";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { LayerMap } from "../terrains/TerrainMap";
import { LangContext } from "src/lang/lang";
import { Subjects } from "src/api/Permissions";
import { ModalDocumentACLEditor } from "../document-access-control/modal-document-acl-editor";
import { ManagedLayerBuildSettingsEditor } from "./managed-layer-build-settings-editor";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";
import { Action } from "@shared/auth/src";
import { subject } from "@casl/ability";
import { AbilityContext, Can } from "src/casl/Can";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { useAbility } from "@casl/react";

interface ParamButtonProps {
    eventKey: string;
}

export const ParamButton: FC<ParamButtonProps> = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log("totally custom!"),
    );

    return (
        <Button type="button" onClick={decoratedOnClick}>
            {children}
        </Button>
    );
};

export type LayerEditorProps = {
    layerId: number;
    onDeletedLayer?: (layerId: number) => void;
};

export const LayerEditor: FC<LayerEditorProps> = ({
    layerId,
    onDeletedLayer,
}) => {
    const { data } = useGetLayerQuery(
        { layerId },
        { refetchOnMountOrArgChange: true },
    );

    const [updateLayer] = useUpdateLayerMutation();
    const [deleteLayer] = useDeleteLayerMutation();
    const [upsertMembership] = useLayerAddUser();
    const [changeTeam] = useChangeTeam();
    const [changeVisibility] = useChangeVisibility();

    const abilities = useAbility(AbilityContext);
    const { ObjectNames, Sentences } = useContext(LangContext);

    const [editable, setEditable] = useState(false);

    function onDeleteClicked() {
        deleteLayer({ layerId })
            .unwrap()
            .then(() => {
                if (onDeletedLayer) {
                    onDeletedLayer(layerId);
                }
            });
    }

    const [, setFormValues] = useState<any>({
        name: "",
        url: "",
    });

    const validationSchema = Yup.object({
        name: Yup.string(),
        url: Yup.string().nullable(true),
    });

    const onSubmit = async (values: any, formikBag: any) => {
        formikBag.setSubmitting(true);
        try {
            await updateLayer({
                layerId,
                updateLayerDto: { ...values },
            }).unwrap();
            formikBag.setSubmitting(false);
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };

    useEffect(() => {
        if (data) {
            setFormValues({ ...data });
        }

        setEditable(
            data !== undefined &&
            abilities.can(
                Action.Update,
                subject(Subjects.TerrainLayer, { ...data }),
            ),
        );

    }, [data, abilities]);


    return (
        <Container className="section">
            <div>
                {data && (
                    <div>
                        <Formik
                            initialValues={data}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {(formik) => (
                                <Form
                                    onSubmit={formik.handleSubmit}
                                    method="post"
                                >
                                    <Row className="mb-2">
                                        <Col>
                                            <h1>{ObjectNames.layer.en}</h1>

                                            <ModalDocumentACLEditor
                                                document={data}
                                                onVisibilityUpdate={
                                                    changeVisibility
                                                }
                                                documentType={
                                                    Subjects.TerrainLayer
                                                }
                                                onTeamChanged={changeTeam}
                                                objWithTeam={data}
                                                documentId={`${data.id}`}
                                                teamId={data.teamId}
                                                onUpsert={(dto) =>
                                                    upsertMembership({
                                                        id: dto.documentId,
                                                        upsertDocumentMembershipDto:
                                                            dto,
                                                    }).unwrap()
                                                }
                                            />
                                        </Col>
                                        {editable && (
                                            <Col
                                                md="auto"
                                                className="align-items-end d-flex"
                                            >
                                                <Button
                                                    disabled={!formik.dirty}
                                                    type="submit"
                                                    variant="primary"
                                                >
                                                    {Sentences.save.en}
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                    <FkStackedTextInput
                                        name="name"
                                        label="Layer name"
                                        placeholder="enter layer name"
                                        disabled={!editable}
                                    />
                                    <Row className="section">
                                        <Col>
                                            Management mode:{" "}
                                            {data.managementMode}
                                        </Col>
                                        <Col>Data Type: {data.dataType}</Col>
                                        <Col>
                                            Source Type: {data.sourceType}
                                        </Col>
                                    </Row>
                                    <Accordion>
                                        <Row>
                                            <Col md="auto">
                                                <ParamButton eventKey="0">
                                                    <FontAwesomeIcon
                                                        icon={faCog}
                                                    />
                                                </ParamButton>
                                            </Col>
                                            <Col>
                                                <BootstrapTextInput
                                                    label="Url"
                                                    name="url"
                                                    placeholder={
                                                        Sentences
                                                            .layerDetailsJsonUrl
                                                            .en
                                                    }
                                                    disabled={
                                                        data.managementMode ===
                                                        "INTERNAL" ||
                                                        !editable
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <AccordionCollapse eventKey="0">
                                            <FkEditKeyValues
                                                name="connectionParams"
                                                label={
                                                    Sentences
                                                        .connectionHeaderAndValues
                                                        .en
                                                }
                                                disabled={!editable}
                                            />
                                        </AccordionCollapse>
                                    </Accordion>
                                </Form>
                            )}
                        </Formik>

                        {data.managementMode !== "INTERNAL" && <LayerMap terrainLayer={data} mapHeight="600px" />}
                        {editable && data.managementMode === "INTERNAL" && (
                            <div className="section">
                                <ManagedLayerBuildSettingsEditor
                                    layer={data}
                                    editable={editable}
                                />
                            </div>
                        )}

                        <Can
                            I={Action.Delete}
                            this={subject(Subjects.TerrainLayer, { ...data })}
                        >
                            {" "}
                            <DeleteButtonWithConfirm
                                onClick={onDeleteClicked}
                                message={Sentences.layerDeleteSentence.en}
                            >
                                Delete Layer
                            </DeleteButtonWithConfirm>
                        </Can>
                    </div>
                )}
            </div>
        </Container>
    );
};
