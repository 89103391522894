
import React from 'react';
import { Outlet } from 'react-router-dom';

import { MainNavbar } from 'src/app-parts/MainNavbar';


const MainLayout: React.FC = ({ children }) => {

    return (
        <>
            <MainNavbar />
            <div className="box">
                <Outlet />
            </div>
        </>
    );

}

export default MainLayout
