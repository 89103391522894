import { subject } from "@casl/ability";
import { useAbility } from "@casl/react";
import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Action, Subjects } from "src/api/Permissions";
import {
    Activity,
    CourseSession,
    Membership,
    Terrain,
    TerrainLayer,
    TerrainModel,
    api,
} from "src/api/generated.api";
import { AbilityContext } from "src/casl/Can";
import { selectLoggedInUser } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { store } from "src/app/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

export type DocumentAclDebuggerProps = {
    document: Terrain | TerrainLayer | TerrainModel | Activity | CourseSession;
    documentType: Subjects;
};

export const DocumentAclDebugger: FC<DocumentAclDebuggerProps> = ({
    document,
    documentType,
}) => {
    const ab = useAbility(AbilityContext);
    const user = useSelector(selectLoggedInUser);

    const [teamMembership, setTeamMembership] = useState<
        Membership | undefined
    >();

    const [data, setData] = useState<
        { action: Action; allowed: boolean; reason: string }[]
    >([]);

    useEffect(() => {
        const fetchTeamRole = async () => {
            if (user) {
                const { data } = await store.dispatch(
                    api.endpoints.membershipControllerGetMember.initiate({
                        teamId: document.teamId,
                        userId: user.id,
                    }),
                );
                setTeamMembership(data);
            }
        };
        fetchTeamRole();
    }, [user, document]);

    useEffect(() => {
        setData(
            [
                Action.Read,
                Action.Create,
                Action.Delete,
                Action.Update,
                Action.Share,
            ].map((action) => {
                const sub = subject(documentType, { ...document });
                const allowed = ab.can(action, subject(documentType, sub));
                const rule = ab.relevantRuleFor(action, sub);
                const reason = rule
                    ? rule.reason || "No reason provided"
                    : "No rule is allowing this action";
                return {
                    action,
                    allowed,
                    reason,
                };
            }),
        );
    }, [ab, document, documentType]);

    return (
        <div className="debugText">
            <div>Document Visibility: {document.visibility}</div>
            <div>
                User permissions in team owning this doc:{" "}
                {teamMembership
                    ? teamMembership.role.permissions.map(o => o.action).join(", ")
                    : "User is not a member of the team this document belong to"}
            </div>
            {data.map(({ action, allowed, reason }) => (
                <Row key={action}>
                    <Col md="1" className="text-center">
                        <FontAwesomeIcon
                            color={allowed ? "green" : "red"}
                            icon={allowed ? faCheckCircle : faTimesCircle}
                        />
                    </Col>
                    <Col md="1">{action}</Col>
                    <Col>{reason}</Col>
                </Row>
            ))}
        </div>
    );
};
