import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { Can } from "../casl/Can";
import { Action, Subjects } from "@shared/auth/src/permissions";
import { FormCheck, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { CurrentUser } from "../features/auth/CurrentUser";
import { UploaderMonitor } from "../features/uploader/UploaderMonitor";
import { useGetTerrainModelsCapabilitiesQuery } from "../api/TerrainModelApi";
import { VersionIsNewerRefreshButton } from "../features/AppVersionWatcher/VersionIsNewerRefreshButton";
import { AppLogo } from "./AppLogo";
import { NavDropdownMailto } from "src/components/NavDropdownMailto";
import { AppInfoContext } from "src/features/AppVersionWatcher/BackendVersionProvider";
import { TeamContextSelector } from "src/features/teams/context/team-context-selector";
import {
    faArrowCircleDown,
    faBell,
    faCog,
    faCube,
    faEdit,
    faExclamationTriangle,
    faEye,
    faGlobeAmericas,
    faLayerGroup,
    faQuestionCircle,
    faShieldAlt,
    faThList,
    faUserCog,
    faUserFriends,
    faUserPlus,
    faUsersCog,
    faVrCardboard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "src/features/auth/authSlice";
import { NavBasicNotificationItem } from "src/features/notifications/NavBasicNotificationItem";
import RoutesDef from "src/routes/RoutesDef";

export const MainNavbar: FC<any> = () => {
    const { data: tmCapabilities } = useGetTerrainModelsCapabilitiesQuery();
    const navDropdownAboutTitle = (
        <FontAwesomeIcon icon={faQuestionCircle} className="bigIcon" />
    );
    const navDropdownSettingsTitle = (
        <FontAwesomeIcon icon={faCog} className="bigIcon" />
    );

    const navDropdownVRSessionsTitle = (
        <span>
            <FontAwesomeIcon icon={faVrCardboard} className="bigIcon mr-2" />
            Sessions
        </span>
    );
    const appVersion = useContext(AppInfoContext);
    const authStatus = useSelector(selectAuthStatus);
    const loggedIn = (authStatus === 'LOGGED_IN');
    return (
        <Navbar fixed="top" expand="lg" bg="dark">
            <Navbar.Brand >
                <Link to={RoutesDef.HOME}>
                    <AppLogo />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Can I={Action.Read} a={Subjects.CourseSessions}>
                        <NavDropdown
                            alignRight
                            title={navDropdownVRSessionsTitle}
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item as={Link} to="/sessions">
                                <FontAwesomeIcon
                                    className="mr-2 bigIcon"
                                    icon={faEdit}
                                />{" "}
                                Manage
                            </NavDropdown.Item>

                            <NavDropdown.Item as={Link} to="/session-records">
                                <FontAwesomeIcon
                                    className="mr-2 bigIcon"
                                    icon={faEye}
                                />{" "}
                                Records
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Can>

                    <Can I={Action.Read} a={Subjects.Activities}>
                        <Link className="nav-link" to="/activities">
                            <FontAwesomeIcon
                                className="mr-2 bigIcon"
                                icon={faThList}
                            />{" "}
                            Slide Deck
                        </Link>
                    </Can>
                    <Can I={Action.Read} a={Subjects.Terrains}>
                        <Link className="nav-link" to="/terrains">
                            <FontAwesomeIcon
                                className="mr-2 bigIcon"
                                icon={faGlobeAmericas}
                            />{" "}
                            Scenes
                        </Link>
                    </Can>
                    <Can I={Action.Read} a={Subjects.TerrainLayer}>
                        <Link className="nav-link" to="/terrain-layers">
                            <FontAwesomeIcon
                                className="mr-2 bigIcon"
                                icon={faLayerGroup}
                            />{" "}
                            Layers
                        </Link>
                    </Can>
                    <Can I={Action.Read} a={Subjects.TerrainModel}>
                        {tmCapabilities && tmCapabilities.length > 0 && (
                            <Link className="nav-link" to="/terrain-model">
                                <FontAwesomeIcon
                                    className="mr-2 bigIcon"
                                    icon={faCube}
                                />{" "}
                                Models
                            </Link>
                        )}
                    </Can>
                </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <Can I={Action.Read} a={Subjects.Terrains}>
                        <UploaderMonitor />
                    </Can>
                    <VersionIsNewerRefreshButton />
                    {loggedIn && <TeamContextSelector />}
                    <Can I={Action.Manage} a={Subjects.All}>
                        <NavDropdown
                            className="navDropDownWithoutArrow"
                            alignRight
                            title={navDropdownSettingsTitle}
                            id="basic-nav-dropdown"
                        >


                            <NavDropdown.Item as={Link} to="/users">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faUserCog}
                                />{" "}
                                Manage users
                            </NavDropdown.Item>

                            <NavDropdown.Item as={Link} to="/admin/teams/">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faUsersCog}
                                />{" "}
                                Manage teams
                            </NavDropdown.Item>

                            <NavDropdown.Item
                                as={Link}
                                to="/admin/teams/roles/"
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faShieldAlt}
                                />{" "}
                                Manage team roles
                            </NavDropdown.Item>

                        </NavDropdown>
                    </Can>

                    {loggedIn && <NavBasicNotificationItem />}

                    <NavDropdown
                        className="navDropDownWithoutArrow"
                        alignRight
                        title={navDropdownAboutTitle}
                        id="basic-nav-dropdown"
                    >
                        <NavDropdownMailto
                            label="Contact support"
                            mailto={appVersion?.supportEmail}
                        />
                        <Can I={Action.Read} a={Subjects.ArtifactRelease}>
                            <NavDropdown.Item as={Link} to="/releases">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faArrowCircleDown}
                                />
                                Releases
                            </NavDropdown.Item>
                        </Can>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={Link} to="/about">
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faQuestionCircle}
                            />{" "}
                            About VRExplorer
                        </NavDropdown.Item>
                    </NavDropdown>

                    <CurrentUser />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
