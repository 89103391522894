import { Formik, FormikHelpers, Form as FormikForm } from "formik";
import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import {
    ArtifactRelease,
    CreateArtifactReleaseDto,
    useCreateRelease,
} from "src/api/BinaryArtifactApi";
import { FkStackedDropdownKeys } from "src/components/BootstrapFormComponents";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { OnSavedId } from "src/types/common-props";

export const AddBinaryArtifactRelease: FC<OnSavedId<ArtifactRelease["id"]>> = ({
    onSaveId: onSave,
}) => {
    const [createArtifactRelease] = useCreateRelease();
    const intialValues: CreateArtifactReleaseDto = {
        name: "",
        channel: "",
    };

    const onSubmit = async (
        createArtifactReleaseDto: CreateArtifactReleaseDto,
        formikBag: FormikHelpers<CreateArtifactReleaseDto>,
    ) => {
        try {
            const res = await createArtifactRelease({
                createArtifactReleaseDto,
            }).unwrap();
            formikBag.resetForm({ values: createArtifactReleaseDto });
            if (onSave) onSave(res.id);
        } catch (error) {
            const errors = convertApiErrorsToFormikErrors(error);
            formikBag.setErrors(errors);
        }
    };
    return (
        <>
            <Formik initialValues={intialValues} onSubmit={onSubmit}>
                {(formik) => (
                    <FormikForm onSubmit={formik.handleSubmit}>
                        <FkStackedTextInputWithButton
                            label="Release Name"
                            name="name"
                            placeholder="release name"
                            buttonText="Save"
                        />
                        <Row>
                            <Col>
                                <FkStackedTextInput
                                    label="Channel Name"
                                    name="channel"
                                    placeholder="channel name"
                                />
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </>
    );
};
