import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { C } from "@shared/auth/src";
import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { TeamPermission } from "src/api/generated.api";
import {
    Team,
    TeamRole,
    useMembershipControllerAddMembersMutation,
    useTeamRoleControllerGetTeamRolesQuery,
} from "src/api/team.api";
import { FkStackedDropdownObj } from "src/components/BootstrapFormComponents";
import ErrorBar from "src/components/ErrorBar";
import { FkSearchUsersAutocomplete } from "src/features/users/FkSearchUsersAutocomplete";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import * as Yup from "yup";

export type AddTeamMembershipProps = {
    teamId: Team["id"];
};

export const AddTeamMembership: FC<AddTeamMembershipProps> = ({ teamId }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const { data: roles } = useTeamRoleControllerGetTeamRolesQuery({ teamId });
    const [createMembership, createMembershipResult] = useMembershipControllerAddMembersMutation();
    const initialValue = {
        teamId,
        userId: "",
        role: roles ? roles[0] : "",
    };

    const validationSchema = Yup.object().shape({
        teamId: Yup.string().required(),
        userId: Yup.number().required("a known user is required"),
        role: Yup.object().defined(),
    });

    useEffect(
        () => setErrorMessage(handleError(createMembershipResult.error)),
        [createMembershipResult.isError, createMembershipResult.error],
    );

    function handleError(
        error: FetchBaseQueryError | SerializedError | undefined,
    ) {
        if (error === undefined) {
            return "";
        }
        let serError = error as any;
        if (serError?.data?.message.includes("already exists")) {
            return "This user is already a member of this team.";
        }
        return JSON.stringify(error);
    }

    const onSubmit = async (values: any) => {


        const m = await createMembership({
            createTeamMembershipDto: {
                role: values.role,
                teamId,
                userId: values.userId,
            },
            teamId,
        });


    };
    return (
        <>
            <ErrorBar errorMessage={errorMessage} />
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}

            >
                {(formikProps) => (
                    <Form className="mt-5">
                        <Row>
                            <Col>
                                <FkSearchUsersAutocomplete
                                    fieldIsId
                                    name="userId"
                                    label="User"
                                />
                            </Col>
                            <Col>
                                <FkStackedDropdownObj
                                    items={roles || []}
                                    name="role"
                                    label="Role"
                                />
                            </Col>
                            <Col md="auto">
                                <Button type="submit">Add</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
};
