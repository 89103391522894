import { useField, useFormikContext } from "formik";
import { FC } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { FkStackedTextInputProps } from "./FkStackedTextInput";

export type FkStackedTextInputWithButtonProps = FkStackedTextInputProps & {
    buttonText: string
}

export const FkStackedTextInputWithButton: FC<FkStackedTextInputWithButtonProps> = ({ label, placeholder, buttonText, onChange, ...props }) => {
    const { dirty } = useFormikContext();
    const [field, meta] = useField(props);

    return (
        <Form.Group controlId={field.name}>
            <Row>
                <Col>
                    <Form.Label>{label}</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control
                        as='input'
                        type={props.type || "text"}
                        name={field.name}
                        onBlur={field.onBlur}
                        disabled={props.disabled}
                        onChange={(evt) => {
                            if (onChange) {
                                onChange(evt);
                            }
                            field.onChange(evt);
                        }}
                        value={field.value}
                        isInvalid={(meta.touched && (meta.error !== undefined)) || ((meta.initialError !== undefined) && meta.initialValue === field.value)}
                        placeholder={placeholder}
                    />
                    {
                        (meta.touched && meta.error)
                            ? (<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>)
                            : (meta.initialError && meta.initialValue === field.value)
                                ? (<Form.Control.Feedback type="invalid">{meta.initialError}</Form.Control.Feedback>)
                                : null
                    }
                </Col>
                {!props.disabled && <Col md="auto">
                    <Button disabled={!dirty} type="submit" variant="primary">{buttonText}</Button>
                </Col>}
            </Row>
        </Form.Group>
    );
}