import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';
import queryString from 'query-string';
import { useSocialGoogleLoginRedirectQuery } from 'src/api/AuthApi';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';
import { LangContext } from 'src/lang/lang';
import ErrorBar from 'src/components/ErrorBar';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import RoutesDef from 'src/routes/RoutesDef';

export function GoogleLogin() {
    const { Sentences } = useContext(LangContext);

    const location = useLocation();
    const navigate = useNavigate();
    const { authuser, code, prompt, scope } = queryString.parse(location.search);
    const { isSuccess, isError, error } = useSocialGoogleLoginRedirectQuery({
        authuser: (!Array.isArray(authuser) && authuser ? authuser : undefined),
        code: (!Array.isArray(code) && code ? code : undefined),
        prompt: (!Array.isArray(prompt) && prompt ? prompt : undefined),
        scope: (!Array.isArray(scope) && scope ? scope : undefined),
    });
    const [errorMessage, setErrorMessage] = useState<string>("");
    useEffect(() => {

        if (isError) {
            const fberror = error as FetchBaseQueryError;
            const message = fberror.data ? (fberror.data as { status: number, message: string }).message : JSON.stringify(fberror.data);
            setErrorMessage(message)
            setTimeout(() => navigate(RoutesDef.LOGIN), 5000);
        }

        if (isSuccess) {
            navigate("/");
        }
    }, [isError, isSuccess, navigate]);

    return (<>
        <Row className="bg ">

            <Col className="my-auto justify-content-center">
                <div className='my-50'><h1 className="display-4 text-center">{Sentences.welcome.en}</h1></div>
                <Container style={{ width: 450 }}>
                    <Card >
                        <Card.Header className='text-center'>
                            <h1>{Sentences.signIn.en}</h1>
                        </Card.Header>
                        <Card.Body>
                            {isError && <>
                                <ErrorBar errorMessage={errorMessage} />
                                <Button onClick={() => navigate(RoutesDef.LOGIN)}>Go back to login</Button>
                            </>}
                        </Card.Body>
                    </Card>
                </Container>
            </Col>
        </Row>
    </>
    );
}