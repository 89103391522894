import { FC } from "react"
import { QuizContent } from "src/api/generated.api";

type Qtype = 'NONE'| 'FREEFORM'| 'MULTIPLE_CHOICE'| 'SINGLE_CHOICE'| 'REORDER_CHOICE';

export type QuizResultsViewProps = {
    quiz: QuizContent,
    quizType: Qtype,
    answers: string[],
};

export const QuizResultsView: FC<QuizResultsViewProps> = ({quiz, quizType, answers}) => {


    const renderProposedAnswers = () => {
        if (quizType === 'NONE' || quizType === 'FREEFORM') {
            return null;
        }        
        if (quizType === 'MULTIPLE_CHOICE' || quizType === 'SINGLE_CHOICE' || quizType === 'REORDER_CHOICE') {
            return (<div>
                <div>Possible answers:</div>
                <ul>{quiz.possibleAnswers.map( (answer, index) => <li key={index}>{answer.answer}</li>)}</ul>
                <div>Group Answered:</div>
                <ul>{answers.map( (answer, index) => <li key={index}>{answer}</li>)}</ul>
                </div>);
        }
        return null;
    }


    return (
        <div>
            <div>Quiz Type: {quizType} </div>
            <div>{quiz.description}</div>
            {renderProposedAnswers()}
        </div>
    )
}