import { Formik, FormikHelpers } from "formik";
import { FC, useEffect, useState, useContext } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { TerrainModel } from "src/api/generated.api";
import {
    useChangeTeam,
    useChangeVisibility,
    useGetOneTerrainModelQuery,
    useGetTerrainModelsCapabilitiesQuery,
    useRemoveTerrainModelMutation,
    useTerrainModelsAddUser,
    useUpdateTerrainModelMutation,
} from "src/api/TerrainModelApi";
import {
    BootstrapDropdownKeys,
    BootstrapTextInput,
} from "src/components/BootstrapFormComponents";
import ErrorBar from "src/components/ErrorBar";
import { FkEditVectorObj } from "src/components/FkEditVectorObj";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { LangContext } from "src/lang/lang";
import { EditTerrainModelFilesRevisions } from "../TerrainModelFiles/EditTerrainModelFilesRevisions";
import { FkEditMatrix } from "src/components/FkEditMatrix";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { MeshDataSourceTypes, uploadableTypes, useMeshSourceTypes } from "./terrain-model-types";
import { Action, Subjects } from "src/api/Permissions";
import { ModalDocumentACLEditor } from "src/features/document-access-control/modal-document-acl-editor";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "src/casl/Can";
import { subject } from "@casl/ability";

export type EditTerrainModelProps = {
    terrainModelId: number;
};

export const EditTerrainModel: FC<EditTerrainModelProps> = ({
    terrainModelId,
}) => {
    /** queries */
    const {
        data: terrainModel,
        isError,
        error,
    } = useGetOneTerrainModelQuery({ terrainModelId });
    const { data: capabilities } = useGetTerrainModelsCapabilitiesQuery();

    const [infoMessage, setInfoMessage] = useState(true);

    /** mutations */
    const [upsertMembership] = useTerrainModelsAddUser();
    const [updateTerrainModel] = useUpdateTerrainModelMutation();
    const [deleteTerrainModel] = useRemoveTerrainModelMutation();
    const [changeTeam] = useChangeTeam();
    const [changeVisibility] = useChangeVisibility();

    /** context */
    const navigate = useNavigate();
    const { ObjectNames, Sentences } = useContext(LangContext);
    const meshSourceTypes = useMeshSourceTypes();
    const abilities = useAbility(AbilityContext);

    /** states */
    const [errorMessage, setErrorMessage] = useState("");
    const [editable, setEditable] = useState(false);

    const onSubmit = async (
        values: TerrainModel,
        formikBag: FormikHelpers<TerrainModel>,
    ) => {
        formikBag.setSubmitting(true);
        try {
            await updateTerrainModel({
                terrainModelId: values.id,
                updateTerrainModelDto: values,
            }).unwrap();
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
        formikBag.setSubmitting(false);
    };

    const onDeleteClick = async () => {
        try {
            await deleteTerrainModel({ terrainModelId }).unwrap();
            navigate("/terrain-model");
        } catch (e) {
            setErrorMessage(JSON.stringify(e));
        }
    };

    useEffect(() => {
        setErrorMessage(isError ? JSON.stringify(error) : "");
    }, [isError, error]);

    useEffect(() => {
        setEditable(
            terrainModel !== undefined &&
            abilities.can(
                Action.Update,
                subject(Subjects.TerrainModel, { ...terrainModel }),
            ),
        );
    }, [terrainModel, abilities]);

    const terrainModelType = terrainModel ? meshSourceTypes.filter(({ id, name }) => id.includes(terrainModel.meshDataSourceType))[0] : { name: '' };

    return (
        <Container className="section">
            <ErrorBar errorMessage={errorMessage} />
            {terrainModel && (
                <>
                    <Formik
                        initialValues={terrainModel}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} method="post">
                                <Row className="mb-4">
                                    <Col>
                                        <Row>
                                            <Col sm="auto">
                                                <h1>{ObjectNames.terrainModel.en}</h1>
                                            </Col>
                                            <Col >
                                                <Row>  {!infoMessage && <Button className="mt-2 ml-4" onClick={() => setInfoMessage(true)}>?</Button>}</Row>

                                            </Col>
                                        </Row>
                                        <ModalDocumentACLEditor
                                            document={terrainModel}
                                            onVisibilityUpdate={
                                                changeVisibility
                                            }
                                            documentType={Subjects.TerrainModel}
                                            onTeamChanged={changeTeam}
                                            objWithTeam={terrainModel}
                                            documentId={`${terrainModel.id}`}
                                            teamId={terrainModel.teamId}
                                            onUpsert={(dto) =>
                                                upsertMembership({
                                                    id: dto.documentId,
                                                    upsertDocumentMembershipDto:
                                                        dto,
                                                }).unwrap()
                                            }
                                        />
                                    </Col>
                                    {editable && (
                                        <Col
                                            md="auto"
                                            className="align-items-end d-flex"
                                        >
                                            <Button
                                                disabled={!formik.dirty}
                                                type="submit"
                                                variant="primary"
                                            >
                                                {Sentences.save.en}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>

                                {terrainModel.meshDataSourceType == 'TILESV2_FROM_INTERNAL_SOURCE' && <Alert className="mt-2 mb-2" show={infoMessage} style={{ fontSize: '0.9rem', color: "#75b798", backgroundColor: "#051b11" }}>
                                    <p>
                                        <strong>3D Tiles</strong> is specifically designed to stream and render large-scale 3D geospatial content. It uses a hierarchical data structure and a set of tile formats to deliver content that is ready for rendering.
                                    </p>
                                    <p>
                                        <strong>Prerequisite</strong>: Your data must be converted into 3D Tiles using software (e.g. <Link target="_blank" to="https://construkted.com/3d-tiles-from-metashape/">Metashape</Link>) and
                                        must meet the following specifications :
                                        <ul className="mt-2">
                                            <li>
                                                Type: Cesium 3D tiles (*.zip) </li>
                                            <li>
                                                Version: 1.0</li>
                                            <li>
                                                Texture format: jpg</li>
                                            <li>
                                                Max size: 5 Go</li>
                                        </ul>

                                    </p>
                                    <div style={{ position: "absolute", bottom: 10, right: 10 }} className="btn absolute d-flex justify-content-end">
                                        <Button variant="success" onClick={() => setInfoMessage(false)} >
                                            Close
                                        </Button>
                                    </div>
                                </Alert>}

                                <Row className="mb-2">
                                    <Col sm="2">
                                        {Sentences.terrainModelIs.en}
                                    </Col>
                                    <Col sm="9">
                                        {terrainModel && terrainModelType.name}
                                    </Col>
                                </Row>
                                <BootstrapTextInput
                                    name="name"
                                    label={Sentences.terrainModelName.en}
                                    placeholder={Sentences.terrainModelName.en}
                                    disabled={!editable}
                                />
                                <BootstrapTextInput
                                    name="meshFilename"
                                    label={Sentences.terrainModelPath.en}
                                    placeholder={
                                        uploadableTypes.has(
                                            formik.initialValues
                                                .meshDataSourceType,
                                        )
                                            ? "Computed from current revision"
                                            : Sentences.terrainModelPath.en
                                    }
                                    disabled={
                                        !editable ||
                                        uploadableTypes.has(
                                            formik.initialValues
                                                .meshDataSourceType,
                                        )
                                    }
                                />
                                <Row>
                                    <Col>
                                        <FkEditVectorObj
                                            name="meshPosition"
                                            label={Sentences.defaultPosition.en}
                                            disabled={!editable}
                                        />
                                    </Col>
                                    <Col>
                                        <FkEditVectorObj
                                            name="meshRotation"
                                            label={Sentences.defaultRotation.en}
                                            disabled={!editable}
                                        />
                                    </Col>
                                    <Col>
                                        <FkEditVectorObj
                                            name="meshScale"
                                            label={Sentences.defaultScale.en}
                                            disabled={!editable}
                                        />
                                    </Col>
                                    <Col>
                                        <FkEditMatrix
                                            name="meshTransformMatrix"
                                            label={
                                                Sentences.defaultTransformMatrix
                                                    .en
                                            }
                                            disabled={!editable}
                                        />
                                    </Col>
                                </Row>
                                {editable &&
                                    uploadableTypes.has(
                                        terrainModel?.meshDataSourceType,
                                    ) && (
                                        <div>



                                            <EditTerrainModelFilesRevisions
                                                terrainModel={terrainModel}
                                            />
                                        </div>
                                    )}
                                <Can
                                    I={Action.Delete}
                                    this={subject(Subjects.TerrainModel, {
                                        ...terrainModel,
                                    })}
                                >
                                    <DeleteButtonWithConfirm
                                        onClick={onDeleteClick}
                                    >
                                        {Sentences.terrainModelDelete.en}
                                    </DeleteButtonWithConfirm>
                                </Can>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Container>
    );
};
