import { faBell, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { selectLoggedInUser } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { useState } from "react";

export function NavBasicNotificationItem() {
    const [showNotification, setShowNotification] = useState(true)
    const user = useSelector(selectLoggedInUser);
    const displayEmailNotification = !user?.email && showNotification

    const navDropdownNotificationTitle = (
        <>
            <FontAwesomeIcon icon={faBell} className="bigIcon" />
            <span className="position-absolute text-white rounded-pill bg-danger" style={{ width: 18, height: 18, padding: 0, top: 0, right: -5, fontSize: 11, fontWeight: "bold", textAlign: "center" }}>
                1
            </span>
        </>
    );

    return (displayEmailNotification ? <NavDropdown
        className="navDropDownWithoutArrow"
        alignRight
        title={navDropdownNotificationTitle}
        id="basic-nav-dropdown"
    >

        <NavDropdown.Item as={Link} onClick={() => setShowNotification(false)} to="/my/profile">
            <FontAwesomeIcon
                className="mr-2"
                icon={faExclamationTriangle}
            />
            Please update your profile : missing email address
        </NavDropdown.Item>
    </NavDropdown> : <></>)
}