import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Action } from "@shared/auth/src";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FC } from "react";
import { Subjects } from "src/api/Permissions";
import { subject } from "@casl/ability";
import { useAbility } from "@casl/react";
import { AbilityContext } from "src/casl/Can";

export type ViewOrEditButtonProps = {
    obj: Record<PropertyKey, any>;
    subjectType: Subjects;
    field?: string;
    editLink: string;
    readLink: string;
};
export const ViewOrEditLinkButton: FC<ViewOrEditButtonProps> = ({
    obj,
    subjectType,
    field,
    editLink,
    readLink,
}) => {
    const ab = useAbility(AbilityContext);

    return (
        <>
            {" "}
            {ab.can(Action.Update, subject(subjectType, { ...obj }), field) ? (
                <Link to={editLink}>
                    <Button variant="secondary" className="ml-1">
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </Link>
            ) : ab.can(Action.Read, subject(subjectType, { ...obj }), field) ? (
                <Link to={readLink}>
                    <Button variant="secondary" className="ml-1">
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </Link>
            ) : (
                ""
            )}
        </>
    );
};
