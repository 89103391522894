import Alert from "react-bootstrap/Alert";
import { FC } from "react";

export type ErrorBarProps = {
    errorMessage: string;
    onDismiss?: () => void;
};

const ErrorBar: FC<ErrorBarProps> = ({ errorMessage, onDismiss }) => {
    return (
        <>
            {errorMessage ? (
                <Alert
                    variant="danger"
                    dismissible={onDismiss !== undefined}
                    onClose={() => onDismiss && onDismiss()}
                >
                    <Alert.Heading>Error</Alert.Heading>
                    <p> {errorMessage} </p>
                </Alert>
            ) : null}
        </>
    );
};

export default ErrorBar;
