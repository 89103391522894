
import { useNavigate } from "react-router";
import { Container } from "react-bootstrap";
import { CreateJob } from "./CreateEditJob";


export default function CreateJobPage() {

    const navigate = useNavigate();

    function onJobSaved(jobId: string) {
        navigate("" + jobId);
    }

    return (<>

        <Container>
            <CreateJob onJobSaved={onJobSaved} />
        </Container>
    </>
    )
}