import { Formik, FormikHelpers } from "formik";
import { FC, useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CreateTerrainModelDto } from "src/api/generated.api";
import {
    useCreateTerrainModelMutation,
    useGetTerrainModelsCapabilitiesQuery,
} from "src/api/TerrainModelApi";
import { BootstrapDropdownKeys } from "src/components/BootstrapFormComponents";
import ErrorBar from "src/components/ErrorBar";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { teamContext } from "../../teams/context/team-context-provider";
import { useMeshSourceTypes } from "./terrain-model-types";

export type CreateTerrainModelProps = {};

export const TerrainModelCreator: FC<CreateTerrainModelProps> = () => {
    const { Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [createTerrainModel, { isError: createIsError, error: createError }] =
        useCreateTerrainModelMutation();
    const {
        data: capabilities,
        isError: isErrorFetchingCapabilities,
        error: capError,
    } = useGetTerrainModelsCapabilitiesQuery();

    const meshSourceTypes = useMeshSourceTypes();

    const onCreate = async (
        values: CreateTerrainModelDto,
        formikBag: FormikHelpers<CreateTerrainModelDto>,
    ) => {
        try {
            const terrainModel = await createTerrainModel({
                createTerrainModelDto: values,
            }).unwrap();
            navigate(`/terrain-model/${terrainModel.id}`);
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const isErr = isErrorFetchingCapabilities || createIsError;
        const err = [capError, createError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [capError, createError, createIsError, isErrorFetchingCapabilities]);

    const navigate = useNavigate();

    return (
        <>
            <ErrorBar errorMessage={errorMessage} />
            {capabilities && capabilities.length > 0 ? (
                <Formik
                    initialValues={{
                        name: "",
                        meshDataSourceType: meshSourceTypes.filter(({ id }) =>
                            (capabilities || []).includes(id),
                        )[0].id,
                        teamId: currentTeam ? currentTeam.id : "",
                    }}
                    onSubmit={onCreate}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} method="post">
                            <FkStackedTextInputWithButton
                                label={Sentences.terrainModelName.en}
                                name="name"
                                placeholder={Sentences.terrainModelName.en}
                                buttonText={Sentences.terrainModelCreate.en}
                            />
                            <BootstrapDropdownKeys
                                items={meshSourceTypes.filter(({ id }) =>
                                    (capabilities || []).includes(id),
                                )}
                                label={Sentences.terrainModelIs.en}
                                name="meshDataSourceType"
                            />
                        </Form>
                    )}
                </Formik>
            ) : (
                <div>No model storage capabilities available on server.</div>
            )}
        </>
    );
};
