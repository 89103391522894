import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

/**
 * A service to manage file uploads in a queue.
 * File upload should be done as full file or as a chunked upload.
 * Files are provided along with a function to resolve the upload path.
 */

const { createContext, useContext } = React;

const chunkSize = 100 * 1024 * 1024;

export enum FileUploadStatus {
    PENDING = "Pending",
    RUNNING = "Running",
    DONE = "Done",
    ERROR = "Error",
}

export type FileUploadTask = {
    file: File;
    /** local tracking id, mainly used front side. autogenerated */
    uploadId: string;
    progress: number;
    totalChunks: number;
    uploadedChunks: number;
    status: FileUploadStatus;
    abortController: AbortController;
    uploadFunction: DoUploadFunction;
    metadata?: any;
};

export type ChunkInfo = {
    offset: number;
    totalSize: number;
    endAdress: number;
    fileName: string;
    chunk: Blob;
    uploadId: string;
    /** start at 1 */
    partNumber: number;
    totalParts: number;
}

export type DoUploadFunction = (chunkInfo: ChunkInfo, abortSignal: AbortSignal) => Promise<void>;
export type InitUploadFunction = (uploadIds: string[]) => void;

const uploadNextChunk = async (task: FileUploadTask) => {

    const offset = task.uploadedChunks * chunkSize;
    const totalSize = task.file.size;
    const endAdress = Math.min(offset + chunkSize, totalSize);
    const chunk = task.file.slice(offset, endAdress);

    const info: ChunkInfo = {
        offset,
        totalSize,
        endAdress,
        fileName: task.file.name,
        chunk,
        uploadId: task.uploadId,
        partNumber: task.uploadedChunks + 1,
        totalParts: task.totalChunks,
    }

    return task.uploadFunction(info, task.abortController.signal)
};

export type uploadFunc = (files: File[], layerId: number) => string[];
export type cancelFunc = (uploadId: string) => void;

export type ChunkUploaderContextType = {
    addToQueue: (files: File[], doUploadFunction: DoUploadFunction, initUploadFunction?: InitUploadFunction) => Promise<FileUploadTask[]>; 
    cancel: cancelFunc;
    cancelAll: () => void;
    clearProcessed: () => void;
    uploadQueue: FileUploadTask[];
    filesProcessed: FileUploadTask[];
};

const ChunkUploaderContext = createContext<ChunkUploaderContextType>({
    addToQueue: (files: File[], doUploadFunction: DoUploadFunction, initUploadFunction?: InitUploadFunction) => {
        console.error(
            "addToQueue has been called from default context. Did you forgot to add a provider ?",
        );
        return Promise.resolve([]);
    },
    cancel: (uploadId) => {
        console.error(
            "cancel has been called from default context. Did you forgot to add a provider ?",
        );
    },
    cancelAll: () => {
        console.error(
            "cancelAll has been called from default context. Did you forgot to add a provider ?",
        );
    },
    clearProcessed: () => {
        console.error(
            "clearProcessed has been called from default context. Did you forgot to add a provider ?",
        );
    },
    uploadQueue: [],
    filesProcessed: [],
});

export type ChunkUploaderProviderProps = {
    children: ReactNode;
};

type BeforeUnloadEventHandler = (e: BeforeUnloadEvent ) => void;

export const ChunkUploaderProvider: FC<ChunkUploaderProviderProps> = ({
    children,
}) => {
    const [queues, setQueues] = useState<{
        uploadQueue: FileUploadTask[];
        filesProcessed: FileUploadTask[];
    }>({
        uploadQueue: [],
        filesProcessed: [],
    });

    const alertUserRef = useRef<BeforeUnloadEventHandler>();

    const addToQueue = async (files: File[], doUploadFunction: DoUploadFunction, initUploadFunction?: InitUploadFunction) => {
        const objs: FileUploadTask[] = files.map((file) => ({
            file,
            progress: 0,
            totalChunks: Math.ceil(file.size / chunkSize),
            uploadedChunks: 0,
            uploadId: v4(),
            status: FileUploadStatus.PENDING,
            abortController: new AbortController(),
            uploadFunction: doUploadFunction,
        }));
        const ids = objs.map(({ uploadId }) => uploadId);
        if (initUploadFunction) {
            await initUploadFunction(ids.map((uploadId)=>uploadId));
        }
        setQueues((old) => ({
            uploadQueue: [...old.uploadQueue, ...objs],
            filesProcessed: [...old.filesProcessed],
        }));
        return objs;
    };

    const cancel: cancelFunc = (uploadId) => {
        setQueues(({ uploadQueue, filesProcessed }) => {
            const task = uploadQueue.find(
                ({ uploadId: uId }) => uId === uploadId,
            );
            if (task) {
                task.abortController.abort();
            }

            return {
                filesProcessed,
                uploadQueue: uploadQueue.filter(
                    (task) => task.uploadId !== uploadId,
                ),
            };
        });
    };

    const cancelAll = () => {
        setQueues(({ uploadQueue, filesProcessed }) => {
            queues.uploadQueue.forEach((task) => {
                task.abortController.abort();
                task.status = FileUploadStatus.ERROR;
            });
            return {
                filesProcessed: [...filesProcessed, ...uploadQueue],
                uploadQueue: [],
            };
        });
    };

    const clearProcessed = () => {
        setQueues(({ uploadQueue }) => ({
            uploadQueue,
            filesProcessed: [],
        }));
    };

    useEffect(() => {
        const processQueue = async () => {
            const runningTasks = queues.uploadQueue.filter(
                ({ status }) => status === FileUploadStatus.RUNNING,
            );

            if (runningTasks.length === 0) {
                const task = queues.uploadQueue.find(
                    ({ status }) => status === FileUploadStatus.PENDING,
                );
                if (task) {
                    try {
                        task.status = FileUploadStatus.RUNNING;
                        setQueues(({ uploadQueue, filesProcessed }) => {
                            return {
                                filesProcessed,
                                uploadQueue: uploadQueue.map((oldTask) =>
                                    oldTask.uploadId === task.uploadId
                                        ? task
                                        : oldTask,
                                ),
                            };
                        });
                        await uploadNextChunk(task);
                        task.uploadedChunks += 1;
                        task.progress = task.uploadedChunks / task.totalChunks;
                        if (task.uploadedChunks === task.totalChunks) {
                            task.status = FileUploadStatus.DONE;
                            setQueues(({ uploadQueue, filesProcessed }) => {
                                return {
                                    uploadQueue: uploadQueue.filter(
                                        ({ uploadId }) =>
                                            uploadId !== task.uploadId,
                                    ),
                                    filesProcessed: [...filesProcessed, task],
                                };
                            });
                        } else {
                            task.status = FileUploadStatus.PENDING;
                            setQueues(({ uploadQueue, filesProcessed }) => {
                                return {
                                    filesProcessed,
                                    uploadQueue: uploadQueue.map((oldTask) =>
                                        oldTask.uploadId === task.uploadId
                                            ? task
                                            : oldTask,
                                    ),
                                };
                            });
                        }
                    } catch {
                        task.status = FileUploadStatus.ERROR;
                        setQueues(({ uploadQueue, filesProcessed }) => {
                            return {
                                uploadQueue: uploadQueue.filter(
                                    ({ uploadId }) =>
                                        uploadId !== task.uploadId,
                                ),
                                filesProcessed: [...filesProcessed, task],
                            };
                        });
                    }
                }
            }
        };
        processQueue();
    }, [queues]);

    const alertUser = useCallback<BeforeUnloadEventHandler>((e) => {
        console.log(queues.uploadQueue.length)
        if (queues.uploadQueue.length > 0) {
            e.preventDefault();
            e.returnValue = "";
        }
    }, [queues]);

    useEffect(() => {
        if (alertUserRef.current) {
            window.removeEventListener("beforeunload", alertUserRef.current);
        }
        alertUserRef.current = alertUser;
        window.addEventListener("beforeunload", alertUserRef.current);
        return () => {
            if (alertUserRef.current) {
                window.removeEventListener("beforeunload", alertUserRef.current);
            }
        };
    }, [alertUser]);

    return (
        <ChunkUploaderContext.Provider
            value={{
                addToQueue,
                cancel,
                clearProcessed,
                cancelAll,
                uploadQueue: queues.uploadQueue,
                filesProcessed: queues.filesProcessed,
            }}
        >
            {children}
        </ChunkUploaderContext.Provider>
    );
};

export const useChunkUploader = () => {
    return useContext(ChunkUploaderContext);
};
