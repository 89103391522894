import { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DocumentTeamEditIfCan, DocumentTeamEditIfCanProps } from '../document-mgmt/document-team-edit-if-can';
import { DocumentVisibilityEditorIfCan, DocumentVisibilityEditorIfCanProps } from './document-visibility-editor-if-can';
import { DocumentAclDebugger } from './document-acl-debugger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faEye, faLock, faQuestionCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { DocumentAclEntriesEditor, DocumentAclEntriesEditorProps } from './document-acl-entries-editor';
import { getDocumentAclEntries } from 'src/api/document-access-control.api';

export type ModalDocumentACLEditorProps = DocumentVisibilityEditorIfCanProps & DocumentTeamEditIfCanProps & DocumentAclEntriesEditorProps;

export const ModalDocumentACLEditor: FC<ModalDocumentACLEditorProps> = ({
    document,
    documentType,
    onVisibilityUpdate,
    onTeamChanged,
    documentId,
    teamId,
    onUpsert
}) => {
    const [showDebugger, setshowDebugger] = useState(false);
    const [show, setShow] = useState(false);

    const handleCloseMainModal = () => setShow(false);
    const handleShowMainModal = () => setShow(true);
    const handleCloseDebugger = () => setshowDebugger(false);
    const toggleShowDebugger = () => setshowDebugger(!showDebugger);

    const iconByVisibilty: { [key: string]: IconDefinition } = {
        'Team': faUserFriends,
        'Public': faEye,
        'Private': faLock
    }

    let perm_string = 'undefined'
    const perms: { [key: string]: number } = {}

    const { data } = getDocumentAclEntries({ documentId, documentType });

    document.perms.forEach((perm) => {
        const role_name = perm.role.name
        if (role_name in perms) {
            perms[role_name] += 1
        }
        else {
            perms[role_name] = 1
        }
    });

    let perm_array = []
    for (let key in perms) {
        perm_array.push(perms[key] + ' ' + key + (perms[key] > 1 ? 's' : ''))
    }
    if (perm_array.length) {
        perm_string = perm_array.join(', ')
    }

    const currentVisibilityIcon = document.visibility in iconByVisibilty ? (<FontAwesomeIcon className="ml-2" icon={iconByVisibilty[document.visibility]} />) : ''

    return (
        <>
            <div className='documentVisibility'>
                <span>Permissions : </span>
                <Button onClick={handleShowMainModal} variant="link">{perm_string}</Button>
                <FontAwesomeIcon onClick={toggleShowDebugger} className="documentVisibility ml-2 secondary" icon={faQuestionCircle} />
            </div>
            <div className='documentVisibility'>
                <span>Visibility : </span>
                <Button onClick={handleShowMainModal} variant="link">{document.visibility}{currentVisibilityIcon}</Button>
            </div>

            <Modal show={showDebugger} onHide={handleCloseDebugger}>
                <Modal.Header closeButton>
                    <Modal.Title>ACL Debugger</Modal.Title>
                </Modal.Header>
                <Modal.Body> <DocumentAclDebugger
                    document={document}
                    documentType={documentType}
                /></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDebugger}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className='modalDocACLEditor' show={show} onHide={handleCloseMainModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Document access control</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocumentTeamEditIfCan
                        documentType={documentType}
                        objWithTeam={document}
                        onTeamChanged={onTeamChanged}
                    />

                    <DocumentVisibilityEditorIfCan
                        document={document}
                        onVisibilityUpdate={onVisibilityUpdate}
                        documentType={documentType}
                    />

                    <h5 className='my-4'>User permissions</h5>

                    <DocumentAclEntriesEditor
                        documentId={documentId}
                        documentType={documentType}
                        teamId={teamId}
                        onUpsert={onUpsert}
                    />
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseMainModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

