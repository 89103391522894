import {
    useAddStudentToCourseSessionMutation,
    useConnectActivityWithCourseSessionMutation,
    useDisconnectActivityFromCourseSessionMutation,
    useGetCourseSessionActivityProgressQuery,
    useGetCourseSessionQuery,
    useGetCourseSessionStudentsQuery,
    useRemoveStudentFromCourseSessionMutation,
    useUpdateCourseSessionMutation,
    useSetCourseSessionOwnerMutation,
    useCreateAndConnectActivityMutation,
    useSetCourseSessionModeMutation,
    useCourseSessionAddUser,
    useChangeTeam,
    useChangeVisibility,
} from "src/api/CourseSessionApi";

import { Formik, Form, FormikHelpers } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Row, Col, Collapse, FormLabel } from "react-bootstrap";
import { FkStackedDropDownList } from "src/components/BootstrapFormComponents";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import * as Yup from "yup";
import TableSimple from "src/components/tables/TableSimple";
import {
    Activity,
    ActivityProgress,
    CourseSession,
    UpdateCurrentModeDto,
    User,
} from "src/api/generated.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Can, AbilityContext } from "src/casl/Can";
import { Action, Subjects } from "src/api/Permissions";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { subject } from "@casl/ability";
import { ActivitySelector } from "../activities/ActivitySelector";
import ErrorBar from "src/components/ErrorBar";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import ConfirmDelete from "src/components/ConfirmDelete";
import { ActivityStats } from "../session-records/activity-progress/ActivityProgressStats";
import { LangContext } from "src/lang/lang";
import { useNavigate } from "react-router-dom";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { TeamMemberUserTableSelector } from "../teams/team-member-user-table-selector";
import { ModalDocumentACLEditor } from "../document-access-control/modal-document-acl-editor";
import { useAbility } from "@casl/react";

type EditSessionModeProps = {
    courseSession: CourseSession;
};

const EditSessionMode: FC<EditSessionModeProps> = ({ courseSession }) => {
    const { Sentences } = useContext(LangContext);

    const ability = useContext(AbilityContext);

    const { id: courseSessionId, currentMode: mode } = courseSession;
    const [setCurrentMode] = useSetCourseSessionModeMutation();

    const onSubmit = async (
        values: UpdateCurrentModeDto,
        helpers: FormikHelpers<UpdateCurrentModeDto>,
    ) => {
        await setCurrentMode({
            courseSessionId,
            updateCurrentModeDto: { mode: values.mode },
        });
    };
    return (
        <>
            <Formik<UpdateCurrentModeDto>
                initialValues={{ mode }}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    return (
                        <Form>
                            <FkStackedDropDownList
                                items={["COURSE", "TP", "EXAM"]}
                                label={
                                    Sentences.courseSessionStartupModeLabel.en
                                }
                                name="mode"
                                disabled={ability.cannot(
                                    Action.Update,
                                    subject(Subjects.CourseSessions, {
                                        ...courseSession,
                                    }),
                                )}
                                onChange={formik.submitForm}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

interface EditSessionProps {
    courseSessionId: number;
}

export default function EditSession({ courseSessionId }: EditSessionProps) {
    const [editable, setEditable] = useState(false);
    const abilities = useAbility(AbilityContext);
    const { ObjectNames, Sentences } = useContext(LangContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [changeTeam] = useChangeTeam();
    const [changeVisibility] = useChangeVisibility();
    const {
        data,
        isFetching: isFetchingCourseSession,
        isError: isGetCourseSessionError,
        error: getCourseQueryError,
    } = useGetCourseSessionQuery(
        { courseSessionId },
        { refetchOnMountOrArgChange: true },
    );
    const {
        data: activityProgressRecords,
        isFetching: isFetchingActivities,
        isError: isGetActivityError,
        error: getActivityProgressError,
    } = useGetCourseSessionActivityProgressQuery({ courseSessionId });
    const {
        data: students,
        isFetching: isFetchingStudents,
        isError: isFetchingStudentsError,
        error: getStudentsError,
    } = useGetCourseSessionStudentsQuery({ courseSessionId });
    const ability = useContext(AbilityContext);

    const [showConfirmDeleteActivity, setShowConfirmDeleteActivity] =
        useState(false);
    const [activityToDelete, setActivityToDelete] = useState<number>(-1);

    const [showConfirmRemoveStudent, setShowConfirmRemoveStudent] =
        useState(false);
    const [studentToRemove, setStudentToRemove] = useState<number>(-1);

    const [addStudent, addStudentResult] =
        useAddStudentToCourseSessionMutation();
    const [removeStudent, removeStudentResult] =
        useRemoveStudentFromCourseSessionMutation();

    const [connectActivity, connectActivityResult] =
        useConnectActivityWithCourseSessionMutation();
    const [disconnectActivity, disconnectActivityResult] =
        useDisconnectActivityFromCourseSessionMutation();

    const [createAndConnectActivity] = useCreateAndConnectActivityMutation();

    const [setOwner, setOwnerResult] = useSetCourseSessionOwnerMutation();

    const navigate = useNavigate();

    useEffect(
        () => setErrorMessage(handleError(getCourseQueryError)),
        [isGetCourseSessionError, getCourseQueryError],
    );
    useEffect(
        () => setErrorMessage(handleError(getActivityProgressError)),
        [isGetActivityError, getActivityProgressError],
    );
    useEffect(
        () => setErrorMessage(handleError(getStudentsError)),
        [isFetchingStudentsError, getStudentsError],
    );
    useEffect(
        () => setErrorMessage(handleError(addStudentResult.error)),
        [addStudentResult.isError, addStudentResult.error],
    );
    useEffect(
        () => setErrorMessage(handleError(removeStudentResult.error)),
        [removeStudentResult.isError, removeStudentResult.error],
    );
    useEffect(
        () => setErrorMessage(handleError(connectActivityResult.error)),
        [connectActivityResult.isError, connectActivityResult.error],
    );
    useEffect(
        () => setErrorMessage(handleError(disconnectActivityResult.error)),
        [disconnectActivityResult.isError, disconnectActivityResult.error],
    );
    useEffect(
        () => setErrorMessage(handleError(setOwnerResult.error)),
        [setOwnerResult.isError, setOwnerResult.error],
    );

    function handleError(
        error: FetchBaseQueryError | SerializedError | undefined,
    ) {
        if (error === undefined) {
            return "";
        }
        let fetchError = error as FetchBaseQueryError;
        if (fetchError.status !== undefined) {
            if ("error" in fetchError) {
                return fetchError.error;
            }
            return JSON.stringify(fetchError);
        }
        let serError = error as SerializedError;
        return serError.message ? serError.message : "unknown serialized error";
    }

    const [saveObject] = useUpdateCourseSessionMutation();

    const [formValues, setFormValues] = useState<any>({
        name: "",
    });

    const validationSchema = Yup.object({
        name: Yup.string(),
    });

    const onSubmit = async (values: any, formikBag: any) => {
        formikBag.setSubmitting(true);
        try {
            const { name } = values;
            await saveObject({
                courseSessionId,
                updateCourseSessionDto: {
                    name,
                },
            }).unwrap();
            formikBag.setSubmitting(false);
        } catch (e) {
            try {
                const errors = convertApiErrorsToFormikErrors(e);
                formikBag.setErrors(errors);
                formikBag.setSubmitting(false);
            } catch (fe) {
                setErrorMessage(JSON.stringify(e));
                formikBag.setSubmitting(false);
            }
        }
    };

    useEffect(() => {
        if (data) {
            setFormValues({ ...data });
        }
    }, [data]);

    function onActivitySelected(activity: Activity) {
        connectActivity({ courseSessionId, activityId: activity.id });
    }

    function onRemoveActivityItemClicked(index: number) {
        if (activityProgressRecords) {
            setActivityToDelete(activityProgressRecords[index].id);
            setShowConfirmDeleteActivity(true);
        }
    }

    async function onCreateAndConnectClick() {
        try {
            const act = await createAndConnectActivity({
                courseSessionId,
            }).unwrap();
            navigate(`/activities/${act.id}`);
        } catch (error) {
            setErrorMessage(handleError(error));
        }
    }

    function performActivityDelete() {
        if (activityToDelete > -1) {
            setShowConfirmDeleteActivity(false);
            disconnectActivity({
                courseSessionId,
                activityProgressId: activityToDelete,
            });
        }
    }

    function cancelActivityDelete() {
        setShowConfirmDeleteActivity(false);
        setActivityToDelete(-1);
    }

    function onRemoveStudentItemClicked(index: number) {
        if (students) {
            setStudentToRemove(students[index].id);
            setShowConfirmRemoveStudent(true);
        }
    }

    function performRemoveStudent() {
        if (studentToRemove > -1) {
            setShowConfirmRemoveStudent(false);
            removeStudent({ courseSessionId, studentId: studentToRemove });
        }
    }

    function cancelRemoveStudent() {
        setShowConfirmRemoveStudent(false);
        setStudentToRemove(-1);
    }

    function onStudentSelected(student: User) {
        addStudent({ courseSessionId, studentId: student.id });
    }

    const onOwnerSelected = async (owner: User) => {
        await setOwner({ courseSessionId, userId: owner.id });
        //addStudent({courseSessionId, studentId: student.id});
    };

    const activitiesColumns = [
        {
            Header: "Name",
            width: undefined,
            Cell: ({ row }: { row: { original: ActivityProgress } }) => (
                <div>{row.original.activity.name}</div>
            ),
        },
        {
            Header: "Stats",
            width: undefined,
            Cell: ({ row }: { row: { original: ActivityProgress } }) => (
                <ActivityStats
                    courseSessionId={courseSessionId}
                    activityProgressId={row.original.id}
                />
            ),
        },
        {
            Header: "Action",
            width: 125,

            Cell: ({
                row,
            }: {
                row: { original: ActivityProgress; index: number };
            }) => {
                return (
                    <div>
                        {editable && (
                            <Button
                                variant="outline-dark"
                                className="ml-1"
                                onClick={() =>
                                    onRemoveActivityItemClicked(row.index)
                                }
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    const studentsColumns = [
        {
            Header: "Name",
            width: undefined,
            Cell: ({ row }: { row: { original: User } }) => (
                <div>{row.original.username}</div>
            ),
        },
        {
            Header: "Action",
            width: 125,

            Cell: ({ row }: { row: { original: User; index: number } }) => {
                return (
                    <div>
                        {editable && (
                            <Button
                                variant="outline-dark"
                                className="ml-1"
                                onClick={() =>
                                    onRemoveStudentItemClicked(row.index)
                                }
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    const [showActivitySelector, setShowActivitySelector] = useState(false);
    const [showStudentSelector, setShowStudentSelector] = useState(false);
    const [showOwnerSelector, setShowOwnerSelector] = useState(false);
    const [upsertMembership] = useCourseSessionAddUser();

    useEffect(() => {
        if (
            data &&
            abilities.can(
                Action.Update,
                subject(Subjects.CourseSessions, { ...data }),
            )
        ) {
            setEditable(true);
        } else {
            setEditable(false);
        }
    }, [data, abilities]);

    return (
        <>
            <ErrorBar errorMessage={errorMessage}></ErrorBar>
            <ConfirmDelete
                showConfirmation={showConfirmDeleteActivity}
                message={Sentences.unlinkActivityWarning.en}
                onPerformDelete={performActivityDelete}
                onCancelDelete={cancelActivityDelete}
            />
            <ConfirmDelete
                showConfirmation={showConfirmRemoveStudent}
                message={Sentences.unlinkStudentWarning.en}
                onPerformDelete={performRemoveStudent}
                onCancelDelete={cancelRemoveStudent}
            />
            {isFetchingCourseSession && <>...loading</>}
            {!(isGetCourseSessionError || isFetchingCourseSession) ? (
                <>
                    {data && (
                        <Formik
                            initialValues={formValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                        >
                            {(formik) => (
                                <>
                                    <Form
                                        onSubmit={formik.handleSubmit}
                                        method="post"
                                    >
                                        <Row className="section mb-3">
                                            <Col>
                                                <h1>
                                                    {
                                                        ObjectNames
                                                            .courseSession.en
                                                    }
                                                </h1>
                                                <ModalDocumentACLEditor
                                                    document={data}
                                                    onVisibilityUpdate={
                                                        changeVisibility
                                                    }
                                                    documentType={
                                                        Subjects.CourseSessions
                                                    }
                                                    onTeamChanged={changeTeam}
                                                    objWithTeam={data}
                                                    documentId={`${data.id}`}
                                                    teamId={data.teamId}
                                                    onUpsert={(dto) =>
                                                        upsertMembership({
                                                            id: dto.documentId,
                                                            upsertDocumentMembershipDto:
                                                                dto,
                                                        }).unwrap()
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <div className="section">
                                            <FkStackedTextInputWithButton
                                                buttonText={Sentences.save.en}
                                                label={
                                                    Sentences.courseSessionName
                                                        .en
                                                }
                                                name="name"
                                                placeholder={
                                                    Sentences.courseSessionName
                                                        .en
                                                }
                                                disabled={!editable}
                                            />
                                            <>
                                                {data && (
                                                    <EditSessionMode
                                                        courseSession={data}
                                                    />
                                                )}
                                            </>
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    )}
                    {data && data.owners.length === 1 && (
                        <>
                            <Row>
                                <Col sm={2}>
                                    <FormLabel>
                                        {Sentences.courseSessionHostLabel.en}
                                    </FormLabel>
                                </Col>
                                <Col>
                                    <div>{data.owners[0].username}</div>
                                </Col>
                                {editable && (
                                    <Col sm="auto">
                                        <Button
                                            variant="danger"
                                            onClick={() =>
                                                setShowOwnerSelector(
                                                    (value) => !value,
                                                )
                                            }
                                            aria-controls="example-collapse-text"
                                            aria-expanded={showOwnerSelector}
                                        >
                                            {showOwnerSelector
                                                ? Sentences.cancel.en
                                                : Sentences
                                                    .courseSessionChangeHostLabel
                                                    .en}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            {editable && (
                                <Collapse in={showOwnerSelector}>
                                    <div>
                                        {showOwnerSelector && (
                                            <>
                                                <h3>
                                                    {
                                                        Sentences
                                                            .courseSessionHostSelect
                                                            .en
                                                    }
                                                </h3>
                                                <TeamMemberUserTableSelector
                                                    teamId={data.teamId}
                                                    onSelected={(user) => {
                                                        onOwnerSelected(user);
                                                        setShowOwnerSelector(
                                                            false,
                                                        );
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </Collapse>
                            )}
                        </>
                    )}
                    <div className="section mb-3">
                        <Row>
                            <Col>
                                <h4>{ObjectNames.activities.en}</h4>
                            </Col>
                            <Col md="auto"></Col>
                        </Row>
                    </div>
                    <TableSimple
                        data={activityProgressRecords ?? []}
                        columns={activitiesColumns}
                        fetchData={() => { }}
                        loading={isFetchingActivities}
                    />
                    <Row>
                        <Col>
                            {showActivitySelector && (
                                <>
                                    <h4>{Sentences.activitySelect.en}</h4>
                                </>
                            )}
                        </Col>
                        <Col md="auto">
                            {editable && (
                                <>
                                    <Can
                                        I={Action.Create}
                                        this={subject(Subjects.Activities, {
                                            teamId: data?.teamId,
                                        })}
                                    >
                                        <>
                                            <Button
                                                onClick={
                                                    onCreateAndConnectClick
                                                }
                                            >
                                                {Sentences.activityCreate.en}
                                            </Button>
                                            {` ${Sentences.or.en} `}
                                        </>
                                    </Can>
                                    <Button
                                        onClick={() => {
                                            setShowActivitySelector(
                                                (value) => !value,
                                            );
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={showActivitySelector}
                                    >
                                        {showActivitySelector
                                            ? Sentences.close.en
                                            : Sentences.activityAdd.en}
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                    {data && editable && (
                        <Collapse in={showActivitySelector}>
                            <div className="mt-4">
                                <ActivitySelector
                                    onSelected={(activity) => {
                                        onActivitySelected(activity);
                                    }}
                                    teamId={data.teamId}
                                />
                            </div>
                        </Collapse>
                    )}
                    <div className="section mb-3">
                        <Row>
                            <Col>
                                <h4>{ObjectNames.students.en}</h4>
                            </Col>
                        </Row>
                    </div>
                    <TableSimple
                        data={students ?? []}
                        columns={studentsColumns}
                        fetchData={() => { }}
                        loading={isFetchingStudents}
                    />
                    <div className="mb-3">
                        {data && editable && (
                            <>
                                <Row>
                                    <Col>
                                        {showStudentSelector && (
                                            <h3>
                                                {Sentences.studentSelect.en}
                                            </h3>
                                        )}
                                    </Col>
                                    <Col md="auto">
                                        <Can
                                            I={Action.Update}
                                            this={subject(
                                                Subjects.CourseSessions,
                                                {
                                                    ...data,
                                                },
                                            )}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setShowStudentSelector(
                                                        (value) => !value,
                                                    );
                                                }}
                                                aria-controls="student-selector"
                                                aria-expanded={
                                                    showStudentSelector
                                                }
                                            >
                                                {showStudentSelector
                                                    ? Sentences.close.en
                                                    : Sentences.studentAdd.en}
                                            </Button>
                                        </Can>
                                    </Col>
                                </Row>

                                <Collapse in={showStudentSelector}>
                                    <div>
                                        <TeamMemberUserTableSelector
                                            teamId={data.teamId}
                                            onSelected={(user) => {
                                                onStudentSelected(user);
                                            }}
                                        />
                                    </div>
                                </Collapse>
                            </>
                        )}
                    </div>
                </>
            ) : (
                `${Sentences.courseSessionErrorLoading.en} ${courseSessionId}`
            )}
        </>
    );
}
