import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import { Confirm } from "./Confirm";

export type ConfirmDeleteProps = {
    showConfirmation: boolean;
    onPerformDelete?: () => void;
    onCancelDelete?: () => void;
    message?: string;
};
const ConfirmDelete = function ({
    showConfirmation,
    onPerformDelete,
    onCancelDelete,
    message,
}: ConfirmDeleteProps) {
    const defaultMessage: string = "Are you sure you want to delete ?";
    return (
        <Confirm
            showConfirmation={showConfirmation}
            message={message || defaultMessage}
            onCancel={onCancelDelete}
            onPerform={onPerformDelete}
        />
    );
};

export default ConfirmDelete;
