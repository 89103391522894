import { FC, useState } from "react";
import { FKTeamMemberSelector } from "../teams/fk-team-member-selector";
import { FkDocumentRoleSelector } from "./fk-document-role-selector";
import { Form, Formik, FormikHelpers } from "formik";
import { UpsertDocumentMembershipDto } from "src/api/document-access-control.api";
import { Button, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";

export type DocumentAclEntryEditorProps = {
    teamId: string;
    documentId: string;
    documentType: string;
    onSubmit: (dto: UpsertDocumentMembershipDto) => Promise<unknown>;
};

export const DocumentAclEntryEditor: FC<DocumentAclEntryEditorProps> = ({
    teamId,
    documentId,
    documentType,
    onSubmit,
}) => {
    const [errorMessage, setErrorMessage] = useState("");

    const initialValues: UpsertDocumentMembershipDto = {
        documentId,
        documentRoleId: "",
        documentType,
        teamMembershipId: "",
    };

    const validationSchema = Yup.object({
        documentId: Yup.string().trim().required(),
        documentRoleId: Yup.string().uuid().required(),
        documentType: Yup.string().trim().required(),
        teamMembershipId: Yup.string().uuid().required(),
    });

    const submit = async (
        values: UpsertDocumentMembershipDto,
        helpers: FormikHelpers<UpsertDocumentMembershipDto>,
    ) => {
        try {
            await onSubmit(values);
            helpers.resetForm();
        } catch (error) {
            setErrorMessage(
                "Failed adding or updating Document Access Control",
            );
        }
    };
    return (
        <>
            <ErrorBar
                errorMessage={errorMessage}
                onDismiss={() => setErrorMessage("")}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submit}
            >
                {(formikProps) => (
                    <Form>
                        <Row>
                            <Col>
                                <FKTeamMemberSelector
                                    name="teamMembershipId"
                                    teamId={teamId}
                                    label="User name"
                                />
                            </Col>
                            <Col>
                                <FkDocumentRoleSelector
                                    name="documentRoleId"
                                    label="Document Role"
                                />
                            </Col>
                            <Col sm={2}>
                                <Button type="submit">Add</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
};
