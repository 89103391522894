import { Formik, Form as FormikForm } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
    useAddLocalPasswordMutation,
    useAddSocialAccountMutation,
    useFindOneUserQuery,
    useGetUserAuthProvidersQuery,
    useRemoveAuthProviderMutation,
    useUpdateUserMutation,
} from "src/api/UserApi";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import {
    BootstrapDropdownList,
    BootstrapTextInput,
    FkStackedDropdownKeys,
    FkStackedDropdownObj,
} from "src/components/BootstrapFormComponents";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { AuthProvider, CreateLocalUserDto, CreateTeamMembershipDto, TeamRole, useCreateLocalUserMutation, useMembershipControllerAddMembersMutation } from "src/api/generated.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TableSimple from "src/components/tables/TableSimple";
import { roles } from "./UserTools";
import { subject } from "@casl/ability";
import { Can, AbilityContext } from "../../casl/Can";
import { Action, Subjects } from "../../api/Permissions";
import { LangContext } from "src/lang/lang";
import axios from "axios";
import { AddAuthProviderForm, LocalPasswordSchema } from "./AddAuthProvider";
import { useNavigate } from "react-router-dom";
import { useMyTeamsControllerFindMineQuery, useTeamRoleControllerGetTeamRolesQuery } from "src/api/team.api";
import { teamContext } from "../teams/context/team-context-provider";
import { Response } from "http-proxy-middleware/dist/types";
import RoutesDef from "src/routes/RoutesDef";



export function CreateUser() {
    const { Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);

    const { data: teams } = useMyTeamsControllerFindMineQuery({});
    const { data: teamRoles } = useTeamRoleControllerGetTeamRolesQuery({ teamId: currentTeam?.id || "" });

    const [createLocalUser] = useCreateLocalUserMutation();
    const [createMembership, createMembershipResult] = useMembershipControllerAddMembersMutation();

    const [errorMsg, setErrorMsg] = useState("");

    type CreateUserWithTeamDto = CreateLocalUserDto & {
        teamId?: string;
        teamrole?: TeamRole;
    };

    const dto: CreateUserWithTeamDto = {
        username: "",
        role: "STANDARD",
        password: "",
        teamId: teams ? teams.results[0].teamId : '',
        teamrole: teamRoles ? teamRoles[0] : undefined
    };

    const navigate = useNavigate();

    return (
        <div>
            <ErrorBar errorMessage={errorMsg} />
            <Can I={Action.Create} a={Subjects.Users}>
                <div>
                    <Formik
                        validationSchema={Yup.object().shape({
                            password: LocalPasswordSchema,
                            password2: Yup.string()
                                .oneOf([Yup.ref('password')], 'Passwords must match'),
                            username: Yup.string()
                                .min(3, "too short!")
                                .max(320, "too long!")
                                .matches(RegExp("^[a-z0-9\\-_.+@]+$"), {
                                    excludeEmptyString: true,
                                    message:
                                        "Can only contain a-z, 0-9 hyphens (-), dots (.) and underscores (_). No space.",
                                })
                                .required("Required !"),
                        })}
                        initialValues={dto}
                        enableReinitialize
                        onSubmit={async (values, formikBag) => {
                            formikBag.setSubmitting(true);
                            try {
                                await createLocalUser({
                                    createLocalUserDto: {
                                        username: values["username"],
                                        role: values["role"],
                                        email: values["email"] || null,
                                        firstName: values["firstName"],
                                        lastName: values["lastName"],
                                        password: values["password"]
                                    },
                                }).unwrap().then((value) => {
                                    console.log(values["teamId"], values["teamrole"])
                                    if (values["teamId"] && values["teamrole"]) {
                                        createMembership({
                                            createTeamMembershipDto: {
                                                role: values["teamrole"],
                                                teamId: values["teamId"],
                                                userId: value?.id,
                                            },
                                            teamId: values["teamId"]
                                        }).then(() =>
                                            navigate(RoutesDef.USERS)
                                        )
                                    }
                                });


                                //
                            } catch (e) {
                                try {
                                    const errors =
                                        convertApiErrorsToFormikErrors(e);
                                    formikBag.setErrors(errors);
                                } catch (newErr) {
                                    setErrorMsg(JSON.stringify(newErr));
                                }
                            }
                        }}
                    >
                        {(formik) => (
                            <>
                                <FormikForm
                                    onSubmit={formik.handleSubmit}
                                    method="post"
                                >
                                    <Container className="section">
                                        <Row>
                                            <Col>
                                                <h1>
                                                    Create user
                                                </h1>
                                            </Col>
                                            <Col
                                                md="auto"
                                                className="align-items-end d-flex"
                                            >



                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container>
                                        <div className="section">

                                            <h4 className="mt-5 ">User settings</h4>
                                            <div className="border-top my-3" ></div>
                                            <BootstrapTextInput
                                                labelColWidth={2}
                                                label="Username *"
                                                name="username"
                                                placeholder="username"

                                            />
                                            <BootstrapTextInput
                                                labelColWidth={2}
                                                label="Email"
                                                name="email"
                                                placeholder="contact@vr2planets.com"

                                            />
                                            <BootstrapTextInput
                                                labelColWidth={2}
                                                label="First name"
                                                name="firstName"
                                                placeholder="First name"

                                            />
                                            <BootstrapTextInput
                                                labelColWidth={2}
                                                label="Last name"
                                                name="lastName"
                                                placeholder="Last name"

                                            />
                                            <BootstrapDropdownList
                                                labelColWidth={2}
                                                label="Role"
                                                name="role"
                                                items={roles}
                                                className="mt-5"

                                            />

                                            <BootstrapTextInput
                                                labelColWidth={2}
                                                label="Password *"
                                                name="password"
                                                placeholder="password"
                                                type="password"
                                                className="mt-5"
                                            />
                                            <BootstrapTextInput
                                                labelColWidth={2}
                                                label="Confirm password *"
                                                name="password2"
                                                placeholder="password"
                                                type="password"

                                            />

                                            <h4 className="mt-5 ">Initial team settings</h4>
                                            <div className="border-top my-3" ></div>
                                            <FkStackedDropdownKeys
                                                label="Team"
                                                name="teamId"
                                                items={(teams ? teams.results : []).map((team) => ({
                                                    ...team,
                                                    id: team.teamId,
                                                }))}
                                                labelColWidth={2}

                                            />
                                            <FkStackedDropdownKeys
                                                labelColWidth={2}
                                                items={teamRoles || []}
                                                name="teamrole"
                                                label="Team role"
                                            />

                                            <div className="text-right">
                                                <Button
                                                    disabled={!formik.dirty}
                                                    className="mt-5 "
                                                    type="submit">
                                                    {Sentences.save.en}
                                                </Button>
                                            </div>


                                        </div>
                                    </Container>
                                </FormikForm>
                            </>
                        )}
                    </Formik>


                </div>
            </Can >
        </div >
    );
}
