import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import ConfirmDelete, { ConfirmDeleteProps } from "./ConfirmDelete";

export type DeleteButtonWithConfirmProps = ButtonProps &
    Pick<ConfirmDeleteProps, "message"> & {
        onClick?: () => void;
    };

export const DeleteButtonWithConfirm: FC<DeleteButtonWithConfirmProps> = ({
    onClick,
    message,
    ...props
}) => {
    const [confirm, setConfirm] = useState(false);
    return (
        <>
            <ConfirmDelete
                showConfirmation={confirm}
                onPerformDelete={() => {
                    if (onClick) onClick();
                    setConfirm(false);
                }}
                onCancelDelete={() => setConfirm(false)}
                message={message}
            />
            <Button
                variant="danger"
                onClick={() => setConfirm(true)}
                {...props}
            >
                <FontAwesomeIcon icon={faTrash} /> {props.children}
            </Button>
        </>
    );
};
