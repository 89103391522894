import { Link, Navigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

export const BuilderModeUnity = () => {
    return (
        <Container>
            <div className="home-unity">
                <Row>
                    <Col className="my-auto">
                        <Link className="nav-link" to="#">
                            <h2>Slide Deck Editor</h2>
                        </Link>
                    </Col>
                    <Col className="my-auto">
                        <Link className="nav-link" to="/client/edit/scene">
                            <h2>Scene Editor</h2>
                        </Link>
                    </Col>
                    <Col className="my-auto">
                        <Link className="nav-link" to="#">
                            <h2>Library</h2>
                        </Link>
                    </Col>
                </Row>
            </div>
        </Container>)
}