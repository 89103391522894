import { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "./authSlice";
import { useGetAuthMethods, useLocalLoginMutation } from "src/api/AuthApi";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { AuthMethodsDto, LoginUserDto } from "src/api/generated.api";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import axios from "axios";
import RoutesDef from "src/routes/RoutesDef";

interface Props {
    clientMode?: Boolean;
}

export function LogIn({ clientMode }: Props) {
    let { rest } = useParams();
    const { ObjectNames, Sentences } = useContext(LangContext);
    const authStatus = useSelector(selectAuthStatus);
    const [login] = useLocalLoginMutation();
    const navigate = useNavigate();
    const location = useLocation();

    const [errorMessage, setErrorMessage] = useState("");
    const [authMethods, setAuthMethods] = useState<AuthMethodsDto>({
        useGoogleOauth: false,
        useCas: false,
        useLocal: false,
    });

    const { data: methods } = useGetAuthMethods();

    const onLoginClicked = async (
        values: LoginUserDto,
        formikBag: FormikHelpers<LoginUserDto>,
    ) => {
        try {
            await login({
                loginUserDto: {
                    username: values.username,
                    password: values.password,
                },
            }).unwrap();
            const defaultLocation = clientMode ? { from: { pathname: "/client" } } : { from: { pathname: "/" } };
            const { from } = location.state || defaultLocation;
            navigate(from.pathname);
        } catch (e) {
            if (e.status === 401) {
                setErrorMessage("Unknown username or password");
            } else {
                setErrorMessage(JSON.stringify(e));
            }
        }
    };

    const onCasLoginClicked = () => {
        window.location.assign(`/api/auth/social-login/cas`);
    };

    if (authStatus === "LOGGED_IN" && clientMode) {
        return <Navigate replace to="/client" />;
    }
    else if (authStatus === "LOGGED_IN") {
        return <Navigate replace to="/" />;
    }
    else {
        return (
            <>
                {" "}
                {methods && (
                    <Row className="bg ">
                        <Col className="my-auto justify-content-center">
                            <div className="my-50">
                                <h1 className="display-4 text-center">
                                    {Sentences.welcome.en}
                                </h1>
                            </div>
                            <Container style={{ width: 450 }}>
                                <Card>
                                    <Card.Header className="text-center">
                                        <h1>{Sentences.signIn.en}</h1>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="text-center mb-4">
                                            {methods.useCas ||
                                                methods.useGoogleOauth
                                                ? Sentences
                                                    .signInSocialInstructions
                                                    .en
                                                : Sentences.signInInstructions
                                                    .en}
                                        </div>
                                        {methods.useCas && (
                                            <Button
                                                onClick={onCasLoginClicked}
                                                block
                                            >
                                                {Sentences.loginWithCas.en}
                                            </Button>
                                        )}
                                        {methods.useGoogleOauth && (
                                            <Button
                                                href="/api/auth/social-login/google"
                                                block
                                            >
                                                Login with Google
                                            </Button>
                                        )}
                                        {(methods.useCas ||
                                            methods.useGoogleOauth) && (
                                                <hr
                                                    data-content="Or"
                                                    className="hr-text"
                                                ></hr>
                                            )}
                                        <div>
                                            <ErrorBar
                                                errorMessage={errorMessage}
                                            />
                                            <Formik
                                                initialValues={{
                                                    username: "",
                                                    password: "",
                                                }}
                                                validationSchema={Yup.object({
                                                    username:
                                                        Yup.string().required(),
                                                    password:
                                                        Yup.string().required(),
                                                })}
                                                onSubmit={onLoginClicked}
                                            >
                                                {(formik) => (
                                                    <Form
                                                        onSubmit={
                                                            formik.handleSubmit
                                                        }
                                                        onChange={(e) => {
                                                            setErrorMessage("");
                                                            formik.handleChange(
                                                                e,
                                                            );
                                                        }}
                                                        method="post"
                                                    >
                                                        <FkStackedTextInput
                                                            label="Username"
                                                            name="username"
                                                            placeholder="username"
                                                        />
                                                        <FkStackedTextInput
                                                            label="Password"
                                                            type="password"
                                                            name="password"
                                                            placeholder="password"
                                                        />
                                                        <Button
                                                            type="submit"
                                                            variant="primary"
                                                            block
                                                        >
                                                            {Sentences.login.en}
                                                        </Button>

                                                        <Link to={RoutesDef.ACCOUNT_RECOVER} className="mt-2 p-0 nav-link">
                                                            Lost your password?
                                                        </Link>


                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}
