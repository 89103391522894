import { useField } from "formik";
import { FC } from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { FieldHookConfigWithLabel } from "./interfaces/FkInterfaces";

export const FkEditNumber: FC<FieldHookConfigWithLabel<any>> = ({
    label,
    ...props
}) => {
    const [field, meta] = useField({ ...props });
    return (
        <FormGroup>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl
                as="input"
                type="number"
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                isInvalid={
                    (meta.touched && meta.error !== undefined) ||
                    (meta.initialError !== undefined &&
                        meta.initialValue === field.value)
                }
            />
            {meta.touched && meta.error ? (
                <FormControl.Feedback type="invalid">
                    {meta.error}
                </FormControl.Feedback>
            ) : meta.initialError && meta.initialValue === field.value ? (
                <FormControl.Feedback type="invalid">
                    {meta.initialError}
                </FormControl.Feedback>
            ) : null}
        </FormGroup>
    );
};
