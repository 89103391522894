import React, {
    FC,
    ReactPropTypes,
    useContext,
    useEffect,
    useState,
} from "react";

import { Action, Subjects } from "@shared/auth/src";
import { useParams } from "react-router";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import TerrainEditor from "./terrain-editor";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";

export const TerrainEditorPage: FC<unknown> = () => {
    const { Sentences } = useContext(LangContext);

    const { terrainId } = useParams<{ terrainId: string }>();

    const [id, setId] = useState<number>();
    const ability = useContext(AbilityContext);
    useEffect(
        () => (terrainId ? setId(parseInt(terrainId)) : setId(undefined)),
        [terrainId],
    );

    return (
        <>
            <Container>
                {ability.can(Action.Update, Subjects.Terrains) ? (
                    id ? (
                        <TerrainEditor terrainId={id} />
                    ) : (
                        <div>{Sentences.terrainNotFound.en}</div>
                    )
                ) : (
                    <ErrorBar errorMessage={Sentences.pageNotAllowed.en} />
                )}
            </Container>
        </>
    );
};
