import { FC } from "react";
import { Button, Form } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FieldArray, FieldHookConfig, useField } from "formik";
import {
    BootstrapDropdownKeys,
    BootstrapTextArea,
    FkStackedDropdownKeys,
} from "src/components/BootstrapFormComponents";
import FkMultiChoiceItem from "./FkMultiChoiceItem";
import FKSingleChoiceItem from "./FkSingleChoiceItem";
import FKReorderChoiceItem from "./FkReorderChoiceItem";

export const ItemTypes = {
    ANSWER: "answer",
};

export interface DragItem {
    index: number;
    id: number;
    type: string;
}

export const style = {
    cursor: "move",
};

export interface Item {
    id: number;
    text: string;
}

export interface ContainerState {
    cards: Item[];
}

export type FkEditQuizProps = FieldHookConfig<any> & {
    /** where to find the quiz type */
    type: string;
};

export const FkEditQuiz: FC<FkEditQuizProps> = ({
    type,
    disabled,
    ...props
}) => {
    const [field] = useField({ ...props });
    const [quizTypeField] = useField(type);

    const quizTypes = [
        { id: "NONE", name: "No quiz" },
        { id: "FREEFORM", name: "freeform question" },
        { id: "MULTIPLE_CHOICE", name: "multiple choice" },
        { id: "SINGLE_CHOICE", name: "single choice" },
        { id: "REORDER_CHOICE", name: "reorder items" },
    ];

    const renderFields = () => {
        switch (quizTypeField.value) {
            case "MULTIPLE_CHOICE":
                return (
                    <FieldArray name={`${field.name}['possibleAnswers']`}>
                        {({ insert, remove, push, move }) => (
                            <div>
                                <DndProvider backend={HTML5Backend}>
                                    {field.value.possibleAnswers.map(
                                        (val: any, index: number) => (
                                            <FkMultiChoiceItem
                                                disabled={disabled}
                                                index={index}
                                                key={index}
                                                name={`${field.name}['possibleAnswers'][${index}]`}
                                                onMoveItem={
                                                    disabled
                                                        ? () => {}
                                                        : (
                                                              dragIndex,
                                                              hoverIndex,
                                                          ) => {
                                                              move(
                                                                  dragIndex,
                                                                  hoverIndex,
                                                              );
                                                          }
                                                }
                                                onDeleteClick={() =>
                                                    remove(index)
                                                }
                                            />
                                        ),
                                    )}
                                </DndProvider>
                                {!disabled && (
                                    <Button
                                        onClick={() =>
                                            push({ answer: "", mark: 0 })
                                        }
                                    >
                                        Add new answer
                                    </Button>
                                )}
                            </div>
                        )}
                    </FieldArray>
                );
            case "SINGLE_CHOICE":
                return (
                    <FieldArray name={`${field.name}['possibleAnswers']`}>
                        {({ insert, remove, push, move }) => (
                            <div>
                                <DndProvider backend={HTML5Backend}>
                                    {field.value.possibleAnswers.map(
                                        (val: any, index: number) => (
                                            <FKSingleChoiceItem
                                                disabled={disabled}
                                                index={index}
                                                key={index}
                                                name={`${field.name}['possibleAnswers'][${index}]`}
                                                onMoveItem={
                                                    disabled
                                                        ? () => {}
                                                        : (
                                                              dragIndex,
                                                              hoverIndex,
                                                          ) => {
                                                              console.log(
                                                                  dragIndex,
                                                                  hoverIndex,
                                                              );
                                                              move(
                                                                  dragIndex,
                                                                  hoverIndex,
                                                              );
                                                          }
                                                }
                                                onDeleteClick={() =>
                                                    remove(index)
                                                }
                                            />
                                        ),
                                    )}
                                </DndProvider>
                                {!disabled && (
                                    <Button
                                        onClick={() =>
                                            push({ answer: "", mark: 0 })
                                        }
                                    >
                                        Add new answer
                                    </Button>
                                )}
                            </div>
                        )}
                    </FieldArray>
                );
            case "REORDER_CHOICE":
                return (
                    <FieldArray name={`${field.name}['possibleAnswers']`}>
                        {({ insert, remove, push, move }) => (
                            <div>
                                <DndProvider backend={HTML5Backend}>
                                    {field.value.possibleAnswers.map(
                                        (val: any, index: number) => (
                                            <FKReorderChoiceItem
                                                disabled={disabled}
                                                index={index}
                                                key={index}
                                                name={`${field.name}['possibleAnswers'][${index}]`}
                                                onMoveItem={
                                                    disabled
                                                        ? () => {}
                                                        : (
                                                              dragIndex,
                                                              hoverIndex,
                                                          ) => {
                                                              console.log(
                                                                  dragIndex,
                                                                  hoverIndex,
                                                              );
                                                              move(
                                                                  dragIndex,
                                                                  hoverIndex,
                                                              );
                                                          }
                                                }
                                                onDeleteClick={() =>
                                                    remove(index)
                                                }
                                            />
                                        ),
                                    )}
                                </DndProvider>
                                {!disabled && (
                                    <Button
                                        onClick={() =>
                                            push({ answer: "", mark: 0 })
                                        }
                                    >
                                        Add new answer
                                    </Button>
                                )}
                            </div>
                        )}
                    </FieldArray>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Form.Group>
                <FkStackedDropdownKeys
                    items={quizTypes}
                    name={type}
                    label="Quiz type"
                    disabled={disabled}
                />
                {quizTypeField.value !== "NONE" && (
                    <BootstrapTextArea
                        label="Question"
                        placeholder="write your question here"
                        rows={3}
                        name={`${field.name}['description']`}
                        disabled={disabled}
                    />
                )}
            </Form.Group>
            {renderFields()}
        </>
    );
};
