import { FC, useContext, useEffect, useState } from "react";
import { AppInfoContext } from "src/features/AppVersionWatcher/BackendVersionProvider";
import { LangContext, ObjectNames, Sentences, generateSentences } from "./lang";

export const LangProvider: FC<any> = ({ children }) => {
    const [lang, setLang] = useState({ ObjectNames, Sentences });
    const appVersion = useContext(AppInfoContext);

    useEffect(() => {
        if (appVersion) {
            const objectNames = {
                ...ObjectNames,
                appName: { en: appVersion.appName },
            };
            setLang({
                ObjectNames: objectNames,
                Sentences: generateSentences(objectNames),
            });
        }
    }, [appVersion]);
    return <LangContext.Provider value={lang}>{children}</LangContext.Provider>;
};
