import React, { useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { useNavigate } from "react-router";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import CreateTerrain from "./CreateTerrain";
import ErrorBar from "src/components/ErrorBar";

export default function CreateTerrainPage() {

    const navigate = useNavigate();
    const ability = useContext(AbilityContext);


    return (<>
        <Container>
            {ability.can(Action.Create, Subjects.Terrains)
                ? <CreateTerrain onSaved={(terrainId) => {
                    navigate(`/terrains/${terrainId}`);
                }} />
                : <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator." />
            }
        </Container>
    </>
    )
}