import { FC, ReactNode, createContext } from "react";
import { VersionInfo, useGetBackendVersionQuery } from "src/api/generated.api";

export const AppInfoContext = createContext<VersionInfo | undefined>(undefined);

export type AppVersionWatcherProps = {
    intervalMs: number;
    children?: ReactNode | undefined;
};

export const BackendVersionProvider: FC<AppVersionWatcherProps> = ({
    intervalMs,
    children,
}) => {
    const { data: appVersion } = useGetBackendVersionQuery(undefined, {
        pollingInterval: intervalMs
    });
    return (
        <AppInfoContext.Provider value={appVersion}>
            {children}
        </AppInfoContext.Provider>
    );
};
