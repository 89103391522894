import { Formik, FormikHelpers } from "formik";
import { FC, useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CreateLayerDto } from "src/api/generated.api";
import { useGetTerrainModelsCapabilitiesQuery } from "src/api/TerrainModelApi";
import { BootstrapDropdownKeys } from "src/components/BootstrapFormComponents";
import ErrorBar from "src/components/ErrorBar";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { teamContext } from "../teams/context/team-context-provider";
import {
    LayerManagementModeTypes,
    useLayerManagementMode,
} from "./terrain-layer-types";
import { useCreateLayerMutation } from "src/api/LayerApi";

export type CreateTerrainModelProps = {};

export const TerrainLayerCreator: FC<CreateTerrainModelProps> = () => {
    const { Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [createLayer, { isError: createIsError, error: createError }] =
        useCreateLayerMutation();

    const {
        data,
        isError: isErrorFetchingCapabilities,
        error: capError,
    } = useGetTerrainModelsCapabilitiesQuery();

    const capabilities: LayerManagementModeTypes[] = ["EXTERNAL", "INTERNAL"];
    const layerManagementModes = useLayerManagementMode();

    const onCreate = async (
        values: CreateLayerDto,
        formikBag: FormikHelpers<CreateLayerDto>,
    ) => {
        try {
            const terrainLayer = await createLayer({
                createLayerDto: values,
            }).unwrap();
            navigate(`/terrain-layers/${terrainLayer.id}`);
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const isErr = isErrorFetchingCapabilities || createIsError;
        const err = [capError, createError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [capError, createError, createIsError, isErrorFetchingCapabilities]);

    const navigate = useNavigate();

    return (
        <>
            <ErrorBar errorMessage={errorMessage} />
            {capabilities && capabilities.length > 0 ? (
                <Formik<CreateLayerDto>
                    initialValues={{
                        name: "",
                        managementMode: layerManagementModes.filter(({ id }) =>
                            (capabilities || []).includes(id),
                        )[0].id,
                        teamId: currentTeam ? currentTeam.id : "",
                    }}
                    onSubmit={onCreate}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} method="post">
                            <FkStackedTextInputWithButton
                                label={Sentences.layerName.en}
                                name="name"
                                placeholder={Sentences.layerName.en}
                                buttonText={Sentences.layerCreate.en}
                            />
                            <BootstrapDropdownKeys
                                items={layerManagementModes.filter(({ id }) =>
                                    (capabilities || []).includes(id),
                                )}
                                label={Sentences.terrainLayerIs.en}
                                name="managementMode"
                            />
                        </Form>
                    )}
                </Formik>
            ) : (
                <div>No Layer storage capabilities available on server.</div>
            )}
        </>
    );
};
