import React from "react";
import { Column, useTable } from "react-table";
import { Table } from 'react-bootstrap';


interface TableSimpleProps<D extends object> {
    columns : Array<Column<D>>,
    data : D[],
    fetchData : (pageIndex: number, pageSize: number) => void,
    loading : boolean,
    initialState? : {}
}

export default function TableSimple<D extends object = {}>(props : TableSimpleProps<D>) {

    // Use the state and functions returned from useTable to build your UI
    const initialState = props.initialState ?? { }


    const instance = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: initialState,
        },
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows
    } = instance;

    // Render the UI for your table
    return (
        <div>
            <Table striped {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(
                                {
                                    style: { width: column.width }
                                }
                            )}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()} >
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                <tr>
                    {props.loading
                        ? (
                            <td colSpan={2}>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </td>
                        )
                        : null}
                </tr>
                </tbody>
            </Table>
        </div>
    )
}

