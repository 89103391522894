import { FieldHookConfig, useField } from 'formik'
import { FC } from 'react'
import { FormControl, FormGroup, FormLabel, InputGroup, Container, Col, Row } from 'react-bootstrap'


export type FkEditVectorObjProps = FieldHookConfig<any> & {
    /** optional label for the field */
    label?: string,
}

export const FkEditVectorObj: FC<FkEditVectorObjProps> = ({ label, ...props }) => {
    const [field, meta] = useField({ ...props });
    return (
        <FormGroup >
            {label && <FormLabel>{label}</FormLabel>}
            <Container style={{ marginLeft: 0 }}>
                <Row xs={1}>
                    {Object.keys(field.value).sort((a, b) => a.localeCompare(b)).map((key) =>
                        <Col style={{ minWidth: '175px', padding: 0 }}>
                            <InputGroup key={key}>
                                <InputGroup.Text>{key}</InputGroup.Text>
                                <FormControl
                                    as='input'
                                    type='number'
                                    step="any"
                                    name={`${field.name}[${key}]`}
                                    onBlur={field.onBlur}
                                    onChange={field.onChange}
                                    value={field.value[key]}
                                    isInvalid={(meta.touched && (meta.error !== undefined)) || ((meta.initialError !== undefined) && (meta.initialValue[key] === field.value[key]))}
                                />
                                {
                                    (meta.touched && meta.error)
                                        ? (<FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>)
                                        : (meta.initialError && meta.initialValue[key] === field.value[key])
                                            ? (<FormControl.Feedback type="invalid">{meta.initialError}</FormControl.Feedback>)
                                            : null
                                }
                            </InputGroup>
                        </Col>
                    )}
                </Row>
            </Container>
        </FormGroup >
    );
};