import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { FC, PropsWithChildren, ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export type IconWithTooltipProps = FontAwesomeIconProps & {
    tooltipText: string;
    id: string;
};

export const IconWithTooltip: FC<IconWithTooltipProps> = (props) => (
    <OverlayTrigger
        overlay={<Tooltip id={props.id}>{props.tooltipText}</Tooltip>}
    >
        <FontAwesomeIcon {...props} />
    </OverlayTrigger>
);

type TooltipWrapperProps = {
    tooltipText: string;
    id: string;
    children: ReactNode;
};

export const TooltipWrapper: FC<TooltipWrapperProps> = ({
    id,
    tooltipText,
    children,
}) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{tooltipText}</Tooltip>}>
        <>{children}</>
    </OverlayTrigger>
);
