import React from "react";

export default function LoginForwadedPage() {

    return (
        <div>
            Authentication in progress in remote application. You can now close this page.
        </div>
    )
}

