import React, { FC, useEffect, useContext, useState } from "react";

import { Action, Subjects } from "src/api/Permissions";
import { TerrainModel } from "../../../api/generated.api";
import { Can } from "../../../casl/Can";
import {
    useGetAllTerrainModelsQuery,
    useRemoveTerrainModelMutation,
} from "src/api/TerrainModelApi";

import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { TerrainModelCreator } from "./terrain-model-creator";
import { Badge, Container } from "react-bootstrap";
import { teamContext } from "../../teams/context/team-context-provider";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { Column, Row } from "react-table";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import { useMeshSourceTypes } from "./terrain-model-types";
import TableWithPagination from "src/components/tables/TableWithPagination";
import moment from "moment";

export const TerrainModels: FC<any> = () => {
    const { ObjectNames } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [errorMessage, setErrorMessage] = React.useState("");
    const terrainModelTypeMap = useMeshSourceTypes();

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const modelsQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : "",
    }

    const {
        data: terrainModels,
        isLoading,
        isFetching,
        isError: getIsError,
        error: getError,
    } = useGetAllTerrainModelsQuery(modelsQueryProps);

    const [deleteTerrainModel, { isError: deleteIsError, error: deleteError }] =
        useRemoveTerrainModelMutation();

    const performDelete = (terrainModelId: TerrainModel["id"]) => {
        deleteTerrainModel({ terrainModelId });
    };

    useEffect(() => {
        const isErr = getIsError || deleteIsError;
        const err = [getError, deleteError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [getIsError, deleteIsError, getError, deleteError]);

    const columns: Column<TerrainModel>[] = [
        {
            Header: 'Name',
            width: undefined,
            accessor: "name",
        },
        {
            Header: "Type",
            width: 300,

            Cell: ({ row }: { row: Row<TerrainModel> }) => (
                <small>
                    {
                        terrainModelTypeMap.find(
                            ({ id }) => id === row.original.meshDataSourceType,
                        )?.name
                    }
                </small>
            ),
        },
        {
            Header: "Visibility",
            width: 150,
            accessor: "visibility"
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: Row<TerrainModel> }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: TerrainModel } }) => (
                <div>
                    {" "}
                    <ViewOrEditLinkButton
                        editLink={`/terrain-model/${row.original.id}`}
                        readLink={`/terrain-model/${row.original.id}`}
                        subjectType={Subjects.TerrainModel}
                        obj={row.original}
                    />
                    <Can
                        I={Action.Delete}
                        this={subject(Subjects.TerrainModel, {
                            ...row.original,
                        })}
                    >
                        <DeleteButtonWithConfirm
                            variant="secondary"
                            className="ml-1"
                            onClick={() => performDelete(row.original.id)}
                        />
                    </Can>
                </div>
            ),
        },
    ];

    return (
        <Container className="section">
            <h1>{ObjectNames.terrainModels.en}</h1>
            <ErrorBar
                errorMessage={errorMessage}
                onDismiss={() => setErrorMessage("")}
            />

            <Can I={Action.Create} a={Subjects.Terrains}>
                <TerrainModelCreator />
            </Can>

            <TableWithPagination
                columns={columns}
                data={terrainModels?.results || []}
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string
                ) => {
                    setPageIndex(pageIndexToFetch);
                    setPageSize(pageSizeToFetch);
                    setFilter(filterToFetch);
                    setSort(sortToFetch);
                }}
                loading={isFetching}
                itemCount={terrainModels?.total || 0}
                enableFiltering={true}
                sortableProps={[
                    { name: 'Name', accessor: 'name' },
                    { name: 'Visibility', accessor: 'visibility' },
                    { name: 'Type', accessor: 'meshDataSourceType' },
                    { name: 'Created at', accessor: 'createdAt' }
                ]}
            />
        </Container>
    );
};
