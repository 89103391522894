import { FC, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { FieldHookConfig, useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";

export type FkDraggableChoiceItemProps = FieldHookConfig<any> & {
    index: number;
    onMoveItem: (dragIndex: number, hoverIndex: number) => void;
    onDeleteClick?: () => void;
};

export interface Item {
    id: number;
    text: string;
}

export const ItemTypes = {
    ANSWER: "answer",
};

export interface DragItem {
    index: number;
    id: number;
    type: string;
}

export const FkDraggableChoiceItem: FC<FkDraggableChoiceItemProps> = ({
    index,
    onMoveItem,
    onDeleteClick,
    children,
    disabled,
    ...props
}) => {
    const [field] = useField({ ...props });
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId, isOver }, connectDropTarget] = useDrop(() => ({
        accept: ItemTypes.ANSWER,
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver(),
            };
        },
        hover: (item: DragItem, monitor: DropTargetMonitor) => {
            const dragIndex = item.id;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            onMoveItem(dragIndex, hoverIndex);
            item.id = hoverIndex;
        },
    }));

    const [, dragSourceRef] = useDrag(() => ({
        type: ItemTypes.ANSWER,
        item: () => ({
            id: index,
        }),
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const opacity = isOver ? 0 : 1;
    dragSourceRef(connectDropTarget(ref));
    return (
        <>
            <Row ref={ref} style={{ opacity }} data-handler-id={handlerId}>
                <Col md="auto">
                    <FontAwesomeIcon icon={faEllipsisV} />
                </Col>
                <Col md="auto">{children}</Col>
                <Col>
                    <FkStackedTextInput
                        placeholder="a new possible anwser"
                        label="answer"
                        name={`${field.name}['answer']`}
                        disabled={disabled}
                    />
                </Col>
                <Col sm="2" md="2" lg="1">
                    <FkStackedTextInput
                        placeholder="mark"
                        label="mark"
                        name={`${field.name}['mark']`}
                        disabled={disabled}
                    />
                </Col>
                {!disabled && (
                    <Col md="auto">
                        <Button
                            variant="danger"
                            onClick={() =>
                                onDeleteClick ? onDeleteClick() : null
                            }
                        >
                            X
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    );
};
