import { useSelector } from "react-redux";
import { selectAuthStatus } from "../auth/authSlice";
import { Await, Link, Navigate, useNavigate } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import RoutesDef from "src/routes/RoutesDef";
import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext, Can } from "src/casl/Can";
import { useAbility } from "@casl/react";
import { useContext, useEffect, useState } from "react";

export const HomeUnity = () => {
    const authStatus = useSelector(selectAuthStatus);
    const ability = useAbility(AbilityContext);

    if (authStatus !== "LOGGED_IN") {
        return <Navigate replace to={RoutesDef.UNITY_LOGIN} />;
    }

    return (
        <>
            <Container>
                <div className="home-unity">
                    <Row>
                        <Col className="my-auto">
                            <Link to={RoutesDef.UNITY_SESSIONS}>
                                <div className="background" style={{ position: "absolute", zIndex: 0, }}>
                                    <Image src="/assets/images/sessions.png" />
                                </div>

                                <div>
                                    <Image src="/assets/svg/play.svg" width="80" />
                                    <h2>Sessions</h2>
                                </div>



                            </Link>
                        </Col>
                        {ability.can(Action.Create, Subjects.Terrains) ?
                            <Col className="my-auto">
                                <Link className="nav-link" to={RoutesDef.UNITY_SCENES}>
                                    <div className="background" style={{ position: "absolute", zIndex: 0, }}>
                                        <Image src="/assets/images/builder.png" />
                                    </div>
                                    <div>
                                        <Image src="/assets/svg/builder.svg" width="80" />
                                        <h2>Scene editor</h2>
                                    </div>

                                </Link>
                            </Col> : <Navigate to={RoutesDef.UNITY_SESSIONS} />}

                    </Row>
                    <Link style={{ position: "absolute", bottom: 0, }} to={RoutesDef.HOME}>.</Link>
                </div>
            </Container>
        </>

    )
}