import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Button, ButtonProps } from "react-bootstrap";

export const SaveButton: FC<ButtonProps> = (props) => (
    <Button {...props}>
        <FontAwesomeIcon icon={faSave} />
    </Button>
);
