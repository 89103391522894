import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View, Map, ImageTile, Feature } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import TileLayer from "ol/layer/Tile";
import TileState from "ol/TileState";
import XYZ from "ol/source/XYZ";
import TileDebug from "ol/source/TileDebug";
import axios from "axios";
import TileGrid from "ol/tilegrid/TileGrid";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import OSM from "ol/source/OSM.js";
import Layer from "ol/layer/Layer";
import { Circle, Point } from "ol/geom";
import GeoJSON from "ol/format/GeoJSON";
import ErrorBar from "src/components/ErrorBar";
import { TerrainLayer, Vector3 } from "src/api/generated.api";
import { Style } from "ol/style";
import { StyleFunction } from "ol/style/Style";
import { useGetLayerQuery } from "src/api/LayerApi";
import wkt from "wkt-parser";
import * as olProj from "ol/proj";

import {
    createFovStyle,
    createGradientCircleRenderer,
    createXStyle,
} from "./TerrainMapStylingFuncs";
import {
    Col,
    Container,
    DropdownButton,
    FormGroup,
    FormLabel,
    Row,
    ToggleButton,
} from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Button } from "src/stories/Button";

function hashUrl(stringToHash: string) {
    let hash = 0,
        i,
        chr;
    if (stringToHash.length === 0) return hash;
    for (i = 0; i < stringToHash.length; i++) {
        chr = stringToHash.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function getProjCodeFromWkt(obj: Object) {
    const authority = Object.entries(obj).find(([k, v]) =>
        ["AUTHORITY", "ID"].includes(k.toUpperCase()),
    );
    return authority && typeof authority[1] === "object" && authority[1].EPSG
        ? `EPSG:${authority[1].EPSG}`
        : undefined;
}

function checkProjection(obj: any) {
    if (
        obj.PROJECTION &&
        !(proj4.Proj as any).projections.get(obj.PROJECTION)
    ) {
        return false;
    }
    return true;
}

export enum MapClickModes {
    NONE,
    GETCOORDS,
}

export type TerrainMapProps = {
    rootUrl: string | null;
    headers?: { [key: string]: string };
    geojson?: any;
    mapConfObjs?: {
        mapPosition: Vector3;
        mapRotation: Vector3;
        mapScale: number;
    };
    mapClickMode?: MapClickModes;
    onMapClicked?: (event: any) => void;
    mapHeight?: string;
    mapWidth?: string;
};

export type LayerMapProps = {
    terrainLayer: TerrainLayer;
    [x: string]: any;
};

export const LayerMap: FC<LayerMapProps> = ({ terrainLayer, ...rest }) => {
    const [rootUrl, setRootUrl] = useState("");
    const [headers, setHeader] = useState({});
    const { data, isError, isLoading } = useGetLayerQuery({
        layerId: terrainLayer.id,
    });
    useEffect(() => {
        if (data) {
            const obj: { [key: string]: string } = {};
            data.connectionParams.forEach((p) => {
                if ("key" in p && "value" in p) {
                    let key = undefined;
                    let value = undefined;
                    Object.entries(p).forEach(([k, v]) => {
                        if (k === "key") {
                            key = v;
                        }
                        if (k === "value") {
                            value = v;
                        }
                    });
                    if (key && value) {
                        obj[key] = value;
                    }
                }
            });
            setRootUrl(data.url);
            setHeader(obj);
        }
    }, [data]);
    return (
        <>
            {data ? (
                <TerrainMap
                    rootUrl={rootUrl}
                    headers={headers}
                    {...rest}
                ></TerrainMap>
            ) : (
                <>
                    <>{isLoading && "Loading map data"}</>
                    <ErrorBar
                        errorMessage={
                            isError ? "Error retrieving layer map data" : ""
                        }
                    />
                </>
            )}
        </>
    );
};

export const TerrainMap: FC<TerrainMapProps> = ({
    rootUrl,
    headers,
    geojson,
    mapConfObjs,
    mapClickMode,
    mapHeight,
    mapWidth,
    onMapClicked,
}) => {
    const [metadata, setMetadata] = useState<any>();
    const defaultProjCode = "EPSG:3857";
    const [mapProjCode, setMapProjCode] = useState<string>(defaultProjCode);
    const [layerProjCode, setLayerProjCode] = useState<string>(defaultProjCode);
    const [useDefaultProjCode, setUseDefaultProjCode] = useState(true);
    const [showOSM, setShowOSM] = useState(true);

    const [projCodes, setProjCodes] = useState<string[]>(
        Object.keys(proj4.defs).sort(),
    );
    const [projIsReady, setProjIsReady] = useState(false);
    const [map, setMap] = useState<Map>();

    const mapElement = useRef<HTMLDivElement>(null);

    const [errorMessage, setErrorMessage] = useState("");
    const [tmsLayer, setTmsLayer] = useState<Layer>();
    const [debugLayer, setDebugLayer] = useState<Layer>();

    const [geoJsonVectorLayer, setGeoJsonVectorLayer] = useState<Layer>(
        new VectorLayer({
            source: new VectorSource({}),
        }),
    );

    const [checkVectorLayer, setCheckVectorLayer] = useState<Layer>(
        new VectorLayer({
            source: new VectorSource({}),
        }),
    );
    const [mapConfVectorLayer] = useState<Layer>(
        new VectorLayer({
            source: new VectorSource(),
        }),
    );

    const parseMetadata = (metadata: any, rootUrl: string) => {
        setErrorMessage("");
        const rawProj = wkt(metadata.srs);
        let projCode = getProjCodeFromWkt(rawProj);
        let errMsg = "";
        let canBeRegistred = true;
        if (
            rawProj.type &&
            !["PROJCS", "GEOGCS", "LOCAL_CS"].includes(rawProj.type)
        ) {
            canBeRegistred = false;
            errMsg += `Cannot register WKT. At root, only "PROJCS", "GEOGCS", "LOCAL_CS" are allowed. Current is: ${rawProj.type}.`;
        }

        if (canBeRegistred && !checkProjection(rawProj)) {
            canBeRegistred = false;
            errMsg += `There is a PROJECTION tag with value ${rawProj.PROJECTION} but this projection is not implemented in this proj4 lib`;
        }

        /* 
            If we have a COMPD_CS, try to extract the 2D part and see if there is an id.
            Because of wkt parser, the nested part is not enriched by the parser as if it was the root part (lacking some properties)
            Thus we cannot reinject the nested part into proj4.
            But, we can try to fake it if the authority or id of this 2D part is allready registred.
        */
        if (!projCode && rawProj.type && rawProj.type === "COMPD_CS") {
            const proj2D = Object.entries(rawProj).find(([k, v]) =>
                ["PROJCS", "GEOGCS", "LOCAL_CS"].includes(k.toUpperCase()),
            );
            if (proj2D && typeof proj2D[1] === "object") {
                let tempProjCode = getProjCodeFromWkt(proj2D[1] as object);
                if (tempProjCode && !proj4.defs(tempProjCode)) {
                    canBeRegistred = false;
                    errMsg += ` Tried to process AUTHORITY in nested PROJCS: found ${tempProjCode}, but it's not registred in proj4. skipping`;
                }
            }
        }

        let needRegister = true;
        if (projCode) {
            console.info(`detected proj code: ${projCode}`);
            // we have a proj code.
            if (!proj4.defs(projCode)) {
                // but it's not known by proj4. let's register this new proj.
                canBeRegistred && proj4.defs(projCode, rawProj);
            } else {
                needRegister = false;
            }
        } else {
            // we don't have a proj code.
            if (canBeRegistred) {
                // let's generate a new one and register this new proj.
                projCode = `layerproj-${hashUrl(rootUrl || "")}`;
                proj4.defs(projCode, rawProj);
            }
        }

        if (needRegister) {
            try {
                register(proj4);
                setProjCodes(Object.keys(proj4.defs).sort());
            } catch (error) {
                canBeRegistred = false;
                errMsg += `Failed to register ${error} into openlayer`;
            }
        }

        if (!projCode && !canBeRegistred && needRegister) {
            setErrorMessage(errMsg);
        } else {
            if (projCode) {
                setMapProjCode(projCode);
                setLayerProjCode(projCode);
                setUseDefaultProjCode(false);
            }
        }
    };

    const xStyle = createXStyle;

    const onMapClick = useCallback(
        (event: any) => {
            if (onMapClicked) onMapClicked(event.coordinate);
        },
        [onMapClicked],
    );
    const fovStyleFunc: StyleFunction = createFovStyle;

    const circle1Style = useMemo(
        () =>
            new Style({
                renderer: createGradientCircleRenderer([0, 0, 255]),
            }),
        [],
    );
    const circle2Style = useMemo(
        () =>
            new Style({
                renderer: createGradientCircleRenderer([0, 255, 0]),
            }),
        [],
    );
    const circle3Style = useMemo(
        () =>
            new Style({
                renderer: createGradientCircleRenderer([255, 0, 0]),
            }),
        [],
    );

    // set Layers on map or layer change
    useEffect(() => {
        if (map) {
            const openCycleMapLayer = new TileLayer({
                source: new OSM({
                    url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                    attributions: "",
                }),
            });

            const openSeaMapLayer = new TileLayer({
                source: new OSM({
                    opaque: false,
                    url: "https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png",
                }),
            });

            const layers = [
                showOSM && openCycleMapLayer,
                tmsLayer,
                debugLayer,
                geoJsonVectorLayer,
                mapConfVectorLayer,
                checkVectorLayer,
            ];
            const okLayers: Layer[] = [];
            layers.forEach((layer) => {
                if (layer) {
                    okLayers.push(layer);
                }
            });
            map.setLayers(okLayers);
        }
    }, [
        map,
        tmsLayer,
        geoJsonVectorLayer,
        mapConfVectorLayer,
        checkVectorLayer,
        debugLayer,
        showOSM,
    ]);

    // create the map config vector layer
    useEffect(() => {
        if (!mapConfVectorLayer) return;
        const source = mapConfVectorLayer.getSource() as VectorSource;
        source.clear();

        if (mapConfObjs) {
            const position = new Feature({
                geometry: new Point([
                    mapConfObjs.mapPosition.x,
                    mapConfObjs.mapPosition.y,
                ]),
            });
            position.setStyle(xStyle);
            const fov = new Feature({
                geometry: new Point([
                    mapConfObjs.mapPosition.x,
                    mapConfObjs.mapPosition.y,
                ]),
                angle: mapConfObjs.mapRotation.y,
            });
            fov.setStyle(fovStyleFunc);
            const dist1VrInMeter = 2;
            const dist2VrInMeter = 10;
            const dist3VrInMeter = 300;
            const circle1 = new Feature({
                geometry: new Circle(
                    [mapConfObjs.mapPosition.x, mapConfObjs.mapPosition.y],
                    mapConfObjs.mapScale * dist1VrInMeter,
                ),
                label: `${dist1VrInMeter}m`,
            });
            circle1.setStyle(circle1Style);
            const circle2 = new Feature({
                geometry: new Circle(
                    [mapConfObjs.mapPosition.x, mapConfObjs.mapPosition.y],
                    mapConfObjs.mapScale * dist2VrInMeter,
                ),
                label: `${dist2VrInMeter}m`,
            });
            circle2.setStyle(circle2Style);
            const circle3 = new Feature({
                geometry: new Circle(
                    [mapConfObjs.mapPosition.x, mapConfObjs.mapPosition.y],
                    mapConfObjs.mapScale * dist3VrInMeter,
                ),
                label: `${dist3VrInMeter}m`,
            });
            circle3.setStyle(circle3Style);
            source.addFeatures([position, fov, circle1, circle2, circle3]);
        }
    }, [
        mapConfVectorLayer,
        mapConfObjs,
        xStyle,
        fovStyleFunc,
        circle1Style,
        circle2Style,
        circle3Style,
    ]);

    // create the map and wait for the metadata object to load
    useEffect(() => {
        console.log("creating map");
        const initialMap = new Map({
            target: mapElement.current ?? undefined,
        });
        initialMap?.addEventListener("click", onMapClick);
        setMap(initialMap);
        const controller = new AbortController();

        const obs = new ResizeObserver(() => {
            initialMap?.updateSize();
        });
        if (mapElement.current) {
            obs.observe(mapElement.current);
        }

        const detailUrl = `${rootUrl}details.json`;
        try {
            const url = new URL(detailUrl);
            axios
                .get(url.href, {
                    headers: headers ?? {},
                    signal: controller.signal,
                })
                .then(
                    ({ data }) => {
                        if (data) {
                            parseMetadata(data, rootUrl || "");
                            setMetadata(data);
                        } else {
                            setErrorMessage("Error: no layer metadata");
                        }
                    },
                    (reason) => {
                        setErrorMessage(
                            `Error loading layer metadata: ${reason.message}`,
                        );
                    },
                );
        } catch (err) {
            console.error(err);
            setErrorMessage(
                `terrain layer url ${detailUrl} is not a valid url. cannot load metadata`,
            );
        }
        return () => {
            console.log("unmounting map");
            initialMap.removeEventListener("click", onMapClick);
            controller.abort();
            initialMap.setTarget(undefined);
            initialMap.dispose();
            setMap(undefined);
            obs.disconnect();
        };
    }, [rootUrl, headers, onMapClick]);

    // handle metadata, create tiles layers
    useEffect(() => {
        if (metadata) {
            const {
                geoTransform: gt,
                bounds,
                levelDimensions: dims,
                texture,
            } = metadata;
            const tiles = Math.pow(2, dims.length - 1);
            const _maxx =
                gt[0] +
                gt[1] * tiles * texture.tileWidth +
                gt[2] * tiles * texture.tileHeight;
            const _maxy =
                gt[3] +
                gt[4] * tiles * texture.tileWidth +
                gt[5] * tiles * texture.tileHeight;
            const _minx = gt[0];
            const _miny = gt[3];

            const minx = Math.min(_minx, _maxx);
            const miny = Math.min(_miny, _maxy);
            const maxx = Math.max(_minx, _maxx);
            const maxy = Math.max(_miny, _maxy);

            const startResolution = (maxx - minx) / texture.tileWidth;

            const tileGrid = new TileGrid({
                extent: [minx, miny, maxx, maxy],
                tileSize: [
                    texture.tileWidth,
                    texture.tileHeight * Math.abs(gt[5] / gt[1]),
                ],
                resolutions: dims.map((_: any, index: number) => {
                    const resolution = startResolution / Math.pow(2, index);
                    return resolution;
                }),
                origin: [gt[0], gt[3]],
                minZoom: 0,
                sizes: dims.map((_: any, index: number) => {
                    const numTiles = Math.pow(2, index);
                    return [numTiles, numTiles];
                }),
            });
            try {
                const center = [
                    bounds.minx + (bounds.maxx - bounds.minx) / 2,
                    bounds.miny + (bounds.maxy - bounds.miny) / 2,
                ];
                const maxLevel = dims.length - 1;
                const view = new View({
                    center,
                    resolution: tileGrid.getResolutions()[0] / 2,
                    projection: mapProjCode,
                });
                map?.setView(view);

                const source = new VectorSource({ wrapX: false });

                source.addFeatures([
                    new Feature({
                        geometry: new Circle(center, 100),
                    }),
                ]);

                const layer = new TileLayer({
                    source: new XYZ({
                        tileUrlFunction: (coordinate) => {
                            const z = maxLevel - coordinate[0];
                            const x = coordinate[1];
                            const y = coordinate[2];
                            return `${rootUrl}texture/${z}/${y}/${x}.jpg`;
                        },
                        tileLoadFunction: (tile, src) => {
                            const tl = tile as ImageTile;
                            axios
                                .get(src, {
                                    responseType: "blob",
                                    headers: headers ?? {},
                                })
                                .then(({ data }) => {
                                    if (
                                        tl.getImage() instanceof
                                        HTMLImageElement
                                    ) {
                                        const img: HTMLImageElement =
                                            tl.getImage() as HTMLImageElement;
                                        img.src = URL.createObjectURL(data);
                                    }
                                })
                                .catch((err) => {
                                    tile.setState(TileState.ERROR);
                                });
                        },
                        tileGrid,
                        projection: mapProjCode,
                    }),
                });
                const debugLayer = new TileLayer({
                    source: new TileDebug({
                        tileGrid,
                        projection: mapProjCode,
                    }),
                });
                setTmsLayer(layer);
                setDebugLayer(debugLayer);
            } catch (error) {
                setErrorMessage(
                    `failed to build map from metadata: ${JSON.stringify(
                        error,
                    )}`,
                );
                console.error(error);
            }
        }
    }, [metadata, rootUrl, headers, map, mapProjCode, checkVectorLayer]);

    // parse and fill the vector layer with the geojson
    useEffect(() => {
        if (geojson) {
            setGeoJsonVectorLayer((layer) => {
                const source = layer.getSource();

                if (source instanceof VectorSource) {
                    source.clear();
                    const features = new GeoJSON({
                        dataProjection: mapProjCode,
                    }).readFeatures(geojson);

                    source.addFeatures(features);
                }
                return layer;
            });
        }
    }, [geojson, projIsReady, mapProjCode]);

    return (
        <>
            <ErrorBar errorMessage={errorMessage} />
            {mapClickMode}
            <div
                style={{
                    height: mapHeight ?? "100%",
                    width: mapWidth ?? "100%",
                }}
                ref={mapElement}
                className="map-container; border; mb-3"
            />
            <Container className="mb-3">
                <Row>
                    <div className="m-auto">Projection</div>
                    <Col>
                        <DropdownButton
                            title={mapProjCode}
                            variant={
                                mapProjCode === layerProjCode
                                    ? "success"
                                    : "warning"
                            }
                        >
                            {projCodes.map((code) => (
                                <DropdownItem
                                    key={code}
                                    onClick={() => setMapProjCode(code)}
                                    active={code === mapProjCode}
                                >
                                    {code === layerProjCode
                                        ? code +
                                          (useDefaultProjCode
                                              ? " (default)"
                                              : " (layer)")
                                        : code}
                                </DropdownItem>
                            ))}
                        </DropdownButton>
                    </Col>
                    {useDefaultProjCode && (
                        <div className="m-auto">
                            No code detected from layer. Using default code
                        </div>
                    )}
                    <ToggleButton
                        type="checkbox"
                        value="1"
                        checked={showOSM}
                        onChange={(e) => setShowOSM(e.currentTarget.checked)}
                        className="mb-0"
                    >
                        Show OSM
                    </ToggleButton>
                </Row>
            </Container>
        </>
    );
};
