import { Ability, RawRuleOf } from "@casl/ability";
import { unpackRules } from "@casl/ability/extra";
import { FC, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAbilitiesQuery } from "src/api/AuthApi";
import { AbilityContext, AppAbility } from "src/casl/Can";
import { selectRules, selectAuthStatus } from "./authSlice";

export type AbilitiesProviderProps = {
    children?: ReactNode | undefined;
};

export const AbilitiesProvider: FC<AbilitiesProviderProps> = ({ children }) => {
    useGetAbilitiesQuery(undefined, {
        refetchOnMountOrArgChange: true,
        pollingInterval: 280000,
    });
    const rules = useSelector(selectRules);
    const authStatus = useSelector(selectAuthStatus);
    const [ability, setAbility] = useState(new Ability());
    useEffect(() => {
        if (rules) {
            const rawRules = unpackRules(rules) as RawRuleOf<AppAbility>[];
            const newAbility = new Ability();
            newAbility.update(rawRules);
            setAbility(newAbility);
        } else {
            const newAbility = new Ability();
            setAbility(newAbility);
        }
    }, [rules]);

    return (
        <AbilityContext.Provider value={ability}>
            {authStatus === "UNKNOWN" ? (
                <div> Wait a minute... {authStatus}</div>
            ) : (
                children
            )}
        </AbilityContext.Provider>
    );
};
