import {
    ArtifactRelease,
    CreateArtifactReleaseDto,
    PackageDto,
    PackageVersionSummaryDto,
    UpdateArtifactReleaseDto,
    api as generatedApi,
} from "./generated.api";

const BINARY_ARTIFACT_RELEASE_TAG = "BinaryArtifactReleaseTag";

const LIST = "LIST";
const BINARY_ARTIFACT_RELEASE_LIST = {
    type: BINARY_ARTIFACT_RELEASE_TAG,
    id: LIST,
} as const;

export const BinaryArtifactApi = generatedApi.enhanceEndpoints({
    addTagTypes: [BINARY_ARTIFACT_RELEASE_TAG],
    endpoints: {
        artifactsControllerCreateReleaseChannel: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result
                    ? [
                          {
                              type: BINARY_ARTIFACT_RELEASE_TAG,
                              id: result.id,
                          } as const,
                          BINARY_ARTIFACT_RELEASE_LIST,
                      ]
                    : [BINARY_ARTIFACT_RELEASE_LIST];
        },
        artifactsControllerGetReleaseChannel: {
            providesTags: (result, error, args) =>
                result
                    ? [{ type: BINARY_ARTIFACT_RELEASE_TAG, id: result.id }]
                    : [],
        },
        artifactsControllerGetReleaseChannelsPaginated: {
            providesTags: (result, error, args) =>
                result
                    ? [
                          ...result.results.map(
                              ({ id }) =>
                                  ({
                                      type: BINARY_ARTIFACT_RELEASE_TAG,
                                      id,
                                  } as const),
                          ),
                          BINARY_ARTIFACT_RELEASE_LIST,
                      ]
                    : [BINARY_ARTIFACT_RELEASE_LIST],
        },
        artifactsControllerDeleteRelease: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) =>
                result
                    ? [
                          {
                              type: BINARY_ARTIFACT_RELEASE_TAG,
                              id: arg.releaseId,
                          } as const,
                          BINARY_ARTIFACT_RELEASE_LIST,
                      ]
                    : [BINARY_ARTIFACT_RELEASE_LIST];
        },
        artifactsControllerGetPackages: {
            providesTags: (result) => [],
        },
        artifactsControllerGetVersions: {
            providesTags: (result) => [],
        },
        artifactsControllerUpdateRelease: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                {
                    type: BINARY_ARTIFACT_RELEASE_TAG,
                    id: arg.releaseId,
                } as const,
                BINARY_ARTIFACT_RELEASE_LIST,
            ];
        },
    },
});

export const {
    useArtifactsControllerCreateReleaseChannelMutation: useCreateRelease,
    useArtifactsControllerGetReleaseChannelQuery: useGetReleaseChannel,
    useArtifactsControllerGetReleaseChannelsPaginatedQuery:
        useGetPaginatedReleases,
    useArtifactsControllerDeleteReleaseMutation: useDeleteRelease,
    useArtifactsControllerGetPackagesQuery: useGetPackages,
    useArtifactsControllerGetVersionsQuery: useGetPackageVersions,
    useArtifactsControllerUpdateReleaseMutation: useUpdateRelease,
} = BinaryArtifactApi;

export type {
    CreateArtifactReleaseDto,
    UpdateArtifactReleaseDto,
    ArtifactRelease,
    PackageDto,
    PackageVersionSummaryDto,
};
