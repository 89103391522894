import { FC, useContext } from "react";
import { Button } from "react-bootstrap";
import { Column } from "react-table";
import { Job, JobEventDto } from "src/api/generated.api";
import { useCleanJobHistoryMutation, useGetJobHistoryQuery } from "src/api/JobApi";
import TableSimple from "src/components/tables/TableSimple";
import { LangContext } from "src/lang/lang";

export type JobHistoryProps = {
    jobId: Job['id'];
}

export const JobHistory: FC<JobHistoryProps> = ({ jobId }) => {
    const {ObjectNames, Sentences } = useContext(LangContext);

    const { data: jobHistory, isFetching } = useGetJobHistoryQuery({jobId});
    const [ cleanHistory ] = useCleanJobHistoryMutation();


    const columns: Column<JobEventDto>[] = [
        {
            Header: ObjectNames.timestamp.en,
            width: undefined,
            Cell: ({ row } : { row : { original : JobEventDto} }) => (
                <div>
                    {row.original.timestamp}
                </div>
            )
        },
        {
            Header: Sentences.jobStatus.en,
            width: undefined,
            Cell: ({ row } : { row : { original : JobEventDto} }) => (
                <div>
                    {row.original.status}
                </div>
            )
        }
    ];

    return (<><TableSimple 
        columns={columns} 
        loading={isFetching}
        data={jobHistory || []}
        fetchData={(pageIndex, pageNumber)=>null}
    />
    <Button onClick={()=>cleanHistory({jobId})}>Clean History</Button>
    </>);
}